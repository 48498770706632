<template>
  <div class="stellasyncNote" v-show="!failedToLoad">
    <textarea id="tinymcenote"></textarea>
    <div class="templateSelect">
      <a-select v-model="selectedTemplate" @select="setTemplate" placeholder="Select Template">
        <a-select-option disabled>Select Template</a-select-option>
        <a-select-option v-for="template in templates" :value="template.content" :key="template.id">
          {{ template.name }}
        </a-select-option>
      </a-select>
    </div>
  </div>
  <div v-if="failedToLoad">
    Failed to load the TinyMCE editor. Please make sure you have included the necessary scripts and styles.
    <button @click="initNote()">Reload</button>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import $ from "jquery";
import { computed } from 'vue';

// Props
const props = defineProps({
  initial: {
    default: null,
    required: true,
    validator(value) {
      return value === null || typeof value === 'string' || value instanceof String;
    },
  },
  modelValue: {
    default: null,
    required: true,
    event: 'change',
    validator(value) {
      return value === null || typeof value === 'string' || value instanceof String;
    },
  },
});

// Emits
const emit = defineEmits(['update:modelValue']);

// Reactive references
const selectedTemplate = ref('');
const templates = computed(() => useStore().state.template.templates);
const failedToLoad = ref(false);

// Methods
const initNote = () => {

  tinymce?.init({
    selector: "#tinymcenote",
    plugins: [
      "anchor", "autolink", "charmap", "codesample", "emoticons", "image", "link",
      "lists", "media", "searchreplace", "table", "visualblocks", "wordcount"
    ],
    toolbar:
      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
    tinycomments_mode: "embedded",
    tinycomments_author: "Author name",
    mergetags_list: [
      { value: "First.Name", title: "First Name" },
      { value: "Email", title: "Email" }
    ],
    setup: function (editor) {
      editor.on("change", () => {
        emit("update:modelValue", getHtmlContent());
      });
      editor.on("input", () => {
        emit("update:modelValue", getHtmlContent());
      });
    },
  });
};

const getHtmlContent = () => {
  const editor = tinymce.get("tinymcenote");
  if (editor) {
    return editor.getContent();
  } else {
    console.error("TinyMCE editor not initialized");
    return null;
  }
};

const setHtmlContent = (newContent) => {
  const editor = tinymce.get("tinymcenote");
  if (editor) {
    editor.setContent(newContent);
  } else {
    console.error("TinyMCE editor not initialized");
  }
};

const setTemplate = (template) => {
  setHtmlContent(template);
  emit("update:modelValue", getHtmlContent());
};

// Watchers
watch(() => props.initial, (newValue) => {
  if (!window?.tinymce) {
    console.error("TinyMCE not found");
    return;
  }
  const editor = tinymce.get("tinymcenote");
  if (editor && newValue !== editor.getContent()) {
    editor.setContent(newValue);
  }
});

// Lifecycle hooks
onMounted(() => {
  if (templates.value.length == 0) {
    useStore().dispatch("template/fetchTemplates");
  }
  if (!window?.tinymce) {
    console.error('Failed to Loade');
    
    failedToLoad.value = true;
    return
  }
  // console.log( useStore().state.template.templates);

  try {
    initNote();
  } catch (error) {
    initNote();
  }

});

onBeforeUnmount(() => {
  if (!window?.tinymce) {
    console.error("TinyMCE not found");
    return;
  }
  const editor = tinymce.get("tinymcenote");
  if (editor) {
    editor.remove();
  }
});
</script>
<style>
.tox-notifications-container {
  visibility: hidden !important;
}
</style>