<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Pharmacy </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Medicine</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <!-- <form action="javaScript:void(0)">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-heading">
                          <h4>Add Medicine</h4>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Medicine Name
                            <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.drug_name"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Category <span class="login-danger">*</span></label
                          >
                          <select
                            class="form-control"
                            type="text"
                            name="cat"
                            v-model="formData.drug_category"
                          >
                            <option value="">Select Category</option>
                            <option
                              :value="drug_category.id"
                              v-for="drug_category in drug_categories"
                              :key="drug_category.id"
                            >
                              {{ drug_category.category }}
                            </option>
                            <option value="Syrup">Syrup</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Original Price
                            <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.original_price"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Selling Price
                            <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.selling_price"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Expiry Date
                            <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="date"
                            placeholder=""
                            v-model="formData.expiry_date"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Medication Type
                            <span class="login-danger">*</span></label
                          >
                          <select
                            class="form-control"
                            type="text"
                            name="cat"
                            v-model="formData.drug_type"
                          >
                            <option value="">Select Category</option>
                            <option
                              :value="drug_type.id"
                              v-for="drug_type in drug_types"
                              :key="drug_type.id"
                            >
                              {{ drug_type.type }}
                            </option>
                            <option value="Syrup">Syrup</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Quantity <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.quantity"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Company Name
                            <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.company_name"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Description
                            <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.description"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Content <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.content"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Mass (kg)
                            <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            v-model="formData.mass"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Image <span class="login-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            type="file"
                            placeholder=""
                            accept="*"
                            @change="handleImageChange"
                          />
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="doctor-submit text-end">
                          <button
                            type="submit"
                            class="btn btn-primary submit-form me-2"
                            @click="addDrug()"
                          >
                            Submit
                          </button>
                          <button
                            type="submit"
                            class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form> -->

              <Add_MedModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Add_MedModal from "./Add_MedModal.vue";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";
import router from "@/router";

export default {
  name: "Index_Page",
  components: {
    Add_MedModal,
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      drug_categories: [],
      drug_types: [],
      formData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        original_price: "",
        selling_price: "",
        expiry_date: "",
        quantity: "",
        company_name: "",
        description: "",
        content: "",
        mass: "",
        avatar: null,
      },
    };
  },

  methods: {
    ...mapActions({
      _getDrugCategories: "pharmacy/getDrugCategories",
      _addDrug: "pharmacy/addDrug",
      _getDrugTypes: "pharmacy/getDrugTypes",
    }),

    handleImageChange(event) {
      this.formData.avatar = event.target.files[0];
    },

    // addDrug() {
    //   let formData = new FormData();
    //   formData.append("drug_name", this.formData.drug_name);
    //   formData.append("drug_category", this.formData.drug_category);
    //   formData.append("drug_type", this.formData.drug_type);
    //   formData.append("original_price", this.formData.original_price);
    //   formData.append("selling_price", this.formData.selling_price);
    //   formData.append("expiry_date", this.formData.expiry_date);
    //   formData.append("quantity", this.formData.quantity);
    //   formData.append("company_name", this.formData.company_name);
    //   formData.append("description", this.formData.description);
    //   formData.append("content", this.formData.content);
    //   formData.append("mass", this.formData.mass);
    //   formData.append("avatar", this.formData.avatar);

    //   this._addDrug(formData)
    //     .then((response) => {
    //       // $("#con-close-modal").modal("hide");
    //       this.response = response.data;
    //       if (this.response["status"] === true) {
    //         this.$toast.success(this.response["message"]);
    //         this.$router.push("Me");
    //       }
    //     })
    //      .catch((error) => {
    //       console.log(error);
    //       // if (error.response && error.response.data) {
    //        if (status == 400 || status == 422 || status == 401) {
    //   this.$toast.error(error.response.data.message);
    // } else if (status === 500) {
    //   this.$toast.error("Network Error, Check your internet conectivity.");
    // }
    //       // } else {
    //       //  this.$toast.error("An unexpected error occurred.");
    //       // }
    //     });
    // },

    getDrugCategories() {
      this._getDrugCategories()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_categories = this.response.data;
            // this.$toast.success(this.response["message"]);
            // console.log(this.drug_categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    getDrugTypes() {
      this._getDrugTypes()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_types = this.response.data;
            // this.$toast.success(this.response["message"]);
            console.log(this.drug_types);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
  },

  mounted() {
    this.getDrugCategories();
    this.getDrugTypes();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
