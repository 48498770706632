<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Accounts </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Expenses</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Expenses List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="#" class="btn btn-primary add-pluss ms-2"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></a>
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->
              <div class="staff-search-table">
                <form>
                  <div class="row">
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Item Name </label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Purchased by </label>
                        <a-select class="w-100">
                          <a-select-option>Select Purchase by</a-select-option>
                          <a-select-option>Bernardo James</a-select-option>
                          <a-select-option>Galaviz Lalema</a-select-option>
                          <a-select-option>Tarah Williams</a-select-option>
                        </a-select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Paid by </label>
                        <a-select class="w-100">
                          <a-select-option>Select Paid by</a-select-option>
                          <a-select-option value="Paypal">Paypal</a-select-option>
                          <a-select-option value="Cheque">Cheque</a-select-option>
                          <a-select-option>Debit Card</a-select-option>
                        </a-select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>From </label>
                        <input class="form-control datetimepicker" type="text" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>To </label>
                        <input class="form-control datetimepicker" type="text" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="doctor-submit">
                        <button type="submit" class="btn btn-primary submit-list-form me-2">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="table-responsive" style="overflow-x: scroll; overflow-y: visible">
                <table class="table border-0 custom-table comman-table table-striped table-hover mb-0">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </th>
                      <th>Item</th>
                      <th>Purchase From</th>
                      <th>Purchase by</th>
                      <th>Paid by</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Anaesthetic machine</td>
                      <td>Biomedical Equipment Inc</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5" alt="" />
                          Andrea Lalema</a>
                      </td>
                      <td>Paypal</td>
                      <td>04.10.2024</td>
                      <td>$1000</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-green dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Approved
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Aspiration Pump</td>
                      <td>Medi Pro Service</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-03.jpg"
                            class="rounded-circle m-r-5" alt="" />William Stephin</a>
                      </td>
                      <td>Cheque</td>
                      <td>08.10.2024</td>
                      <td>$2000</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-pink dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Rejected
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Anaesthetic machine</td>
                      <td>Biomedical Equipment Inc</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5" alt="" />Smith Bruklin</a>
                      </td>
                      <td>Paypal</td>
                      <td>04.10.2024</td>
                      <td>$1000</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-orange dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Pending
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Anaesthetic machine</td>
                      <td>Biomedical Equipment Inc</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-04.jpg"
                            class="rounded-circle m-r-5" alt="" />
                          Bernardo James</a>
                      </td>
                      <td>Paypal</td>
                      <td>04.10.2024</td>
                      <td>$1000</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-purple dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            New
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Medical Expenses</td>
                      <td>Hi-life Medicals</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-06.jpg"
                            class="rounded-circle m-r-5" alt="" />Cristina Groves</a>
                      </td>
                      <td>Debit Card</td>
                      <td>03.10.2024</td>
                      <td>$1600</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-green dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Approved
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Anaesthetic machine</td>
                      <td>Biomedical Equipment Inc</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-05.jpg"
                            class="rounded-circle m-r-5" alt="" />
                          Mark Hay Smith</a>
                      </td>
                      <td>Paypal</td>
                      <td>04.10.2024</td>
                      <td>$1000</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-green dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Approved
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Aspiration Pump</td>
                      <td>Medi Pro Service</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5" alt="" />
                          Andrea Lalema</a>
                      </td>
                      <td>Cheque</td>
                      <td>06.10.2024</td>
                      <td>$4000</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-pink dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Rejected
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td>Medical Expenses</td>
                      <td>Hi-life Medicals</td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5" alt="" />
                          Smith Bruklin</a>
                      </td>
                      <td>Debit Card</td>
                      <td>04.10.2024</td>
                      <td>$1000</td>
                      <td>
                        <div class="dropdown action-label">
                          <a class="custom-badge status-green dropdown-toggle" href="#" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Approved
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">New</a>
                            <a class="dropdown-item" href="javascript:;">Pending</a>
                            <a class="dropdown-item" href="javascript:;">Approved</a>
                            <a class="dropdown-item" href="javascript:;">Rejected</a>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i
                                class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {};
  },

  methods: {},

  mounted() { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
