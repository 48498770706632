<template>
<Loader id="loadercomp" />
<Inloader class="inloader" id="inloader" />
  
      
        <div class="page-wrapper">
          <div class="content">
			
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="departments.html">Department </a></li>
              <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
              <li class="breadcrumb-item active">Department List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      
      <div class="row">
        <div class="col-sm-12">
        
          <div class="card card-table show-entire">
            <div class="card-body">
            
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Department List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here">
                            <a class="btn"><img src="../../assets/assets/img/icons/search-normal.svg" alt=""></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="add-department.html" class="btn btn-primary add-pluss ms-2"><img src="../../assets/assets/img/icons/plus.svg" alt=""></a>
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden=''><img src="../../assets/assets/img/icons/re-fresh.svg" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class=" me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg" alt=""></a>
                    <a href="javascript:;" class=" me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg" alt=""></a>
                    <a href="javascript:;" class=" me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg" alt=""></a>
                    <a href="javascript:;" ><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt=""></a>
                    
                  </div>
                </div>
              </div>
              <!-- /Table Header -->
              
              <div class="table-responsive" style="overflow:visible">
                <table class="table border-0 custom-table comman-table table-striped table-hover mb-0">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </th>
                      <th>Department</th>
                      <th>Department Head</th>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Cardiology</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg" class="rounded-circle m-r-5" alt=""> Dr.Andrea Lalema</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>01.10.2024</td>
                      <td><button class="custom-badge status-green ">Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Urology</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-02.jpg" class="rounded-circle m-r-5" alt=""> Dr.Smith Bruklin</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>01.10.2024</td>
                      <td><button class="custom-badge status-green ">Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Radiology</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-03.jpg" class="rounded-circle m-r-5" alt=""> Dr.William Stephin</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>01.10.2024</td>
                      <td><button class="custom-badge status-green ">Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Dentist</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-04.jpg" class="rounded-circle m-r-5" alt=""> Dr.Bernardo James</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>03.10.2024</td>
                      <td><button class="custom-badge status-pink ">In Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Gynocolgy</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-06.jpg" class="rounded-circle m-r-5" alt="">Dr.Cristina Groves</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>05.10.2024</td>
                      <td><button class="custom-badge status-pink ">In Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Gynocolgy</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-05.jpg" class="rounded-circle m-r-5" alt=""> Mark Hay Smith</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>07.10.2024</td>
                      <td><button class="custom-badge status-green ">Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Otolaryngology</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg" class="rounded-circle m-r-5" alt=""> Dr.Andrea Lalema</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>10.10.2024</td>
                      <td><button class="custom-badge status-green ">Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input class="form-check-input" type="checkbox" value="something">
                        </div>
                      </td>
                      <td>Urology</td>
                      <td class="profile-image"><a href="profile.html"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-02.jpg" class="rounded-circle m-r-5" alt=""> Dr.Smith Bruklin</a></td>
                      <td>Investigates and treats proble...</td>
                      <td>21.10.2024</td>
                      <td><button class="custom-badge status-pink ">In Active</button></td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="edit-department.html"><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i class="fa fa-trash-alt m-r-5"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>							
        </div>					
      </div>
          </div>         

          
  </div>
    
</template>            
<script>







import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";


export default {
  name: "Index_Page",
  components: {
    
Loader,
Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
     
    };
  },

  methods: {
    
  },

  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>