<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Dashboard </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Nurse Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="good-morning-blk not_for_admin">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>
                {{ getGreeting() }}, <span>{{ dash_name }}</span>
              </h2>
              <p>Have a nice day at work.</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img
                src="../../assets/assets/img/undraw_medical_care_movn.svg"
                alt=""
                class="dashimg"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- {{ dashboard_data }}{{ dashboard_data.total_appointments }} -->
      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d1">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-calendar dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Today's Appointments</h4>
              <h2 class="text-light">
                <span class="conter-up">
                  {{
                    dashboard_data?.todays_appointments == 0 ||
                    dashboard_data?.todays_appointments == null ||
                    dashboard_data?.todays_appointments == ""
                      ? 0
                      : dashboard_data?.todays_appointments
                  }}
                </span>
                <!-- <span class="conter-up">{{
                      dashboard_data?.total_appointments == 0
                        ? 0
                        : dashboard_data?.total_appointments
                    }}</span> -->
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>40%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d3">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-bed dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Pending Drugs to Administer</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  dashboard_data?.pending_drugs_administer == 0 ||
                  dashboard_data?.pending_drugs_administer == null ||
                  dashboard_data?.pending_drugs_administer == ""
                    ? 0
                    : dashboard_data?.pending_drugs_administer
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="negative-view"
                  ><i class="feather-arrow-down-right me-1"></i>15%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d4">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-child dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Total In-patients</h4>
              <h2 class="text-light">
                <span class="counter-up">
                  {{
                    dashboard_data?.admitted_patient_count == 0 ||
                    dashboard_data?.admitted_patient_count == null ||
                    dashboard_data?.admitted_patient_count == ""
                      ? 0
                      : dashboard_data?.admitted_patient_count
                  }}</span
                >
              </h2>
              <p class="sub_design" hidden>
                <!-- Number.parseInt(dashboard_data?.earnings).toLocaleString("en-US") -->
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>30%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d2">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-user dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Next Medication</h4>
              <h2 class="text-light">
                <span style="font-size: 20px;">{{
                    dashboard_data?.next_medication?.patient||'-'}} - {{
                      count_down_holder || 'Not yet'}}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>20%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="chart-title patient-visit">
                <h4>Patient Visit by Gender</h4>
                <div></div>

                <div class="center-chart"></div>
                <div class="input-block mb-0 col-3 col-lg-2 col-md-3">
                  <a-select class="w-100">
                    <a-select-option value="2024">2024</a-select-option>
                    <a-select-option value="2025">2025</a-select-option>
                    <a-select-option value="2026">2026</a-select-option>
                    <a-select-option value="2027">2027</a-select-option>
                  </a-select>
                </div>
              </div>
              <!-- {{ chart.chartOptions.labels }} -->
              <!-- {{ chart2.chartOptions.xaxis.categories }} -->
              <!-- <div id="patient-chart"></div> -->
              <apexchart
                type="bar"
                :options="chart2.chartOptions"
                :series="chart2.series"
                height="250"
                v-if="chart2.chartOptions.xaxis.categories"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="row" hidden>
        <div class="col-12 col-md-12 col-xl-4">
          <div class="card top-departments">
            <div class="card-header">
              <h4 class="card-title mb-0">Top Departments</h4>
            </div>
            <div class="card-body">
              <div class="activity-top">
                <div class="activity-boxs comman-flex-center">
                  <img
                    src="../../assets/assets/img/icons/dep-icon-01.svg"
                    alt=""
                  />
                </div>
                <div class="departments-list">
                  <h4>General Physician</h4>
                  <p>35%</p>
                </div>
              </div>
              <div class="activity-top">
                <div class="activity-boxs comman-flex-center">
                  <img
                    src="../../assets/assets/img/icons/dep-icon-02.svg"
                    alt=""
                  />
                </div>
                <div class="departments-list">
                  <h4>Dentist</h4>
                  <p>24%</p>
                </div>
              </div>
              <!--  <div class="activity-top">
                    <div class="activity-boxs comman-flex-center">
                      <img src="../../assets/assets/img/icons/dep-icon-03.svg" alt="" />
                    </div>
                   <div class="departments-list">
                      <h4>ENT</h4>
                      <p>10%</p>
                    </div>
                  </div>
                   <div class="activity-top">
                    <div class="activity-boxs comman-flex-center">
                      <img src="../../assets/assets/img/icons/dep-icon-04.svg" alt="" />
                    </div>
                    <div class="departments-list">
                      <h4>Cardiologist</h4>
                      <p>15%</p>
                    </div>
                  </div>
                  <div class="activity-top mb-0">
                    <div class="activity-boxs comman-flex-center">
                      <img src="../../assets/assets/img/icons/dep-icon-05.svg" alt="" />
                    </div>
                    <div class="departments-list">
                      <h4>Opthomology</h4>
                      <p>20%</p>
                    </div>
                  </div> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-8" hidden>
          <div class="card">
            <div class="card-header pb-0">
              <h4 class="card-title d-inline-block">Patients</h4>
              <router-link to="/Patients" class="float-end patient-views"
                >Show all</router-link
              >
            </div>
            <div class="card-block table-dash">
              <div class="table-responsive" style="overflow: visible">
                <table class="table mb-0 border-0 custom-table">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <th>Patient Type</th>
                      <th>Sex</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <!-- <th>Account Number</th> -->
                      <!-- <th>Balance</th> -->
                      <th>Joining Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="patient in patients" :key="patient.id">
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />{{ patient.first_name }} {{ patient.last_name }}</a
                        >
                      </td>

                      <td>
                        {{
                          transformString(patient?.patient_type?.patient_type)
                        }}
                      </td>
                      <td>{{ patient.sex }}</td>
                      <td>
                        <a href="javascript:;">{{ patient.phone_number }}</a>
                      </td>
                      <td>{{ patient.email }}</td>
                      <!-- <td>{{ patient?.account_number }}</td> -->
                      <!-- <td>₦ {{ formatNumberWithCommas(patient.balance) }}</td> -->
                      <td>{{ formattedDate(patient.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- <div class="card">
                <div class="card-header">
                  <h4 class="card-title d-inline-block">Upcoming Appointments</h4>
                  <router-link to="Category-GOPD" class="patient-views float-end"
                    >Show all</router-link
                  >
                </div>
                <div class="card-body p-0 table-dash">
                  <div class="table-responsive" style="overflow: visible">
                    <table class="table mb-0 border-0 custom-table">
                      <thead>
                        <tr>
                          <th>
                            <div class="form-check check-tables" hidden=''>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="something"
                              />
                            </div>
                          </th>
                          <th></th>
                          <th>Patient name</th>
                          <th>Doctor</th>
                          <th>Time</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(apt, index) in appointments" v-bind:key="index">
                          <td>
                            <div class="form-check check-tables" hidden=''>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="something"
                              />
                            </div>
                          </td>
                          <td>-</td>
                          <td>{{ apt.patient_name }}</td>
                          <td class="table-image appoint-doctor">
                            <img
                              width="28"
                              height="28"
                              class="rounded-circle"
                              src="../../assets/assets/img/profiles/avatar-02.jpg"
                              alt=""
                            />
                            <h2>{{ apt.doctor_name }}</h2>
                          </td>
                          <td class="appoint-time">
                            <span>{{ apt.date }} </span> {{ apt.time }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-12">
          <div class="card">
            <div class="card-header pb-0">
              <h4 class="card-title d-inline-block">
                Recently Admitted Patients
              </h4>
              <router-link to="/In-Out-Patients" class="float-end patient-views"
                >Show all</router-link
              >
            </div>
            <div class="card-block table-dash">
              <div class="table-responsive" style="overflow: visible">
                <table class="table mb-0 border-0 custom-table">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <th>Sex</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <!-- <th>Account Number</th> -->
                      <!-- <th>Balance</th> -->
                      <th>Joining Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="patient in patients" :key="patient.id">
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />{{ patient.full_name }}</a
                        >
                      </td>

                      <td>{{ patient.sex }}</td>
                      <td>
                        <a href="javascript:;">{{ patient.phone_number }}</a>
                      </td>
                      <td>{{ patient.email }}</td>
                      <!-- <td>{{ patient?.account_number }}</td> -->
                      <!-- <td>₦ {{ formatNumberWithCommas(patient.balance) }}</td> -->
                      <td>{{ formattedDate(patient.date) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LabRequest />
    <!-- <div class="sidebar-overlay" data-reff=""></div> -->
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

// import ApexCharts from "apexcharts";
// import FloatSideBar from "./plugins/float_sidebar";
// import Chat from "./plugins/chat";
// import Loader from "./plugins/Loader";

// import { store } from '@/store';
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      hospital_name:
        localStorage.getItem("hospital") == "null"
          ? ""
          : localStorage.getItem("hospital"),
      dashboard_data: null,
      dash_name:
        localStorage.getItem("name") == "null"
          ? ""
          : localStorage.getItem("name"),
      male_array: [],
      female_array: [],
      month_array: [],
      chartData: [],
      piechartData: "",
      department: [],
      department_scores: [],
      appointments: "",
      patients: "",
      chart: {
        chartOptions: {
          chart: {
            id: "apexchart-example",
          },
          title: {
            // text: "Fruit distribution",
            align: "center",
            // margin: 40,
            style: {
              fontSize: "20px",
              fontWeight: "bold",
              color: "#263238",
              width: 900,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: +{
                chart: {
                  width: 300,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          // colors: ["#2E93fA", "#46DA62", "#242E8A", "#E91E63", "#FF9800"],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: "bottom",
          },
          labels: [],
        },
        series: [],
      },

      chart2: {
        series: [
          {
            name: "Male",
            data: [],
          },
          {
            name: "Female",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 50,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "15%",
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          title: {
            // text: "Fiction Books Sales",
          },
          xaxis: {
            categories: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            // labels: {
            //   formatter: function (val) {
            //     return val + "K";
            //   },
            // },
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      count_down_holder: null,
    };
  },

  methods: {
    ...mapActions({
      _getFrontdeskDashboard: "dashboards/getFrontdeskDashboard",
      _getAdminChartData: "dashboards/getAdminChartData",
      _getdashappointments: "dashboards/getdashappointments",
      _getAdmittedPatientList: "doctor/getAdmittedPatientList",
      _getAdminDashboard: "dashboards/getAdminDashboard",
      _getNursedeskDashboard: "dashboards/getNurseDashboard",
    }),

    getchartdata() {
      this._getAdminChartData({ year: 2024 })
        .then((response) => {
          this.chartData = response.data.data;
          this.chartData = this.chartData.chart;
          this.month_array = Object.keys(this.chartData);
          console.log(this.chartData);

          //   this.female_array = Object.values(this.chartData[Female]);
          //   console.log(this.month_array);

          this.female_array = [];
          this.male_array = [];

          // Iterate through the months
          for (const month in this.chartData) {
            if (this.chartData.hasOwnProperty(month)) {
              const monthData = this.chartData[month];

              // Check if monthData is an empty array
              if (Array.isArray(monthData) && monthData.length === 0) {
                this.male_array.push(0);
                this.female_array.push(0);
              } else {
                // Check if "Male" key exists and push the value to the this.male_array array
                if (monthData.hasOwnProperty("Male")) {
                  this.male_array.push(monthData["Male"]);
                } else {
                  this.male_array.push(0);
                }

                // Check if "Female" key exists and push the value to the this.female_array array
                if (monthData.hasOwnProperty("Female")) {
                  this.female_array.push(monthData["Female"]);
                } else {
                  this.female_array.push(0);
                }
              }
            }
          }
          //   console.log(this.female_array);
          //   console.log(this.male_array);
          //   alert(this.dashboard_data);
          this.chart2.series[0].data = this.male_array;
          this.chart2.series[1].data = this.female_array;
          this.chart2.chartOptions.xaxis.categories = Object.values(
            this.month_array
          );
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    // getFrontdeskDashboard() {
    //   //   this.getchartdata();

    //   this._getFrontdeskDashboard()
    //     .then((response) => {
    //       this.dashboard_data = response.data.data;
    //       this.piechartData = this.dashboard_data.patient_by_dept;
    //       // Extract values of "category" and "appointment_count"
    //       this.department = this.piechartData.map(
    //         (patient) => patient.category
    //       );
    //       this.department_scores = this.piechartData.map(
    //         (patient) => patient.appointment_count
    //       );

    //       this.chart.chartOptions.labels = Object.values(this.department);
    //       this.chart.series = Object.values(this.department_scores);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log("Error fetching data", error);
    //     });
    // },
    getdashappointments() {
      this._getdashappointments()
        .then((response) => {
          this.appointments = response.data.data;
          console.log(this.appointments, "hiuhyuyu");
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getAdmittedPatientList() {
      this._getAdmittedPatientList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.patients = this.response.data;
            this.patients = this.patients.reverse().slice(0, 4);
            console.log(this.patients);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getGreeting() {
      const currentTime = new Date();
      const hours = currentTime.getHours();

      let greeting;

      if (hours < 12) {
        greeting = "Good morning";
      } else if (hours < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      return greeting;
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    transformString(input) {
      if (input === undefined || input === null) {
        return "";
      }

      // Check if the input is a string
      if (typeof input !== "string") {
        return input.toString();
      }
      return input.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
    },

    getNursedeskDashboard() {
      //   this.getchartdata();

      this._getNursedeskDashboard()
        .then((response) => {
          this.dashboard_data = response.data.data;
          setInterval(() => {
            if(this.dashboard_data?.next_medication?.next_medication_time){
          this.calculateCountdown(this.dashboard_data?.next_medication?.next_medication_time)
          console.log(this.count_down_holder)
        }
        }, 1000);
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    // timer
    calculateCountdown(countDownDate) {
      if (countDownDate) {
        const countDownDatek = new Date(countDownDate).getTime();
        const now = new Date().getTime();
        const distance = countDownDatek - now;

        // Time calculations for hours, minutes, and seconds
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
          // clearInterval(this.intervalIds[index]); // Clear the interval
          this.count_down_holder = "0s";
        } else {
          this.count_down_holder = `${hours}h ${minutes}m ${seconds}s`;
        }
      } else {
        return "Not yet";
      }
    },
  },
  created() {
    // Initialize Apex chart here
    // this.getchartdata();
  },

  mounted() {
    setTimeout(() => {
      // window.location.reload();
      // this.getdashappointments();
      // this.getFrontdeskDashboard();
      this.getAdmittedPatientList();
      this.getchartdata();
      // this.getAdminDashboard();
      this.getNursedeskDashboard();
    }, 2000);
    var role = localStorage.getItem("role");
    if (role == "Super Admin" || role == "Admin") {
      $(".not_for_admin").hide();
    }
  },
};
</script>

<style scoped>
.dashimg {
  width: 100%;
  height: 130%;
  margin-left: -20%;
  /* margin-top: 650px; */
}

.h2,
.h4 {
  color: white;
}

.d1 {
  /* background-color: #fc8955; */
  background-color: #14a79e;

  color: white;
}

.d2 {
  /* background-color: #0583d2; */
  background: rgb(255, 69, 96);
  /* background: #fbbc06; */
  color: white;
}

.d3 {
  /* background-color: #4fd6f7; */
  background: #2c50ed;
  color: white;
}

.d4 {
  /* background-color: #b8e3ff; */
  background: #222437;
  color: white;
}

.dash-boxs {
  /* background: #e8eff4; */
  background: #eff0f144;
}

.counter-up {
  color: white;
}

.dash_card_icons {
  color: white;
  font-size: 30px;
}

.sub_design {
  width: 53%;
  padding: 2px;
  border: 2px solid white;
  border-radius: 12px;
  background-color: white;
  color: black;
}

@media (max-width: 480px) {
  .dashimg[data-v-e4871a92] {
    width: 100%;
    height: 130%;
    margin-left: 0 !important;
    /* margin-top: 650px; */
  }
}
</style>
