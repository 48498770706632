<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Pharmacy </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Dispensory</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Dispensory</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @input="click()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto text-end float-end ms-auto download-grp">
                <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                    alt="" /></a>
                <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                    alt="" /></a>
                <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                    alt="" /></a>
                <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <div class="table-responsive" style="overflow-x: scroll">
            <a-table :dataSource="dispensory" :columns="columns">
              <template #bodyCell="{ column, record }">

                <template v-if="column.key == 'action'">
                  <span class="btn btn-primary me-2" @click="view_prescriptions(record)">View
                    prescriptions</span>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Prescription :prescription_data_recieved="prescriptions" />

</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import Prescription from "./plugin/prescriptions.vue";

import jquery from "jquery";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Patient Name",
    dataIndex: "patient_name",
    key: "patient_name",
  },
  {
    title: "Patient Type",
    dataIndex: "patient_type",
    key: "patient_type",
  },

  {
    key: "action",
  },

];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    Prescription
  },

  data() {
    return {
      dispensory: [],
      search: "",
      dispensory_reserve: [],
      columns,
      prescriptions:[]
    };
  },

  methods: {
    ...mapActions({
      _getDispensory: "pharmacy/getDispensory",
      _dispense_drugs: "pharmacy/dispense_drugs",
    }),

    getDispensory() {
      this._getDispensory({ pending: 1 })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.dispensory = this.response.data;
            this.dispensory_reserve = this.dispensory;
            // this.$toast.success(this.response["message"]);
            // console.log(this.drug_categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },


    search_the_list() {
      if (this.search == "") {
        this.dispensory = this.dispensory_reserve;
      } else {
        this.dispensory = this.dispensory_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.dispensory.length; i++) {
          var drug_name = this.dispensory[i].drug_name.toLowerCase();
          var price = this.dispensory[i].price;
          var date = this.dispensory[i].date;
          if (
            drug_name.includes(searchLetter) ||
            price.includes(searchLetter) ||
            date.includes(searchLetter)
          ) {
            searchResult.push(this.dispensory[i]);
          }
          // if (searchResult == []) {
          //   this.dispensory = this.dispensory;
          // }
        }
      }
      if (this.search == "") {
        this.dispensory = this.dispensory_reserve;
      } else {
        this.dispensory = searchResult;
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    view_prescriptions(prescription_data) {
      this.prescriptions = prescription_data;
      $("#prescriptions-modal").modal("show");

    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    this.getDispensory();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{width:60%} */
</style>
