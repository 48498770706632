export const formatAmount = (amount, addNairaSign = false) => {
  return (
    (addNairaSign ? "₦" : "") +
    parseFloat(amount || 0).toLocaleString("en-Us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

export function formatDate(timestamp, showTime = false) {
  return new Date(timestamp).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",

    ...(showTime
      ? {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      : {}),
  });
}

export function capitalize(string) {
  const _string = string || "";
  return _string.charAt(0).toUpperCase() + _string.slice(1);
}
