<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Settings </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">View Roles</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="settings-menu-links">
        <ul class="nav nav-tabs menu-tabs">
          <li class="nav-item">
            <router-link
              to="/Settings"
              class="nav-link"
              v-if="role != 'Super Admin'"
              >General Settings</router-link
            >
          </li>
          <li class="nav-item active">
            <router-link to="/View-Roles" class="nav-link"
              >View Roles</router-link
            >
          </li>
          <li class="nav-item" v-if="role == 'Super Admin'">
            <router-link to="/View-Category" class="nav-link"
              >View Categories</router-link
            >
          </li>
          <!-- <li class="nav-item hide_for_now" hidden="">
          <router-link to="/Assign-Permission" class="nav-link">Assign Permission</router-link>
          </li> -->

          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Loacalization-Settings" class="nav-link"
              >Localization</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Payment-Settings" class="nav-link"
              >Payment Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Email-Settings" class="nav-link"
              >Email Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Login-Settings" class="nav-link"
              >Social Media Login</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Links" class="nav-link"
              >Social Links</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/SEO-Settings" class="nav-link"
              >SEO Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Theme-Settings" class="nav-link"
              >Theme Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Change-Password" class="nav-link"
              >Change Password</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Other-Settings" class="nav-link"
              >Others</router-link
            >
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>List of Roles</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group only_for_super_admin">
                          <router-link
                            to="/Assign-Permission"
                            class="btn btn-primary add-pluss ms-2"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></router-link>

                          <!-- <span class="btn btn-primary doctor-refresh ms-2" hidden=''
                                ><img
                                  src="../../assets/assets/img/icons/re-fresh.svg"
                                  alt=""
                              /></span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="roles" :columns="columns">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a
                          href="#"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a
                            v-if="record.own_healthcare == false"
                            class="dropdown-item"
                            @click="
                              editPermissions(
                                record.permissions,
                                record.role,
                                record.id
                              )
                            "
                            ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                            Edit Permission</a
                          >
                          <a
                            v-else
                            class="dropdown-item"
                            @click="
                              editRole(
                                record.permissions,
                                record.role,
                                record.id,
                                record.roles,
                                record.own_healthcare
                              )
                            "
                            ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                            Edit Role</a
                          >
                          <!-- <a class="dropdown-item" href="#"
                                  ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                                > -->
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Settings_menu";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Date",
    dataIndex: "date_created",
    key: "date_created",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      roles: [],
      search: "",
      roles_reserve: [],
      nonHodDoctors: [],
      editrole: {},
      role: "",
      columns,
      role: localStorage.getItem("role"),
    };
  },

  methods: {
    ...mapActions({
      _getrole: "general/getrole",
    }),

    getrole() {
      this._getrole()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.roles = this.response.data;
            var whoisLoggedin = localStorage.getItem("role");
            if (whoisLoggedin == "Admin") {
              this.roles = this.roles.filter(
                (item) => item.role != "Clinic" && item.role != "Maternity"
              );
            }
            this.roles = this.roles.reverse();
            this.roles_reserve = this.roles;
            console.log(this.roles);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    editPermissions(permissions, role_name, role_id) {
      // alert(permissions)
      localStorage.setItem("edit_permissions", JSON.stringify(permissions));
      localStorage.setItem("role_name", role_name);
      localStorage.setItem("role_id", role_id);
      localStorage.removeItem("roles");

      window.location = "Edit-Permission";
    },
    editRole(permissions, role_name, role_id, roles, health_care_type) {
      // alert(permissions)
      localStorage.setItem("edit_permissions", JSON.stringify(permissions));
      localStorage.setItem("role_name", role_name);
      localStorage.setItem("role_id", role_id);
      localStorage.setItem("health_care_type", health_care_type);
      localStorage.setItem("roles", JSON.stringify(roles));
      window.location = "Edit-Permission";
    },
    search_the_list() {
      if (this.search == "") {
        this.roles = this.roles_reserve;
      } else {
        this.roles = this.roles_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.roles.length; i++) {
          var role = this.roles[i].role.toLowerCase();
          if (role.includes(searchLetter)) {
            searchResult.push(this.roles[i]);
          }
          // if (searchResult == []) {
          //   this.roles = this.roles;
          // }
        }
      }
      if (this.search == "") {
        this.roles = this.roles_reserve;
      } else {
        this.roles = searchResult;
      }
    },
  },

  mounted() {
    this.getrole();
    var role = localStorage.getItem("role");
    if (role == "Super Admin") {
      $(".only_for_super_admin").show();
    }

    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    // this.getrole();
    // this.getNonHodDoctor();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n2 {
  background-color: #14a79e;
}
.only_for_super_admin {
  display: none;
  cursor: pointer;
}

@media (max-width: 480px) {
  .settings-menu-links {
    position: relative;
  }

  ul.nav.nav-tabs.menu-tabs {
    position: relative;
  }

  .nav-tabs.menu-tabs .nav-item {
    position: relative;
  }

  .nav-tabs.menu-tabs .nav-item.active .nav-link {
    position: absolute;
    width: 110px;
    left: 85px;
  }
}
</style>
