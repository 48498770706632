<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Accounts </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Provident Fund List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Provident Fund List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a
                            href="add-provident-fund.html"
                            class="btn btn-primary add-pluss ms-2"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></a>
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div
                class="table-responsive"
                style="overflow-x: scroll; overflow-y: visible"
              >
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <th>Designation</th>
                      <th>Provident Fund Type</th>
                      <th>Employee Share</th>
                      <th>Organization Share</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Andrea Lalema</a
                        >
                      </td>
                      <td>Doctor</td>
                      <td>Basic Salary</td>
                      <td>10%</td>
                      <td>5%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-green dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Approved
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-03.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />William Stephin</a
                        >
                      </td>
                      <td>Doctor</td>
                      <td>Basic Salary</td>
                      <td>15%</td>
                      <td>20%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-orange dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Pending
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />Smith Bruklin</a
                        >
                      </td>
                      <td>Nurse</td>
                      <td>Basic Salary</td>
                      <td>10%</td>
                      <td>12%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-orange dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Pending
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-04.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Bernardo James</a
                        >
                      </td>
                      <td>Specialist</td>
                      <td>Basic Salary</td>
                      <td>12%</td>
                      <td>15%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-green dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Approved
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-06.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />Cristina Groves</a
                        >
                      </td>
                      <td>Doctor</td>
                      <td>Basic Salary</td>
                      <td>10%</td>
                      <td>5%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-green dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Approved
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-05.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Mark Hay Smith</a
                        >
                      </td>
                      <td>Nurse</td>
                      <td>Basic Salary</td>
                      <td>10%</td>
                      <td>15%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-green dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Approved
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Andrea Lalema</a
                        >
                      </td>
                      <td>Doctor</td>
                      <td>Basic Salary</td>
                      <td>10%</td>
                      <td>5%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-orange dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Pending
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Smith Bruklin</a
                        >
                      </td>
                      <td>Nurse</td>
                      <td>Basic Salary</td>
                      <td>15%</td>
                      <td>15%</td>
                      <td>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-green dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Approved
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end status-staff"
                          >
                            <a class="dropdown-item" href="javascript:;"
                              >Pending</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              >Approved</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              href="edit-provident-fund.html"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
