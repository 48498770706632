<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Dashboard </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Clinic Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="good-morning-blk">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>
                {{ getGreeting() }}, <span>{{ dash_name }}</span>
              </h2>
              <p>Have a nice day at work.</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img src="../../assets/assets/img/undraw_clincic.svg" alt="" class="dashimg" />
            </div>
          </div>
        </div>
      </div>
      <!-- {{ dashboard_data }}{{ dashboard_data.total_appointments }} -->
      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d1">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-calendar dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Total Appointments</h4>
              <h2 class="text-light">
                <span class="conter-up">
                  {{
                    dashboard_data?.appointments?.total_appointments == 0 ||
                      dashboard_data?.appointments?.total_appointments == null ||
                      dashboard_data?.appointments?.total_appointments == ""
                      ? 0
                      : dashboard_data?.appointments?.total_appointments
                  }}
                </span>
                <!-- <span class="conter-up">{{
                      dashboard_data?.total_appointments == 0
                        ? 0
                        : dashboard_data?.total_appointments
                    }}</span> -->
              </h2>
              <p class="sub_design">
                <span class="passive-view" v-if="dashboard_data?.appointments?.result == 'Positive'"><i
                    class="feather-arrow-up-right me-1"></i>
                  {{ dashboard_data?.appointments?.percentage || 0 }}%</span>
                <span class="negative-view" v-if="dashboard_data?.appointments?.result == 'Negative'">
                  <i class="feather-arrow-down-left me-1"></i>
                  {{ dashboard_data?.appointments?.percentage || 0 }}%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d2">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-user dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Total Patients</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  dashboard_data?.new_patients?.new_patient == 0 ||
                    dashboard_data?.new_patients?.new_patient == null ||
                    dashboard_data?.new_patients?.new_patient == ""
                    ? 0
                    : dashboard_data?.new_patients?.new_patient
                }}</span>
              </h2>

              <p class="sub_design">
                <span class="passive-view" v-if="
                  dashboard_data?.new_patients?.new_patient_result ==
                  'Positive'
                "><i class="feather-arrow-up-right me-1"></i>
                  {{
                    dashboard_data?.new_patients?.new_patient_percentage || 0
                  }}%</span>
                <span class="negative-view" v-if="
                  dashboard_data?.new_patients?.new_patient_result ==
                  'Negative'
                ">
                  <i class="feather-arrow-down-left me-1"></i>
                  {{
                    dashboard_data?.new_patients?.new_patient_percentage || 0
                  }}%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d3">
            <div class="dash-boxs comman-flex-center">
              <i class="fa  fa-user-md dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Total Doctors</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  dashboard_data?.total_doctor?.total_doctor == 0 ||
                    dashboard_data?.total_doctor?.total_doctor == null ||
                    dashboard_data?.total_doctor?.total_doctor == ""
                    ? 0
                    : dashboard_data?.total_doctor?.total_doctor
                }}</span>
                <!-- <span class="counter-up">{{
                  !!dashboard_data?.total_doctor ? dashboard_data?.total_doctor : 0
                }}</span> -->
                <!-- <span class="counter-up">{{
                  dashboard_data?.total_doctor || 0
                }}</span> -->
              </h2>
              <p class="sub_design">
                <span class="passive-view" v-if="
                  dashboard_data?.total_doctor?.total_doctor == 'Positive'
                "><i class="feather-arrow-up-right me-1"></i>
                  {{
                    dashboard_data?.total_doctor?.percentage || 0
                  }}%</span>
                <span class="negative-view" v-if="
                  dashboard_data?.total_doctor?.total_doctor == 'Negative'
                ">
                  <i class="feather-arrow-down-left me-1"></i>
                  {{
                    dashboard_data?.total_doctor?.percentage || 0
                  }}%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d4">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-wallet dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Total Earning</h4>
              <h2 class="text-light">
                ₦
                <span class="counter-up">
                  {{
                    dashboard_data?.earnings?.earning == 0 ||
                      dashboard_data?.earnings?.earning == null ||
                      dashboard_data?.earnings?.earning == ""
                      ? 0
                      : formatNumberWithCommas(
                        dashboard_data?.earnings?.earning
                      )
                  }}</span>
              </h2>

              <p class="sub_design">
                <span class="passive-view" v-if="dashboard_data?.earnings?.earnings_result == 'Positive'"><i
                    class="feather-arrow-up-right me-1"></i>
                  {{
                    dashboard_data?.earnings?.earnings_percentage || 0
                  }}%</span>
                <span class="negative-view" v-if="dashboard_data?.earnings?.earnings_result == 'Negative'">
                  <i class="feather-arrow-down-left me-1"></i>
                  {{
                    dashboard_data?.earnings?.earnings_percentage || 0
                  }}%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
          <div class="card">
            <div class="card-body">
              <div class="chart-title patient-visit">
                <h4>Today's Appointment by Gender</h4>
                <div></div>

                <div class="center-chart"></div>
                <div class="input-block mb-0">
                  <a-select class="w-100">
                    <a-select-option value="2024">2024</a-select-option>
                    <a-select-option value="2025">2025</a-select-option>
                    <a-select-option value="2026">2026</a-select-option>
                    <a-select-option value="2027">2027</a-select-option>
                  </a-select>
                </div>
              </div>
              <!-- {{ chart.chartOptions.labels }} -->
              <!-- {{ chart2.chartOptions.xaxis.categories }} -->
              <!-- <div id="patient-chart"></div> -->
              <apexchart type="bar" :options="chart2.chartOptions" :series="chart2.series" height="250"
                v-if="chart2.chartOptions.xaxis.categories">
              </apexchart>
            </div>
          </div>
        </div>


        <div class="col-12 col-md-12 col-xl-6 col-lg-6">
          <div class="card">
            <div class="card-header pb-0">
              <h4 class="card-title d-inline-block">Today's Patient Queue</h4>
              <router-link to="/Patients" class="float-end patient-views">Show all</router-link>
            </div>
            <div class="card-block table-dash">
              <div class="table-responsive" style="overflow-x: scroll">
                <table class="table mb-0 border-0 custom-table">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </th>
                      <th>Name</th>
                      <th>Sex</th>
                      <!-- <th>Appointment Time</th> -->
                      <th>Joining Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="patient in patients" :key="patient.id">
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input class="form-check-input" type="checkbox" value="something" />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5" alt="" />{{ patient.first_name }} {{ patient.last_name }}</a>
                      </td>


                      <td>{{ patient.sex }}</td>
                      <!-- <td>
                       5:00 AM
                      </td> -->

                      <td>{{ formattedDate(patient.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- <div class="card">
                <div class="card-header">
                  <h4 class="card-title d-inline-block">Upcoming Appointments</h4>
                  <router-link to="Category-GOPD" class="patient-views float-end"
                    >Show all</router-link
                  >
                </div>
                <div class="card-body p-0 table-dash">
                  <div class="table-responsive" style="overflow: visible">
                    <table class="table mb-0 border-0 custom-table">
                      <thead>
                        <tr>
                          <th>
                            <div class="form-check check-tables" hidden=''>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="something"
                              />
                            </div>
                          </th>
                          <th></th>
                          <th>Patient name</th>
                          <th>Doctor</th>
                          <th>Time</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(apt, index) in appointments" v-bind:key="index">
                          <td>
                            <div class="form-check check-tables" hidden=''>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="something"
                              />
                            </div>
                          </td>
                          <td>-</td>
                          <td>{{ apt.patient_name }}</td>
                          <td class="table-image appoint-doctor">
                            <img
                              width="28"
                              height="28"
                              class="rounded-circle"
                              src="../../assets/assets/img/profiles/avatar-02.jpg"
                              alt=""
                            />
                            <h2 class="text-light">{{ apt.doctor_name }}</h2>
                          </td>
                          <td class="appoint-time">
                            <span>{{ apt.date }} </span> {{ apt.time }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> -->
        </div>
      </div>
      <!-- <div class="row">
            <div class="col-12 col-xl-12">
              <div class="card">
                <div class="card-header pb-0">
                  <h4 class="card-title d-inline-block">Patients</h4>
                  <router-link to="/Patients" class="float-end patient-views"
                    >Show all</router-link
                  >
                </div>
                <div class="card-block table-dash">
                  <div class="table-responsive" style="overflow: visible">
                    <table class="table mb-0 border-0 custom-table">
                      <thead>
                        <tr>
                          <th>
                            <div class="form-check check-tables" hidden=''>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="something"
                              />
                            </div>
                          </th>
                          <th>Name</th>
                          <th>Patient Type</th>
                          <th>Sex</th>
                          <th>Mobile</th>
                          <th>Email</th>
                          <th>Account Number</th>
                          <th>Balance</th>
                          <th>Joining Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="patient in patients" :key="patient.id">
                          <td>
                            <div class="form-check check-tables" hidden=''>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="something"
                              />
                            </div>
                          </td>
                          <td class="profile-image">
                            <a href="#"
                              ><img
                                width="28"
                                height="28"
                                src="../../assets/assets/img/profiles/avatar-01.jpg"
                                class="rounded-circle m-r-5"
                                alt=""
                              />{{ patient.first_name }} {{ patient.last_name }}</a
                            >
                          </td>

                          <td>
                            {{ transformString(patient?.patient_type?.patient_type) }}
                          </td>
                          <td>{{ patient.sex }}</td>
                          <td>
                            <a href="javascript:;">{{ patient.phone_number }}</a>
                          </td>
                          <td>{{ patient.email }}</td>
                          <td>{{ patient?.account_number }}</td>
                          <td>₦ {{ formatNumberWithCommas(patient.balance) }}</td>
                          <td>{{ formattedDate(patient.created_at) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
    </div>
    <LabRequest />
    <!-- <div class="sidebar-overlay" data-reff=""></div> -->
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

// import ApexCharts from "apexcharts";
// import FloatSideBar from "./plugins/float_sidebar";
// import Chat from "./plugins/chat";
// import Loader from "./plugins/Loader";

// import { store } from '@/store';
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      hospital_name:
        localStorage.getItem("hospital") == "null"
          ? ""
          : localStorage.getItem("hospital"),
      dashboard_data: null,
      dash_name:
        localStorage.getItem("name") == "null"
          ? ""
          : localStorage.getItem("name"),
      male_array: [],
      female_array: [],
      month_array: [],
      chartData: [],
      piechartData: "",
      department: [],
      department_scores: [],
      appointments: "",
      patients: "",
      chart: {
        chartOptions: {
          chart: {
            id: "apexchart-example",
          },
          title: {
            // text: "Fruit distribution",
            align: "center",
            // margin: 40,
            style: {
              fontSize: "20px",
              fontWeight: "bold",
              color: "#263238",
              width: 300,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: +{
                chart: {
                  width: 300,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          // colors: ["#2E93fA", "#46DA62", "#242E8A", "#E91E63", "#FF9800"],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: "bottom",
          },
          labels: [],
        },
        series: [],
      },

      chart2: {
        series: [
          {
            name: "Male",
            data: [],
          },
          {
            name: "Female",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 50,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          title: {
            // text: "Fiction Books Sales",
          },
          xaxis: {
            categories: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            // labels: {
            //   formatter: function (val) {
            //     return val + "K";
            //   },
            // },
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
    };
  },

  methods: {
    ...mapActions({
      _getAdminDashboard: "dashboards/getAdminDashboard",
      _getAdminChartData: "dashboards/getAdminChartData",
      _getdashappointments: "dashboards/getdashappointments",
      _getPatientList: "patient/patientList",
    }),

    getchartdata() {
      this._getAdminChartData({ year: 2024 })
        .then((response) => {
          this.chartData = response.data.data;
          this.chartData = this.chartData.chart;
          this.month_array = Object.keys(this.chartData);
          console.log(this.chartData);

          //   this.female_array = Object.values(this.chartData[Female]);
          //   console.log(this.month_array);

          // Iterate through the months
          // for (const month in this.chartData) {
          //   if (this.chartData.hasOwnProperty(month)) {
          //     const monthData = this.chartData[month];

          //     // Check if "Male" key exists and push the value to the this.male_array array
          //     if (monthData.hasOwnProperty("Male")) {
          //       this.male_array.push(monthData["Male"]);
          //     }

          //     // Check if "Female" key exists and push the value to the this.female_array array
          //     if (monthData.hasOwnProperty("Female")) {
          //       this.female_array.push(monthData["Female"]);
          //     }
          //   }
          // }

          this.female_array = [];
          this.male_array = [];

          // Iterate through the months
          for (const month in this.chartData) {
            if (this.chartData.hasOwnProperty(month)) {
              const monthData = this.chartData[month];

              // Check if monthData is an empty array
              if (Array.isArray(monthData) && monthData.length === 0) {
                this.male_array.push(0);
                this.female_array.push(0);
              } else {
                // Check if "Male" key exists and push the value to the this.male_array array
                if (monthData.hasOwnProperty("Male")) {
                  this.male_array.push(monthData["Male"]);
                } else {
                  this.male_array.push(0);
                }

                // Check if "Female" key exists and push the value to the this.female_array array
                if (monthData.hasOwnProperty("Female")) {
                  this.female_array.push(monthData["Female"]);
                } else {
                  this.female_array.push(0);
                }
              }
            }
          }

          //   console.log(this.female_array);
          // console.log(this.male_array);
          //   alert(this.dashboard_data);
          // alert(this.female_array)
          this.chart2.series[0].data = this.male_array.map((value) =>
            value === null ? 0 : value
          );
          this.chart2.series[1].data = this.female_array.map((value) =>
            value === null ? 0 : value
          );
          this.chart2.chartOptions.xaxis.categories = Object.values(
            this.month_array
          );
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    getAdminDashboard() {
      //   this.getchartdata();

      this._getAdminDashboard()
        .then((response) => {
          this.dashboard_data = response.data.data;
          this.piechartData = this.dashboard_data.patient_by_dept;
          // Extract values of "category" and "appointment_count"
          this.department = this.piechartData.map(
            (patient) => patient.category
          );
          this.department_scores = this.piechartData.map(
            (patient) => patient.appointment_count
          );

          this.chart.chartOptions.labels = Object.values(this.department);
          this.chart.series = Object.values(this.department_scores);
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getdashappointments() {
      this._getdashappointments()
        .then((response) => {
          this.appointments = response.data.data;
          console.log(this.appointments, "hiuhyuyu");
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getPatientList() {
      this._getPatientList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.patients = this.response.data;
            this.patients = this.patients.reverse().slice(0, 4);
            console.log(this.patients);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getGreeting() {
      const currentTime = new Date();
      const hours = currentTime.getHours();

      let greeting;

      if (hours < 12) {
        greeting = "Good morning";
      } else if (hours < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      return greeting;
    },
    formatNumberWithCommas(number) {
      if (number.length >= 7) {
        var result = (number / 1000000).toFixed(2);
        var numString = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M";
      } else {
        var result = number;
        // Use regular expression to add commas
        var numString = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return numString;
    },

    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    transformString(input) {
      if (input === undefined || input === null) {
        return "";
      }

      // Check if the input is a string
      if (typeof input !== "string") {
        return input.toString();
      }
      return input.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
    },
  },
  created() {
    // Initialize Apex chart here
    // this.getchartdata();
  },

  mounted() {
    setTimeout(() => {
      // window.location.reload();
      // this.getdashappointments();
      this.getAdminDashboard();
      this.getPatientList();
      this.getchartdata();
    }, 3000);
  },
};
</script>

<style scoped>
.h2,
.h4 {
  color: white;
}

.d1 {
  /* background-color: #fc8955; */
  background-color: #14a79e;

  color: white;
}

.d2 {
  /* background-color: #0583d2; */
  background: rgb(255, 69, 96);
  /* background: #fbbc06; */
  color: white;
}

.d3 {
  /* background-color: #4fd6f7; */
  background: #2c50ed;
  color: white;
}

.d4 {
  /* background-color: #b8e3ff; */
  background: #222437;
  color: white;
}

.dash-boxs {
  /* background: #e8eff4; */
  background: #eff0f144;
}

.counter-up {
  color: white;
}

.dash_card_icons {
  color: white;
  font-size: 30px;
}

.sub_design {
  width: 53%;
  padding: 2px;
  border: 2px solid white;
  border-radius: 12px;
  background-color: white;
  color: black;
}

.dashimg {
  width: 100%;
  height: 130%;
  margin-left: -20%;
  /* margin-top: 650px; */
}
</style>
