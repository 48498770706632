<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Accounts </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Payments</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Fund Patient</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Account Number/Patient ID/Folder Number
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="account" @keyup="checkAccount" />

                      <div id="samall_loader"></div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Payment Date <span class="login-danger">*</span></label>
                      <input class="form-control" id="dateInput" type="date" placeholder="" v-model="payment_date"
                        :max="current_date" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Patient Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="account_name" readonly />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Payment Method
                        <span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="method">
                        <a-select-option disabled>Select Payment Method</a-select-option>
                        <a-select-option value="Cash">Cash</a-select-option>
                        <a-select-option value="POS">POS</a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Total Amount <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="amount" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <span class="text-success noformat" v-if="amount">
                      ₦ {{ formatNumberWithCommas(amount) }}</span>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Other Information </label>
                      <textarea class="form-control" rows="3" cols="30" v-model="description"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2" @click="pay()">Submit</span>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LabRequest />
    <div class="sidebar-overlay" data-reff=""></div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
// import { createToaster } from "@meforma/vue-toaster";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },
  data() {
    return {
      response: "",
      account: "",
      amount: "",
      account_name: "",
      patient_detail: "",
      id: 0,
      payment_date: "",
      method: "Cash",
      description: "",
      current_date: new Date().toISOString().split("T")[0],
    };
  },
  methods: {
    ...mapActions({
      _checkAccount: "finance/checkAccount",
      _pay: "finance/pay",
    }),
    //  removeSpaces(inputString) {
    //    // Use regular expression to remove spaces
    //   inputString = inputString.toString()
    //   return inputString.replace(/\s/g, '');
    // },
    checkAccount() {
      document.getElementById("samall_loader").style.display = "inline-block";
      // if (this.account.length === 10) {
      // this.removeSpaces(this.account)
      this._checkAccount({ account: this.account })
        .then((response) => {
          document.getElementById("samall_loader").style.display = "none";
          this.response = response.data;
          if (this.response["status"] == true) {
            // this.patient_detail = this.response.data
            this.account_name = `${this.response.data.first_name}  ${this.response.data.last_name}`;
            this.returned_account = this.response.data.account_number;
            this.id = this.response.data.id;
            // this.$toast.success(this.id);
          } else if (this.response["status"] == false) {
          }
        })
        .catch((error) => {
          document.getElementById("samall_loader").style.display = "none";
          console.log(error);
        });
    },

    pay() {
      // if (this.account.length < 11 || this.account.length > 11) {
      //   this.$toast.error("Number can not be more than or less than 11 digits");
      // } else
      //   if (isNaN(this.account) || isNaN(this.amount)) {
      //   this.$toast.error("This input only accepts digits");
      // } else 
      if (this.amount == "") {
        this.$toast.error("Amount cannot be empty");
      } else {
        if (this.account == this.returned_account) {
          $(".inloader").show();

          this._pay({
            id: this.id,
            payload: {
              amount: Number.parseInt(this.amount),
              method: this.method,
              payment_date: this.payment_date,
              description: this.description,
            },
          })
            .then((response) => {
              $(".inloader").hide();

              this.response = response.data;
              //   this.response
              this.$toast.success(this.response["message"]);

              if (this.response["status"] == true) {
                window.location = "Payments";

                $(".inloader").hide();

                // this.$router.push({ name: "Payments" });
              }
            })
            .catch((error) => {
              $(".inloader").hide();

              if (error.response) {
                const status = error.response.status;
                const data = error.response.data;

                if (status == 400 || status == 422 || status == 401) {
                  this.$toast.error(error.response.data.message);
                } else if (status === 500) {
                  this.$toast.error(
                    "Network Error, Check your internet conectivity."
                  );
                }
              }
            });
        } else {
          this.$toast.error("Unable to retrieve account.");
        }
      }
      //
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted() {
    // document.getElementById("dateInput").valueAsDate = new Date();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.noformat {
  display: inline-block;
  padding-top: 10px;
}

#samall_loader {
  display: none;
  border: 6px solid #f3f3f3;
  border-top: 4px solid #14a79e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 20%;
  left: 95%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
