<template>
  <div class="cont">
    <!----------last modal to be added------------->
    <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster operation_note_modal"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add operation Note</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4" style="overflow-y: scroll;">
            <!-- body starts here -->
            <form>
              <div class="row">

                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <!-- <label>Note </label> -->
                    <textarea id="operation_note" rows="4" cols="80" class="form-control summernote"
                      placeholder="Enter your message here" v-model="operation_note"></textarea>

                  </div>
                </div>
                <div class="col-12 col-sm-6"></div>
                <div class="col-6">
                  <div class="doctor-submit text-end">
                    <span type="submit" class="btn btn-primary submit-form me-2" @click="add_patient_operation_note()">
                      Submit
                    </span>
                    <span class="btn btn-primary cancel_nurse_note bg-danger text-light">Cancel</span>
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},
  data() {
    return {
      operation_note: ""
    };
  },

  methods: {
    ...mapActions({
      _add_patientoperation_note: "patient/add_patientoperation_note",
    }),

    add_patient_operation_note() {
      $(".inloader").show();
      $(".operation_note_modal").modal("hide");
      const bioDataPayload = {
        operation: this.operation_note
      };

      const appointmentId = localStorage.getItem("apt_id");
      this._add_patientoperation_note({ appointmentId, payload: bioDataPayload })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // this.$router.push({ name: "Patients" });
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    initNote() {
      $('#operation_note').summernote({
        height: 600, // Set the height of the editor
        focus: true,
        callbacks: {
          onChange: (contents) => {
            this.operation_note = contents; // Update Vue's data property when Summernote content changes
          },
          onInit: function() {
        // Define your default table template
        const defaultTable = `
          <table style="width:100%; border-collapse:collapse; table-layout: fixed;">
          <thead>
            <tr>
              <th style="border: 1px solid #000; height:auto;" colspan="3">Anesthesia:  </th>
            </tr>
             <tr>
              <th style="border: 1px solid #000; height:auto;" colspan="3">Anesthetist:  </th>
             
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #000; height:auto;width:50%;word-wrap: break-word;" colspan="2">Surgeon(s): </td>
              <td style="border: 1px solid #000; height:auto;width:50%;word-wrap: break-word;" >Assistant(s):</td>
            </tr>
             <tr>
              <td style="border: 1px solid #000; height:auto;word-wrap: break-word;" colspan="3">Operation:</td>
            </tr>
            <tr>
              <td style="border: 1px solid #000; height:auto;word-wrap: break-word;" colspan="3"><div style="font-weight:bold;width:100%">Findings:</div><br></td>
            </tr>
             <tr>
              <td style="border: 1px solid #000; height:auto;word-wrap: break-word;" colspan="3"><div style="font-weight:bold">Procedures:</div><br></td>
            </tr>
             <tr>
              <td style="border: 1px solid #000; height:auto;word-wrap: break-word;" colspan="3"><div style="font-weight:bold">Suture Material (Details of Specimens):</div><br></td>
            </tr>
          </tbody>
        </table>`;

        // Set the default content
        $('#operation_note').summernote('code', defaultTable);
      }
        },
      });
    }

  },
  mounted() {
    
    this.initNote();
    $(".cancel_nurse_note").click(function () {
      $(".operation_note_modal").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advnced_vital_link {
  cursor: pointer;
  color: #14a79e;
  display: inline-block;
  margin-top: 20px;
}

td {
    word-wrap: break-word; /* or use overflow-wrap: break-word; */
    overflow: hidden; /* optional */
}
</style>
