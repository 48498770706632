<template>
  <div
    id="permission_modal"
    class="modal animate__animated animate__zoomIn animate__faster"
    style="margin-top: 80px"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <h3>
            Assign permissions to <b>{{ hospital_name }}</b>
          </h3>
          <!-- Table Header p-4-->
          <div class="page-table-header mb-2">
            <div class="row align-items-center" id="permstogglebut">
              <div class="col">
                <div class="doctor-table-blk">
                  <h3>Permisions</h3>
                </div>
              </div>
              <div class="col-2 text-end float-end ms-auto">
                <div class="btn-group selectbtn">
                  <button
                    type="button"
                    class="btn dropdown-toggle btn-primary btn-xl"
                    data-bs-toggle="dropdown"
                  >
                    {{ permision_selector || "Permissions" }}
                  </button>
                  <div class="dropdown-menu">
                    <span class="dropdown-item text-bg-secondary"
                      >Select List</span
                    >
                    <span
                      class="dropdown-item"
                      v-for="(name, index) in option_list"
                      v-bind:key="index"
                      @click="selectpermission(name)"
                      >{{ name }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-2 text-end float-end ms-auto">
                <input
                  class="form-control"
                  type="text"
                  v-model="role"
                  placeholder="Add a new role"
                  disabled
                />
              </div>

              <div class="col-2" hidden>
                <div class="btn-group selectbtn">
                  <button
                    type="button"
                    class="btn dropdown-toggle btn-primary btn-xl"
                    data-bs-toggle="dropdown"
                    style="width: 90%"
                  >
                    Select roles
                  </button>
                  <div class="dropdown-menu">
                    <span class="dropdown-item text-bg-secondary"
                      >Select Roles</span
                    >
                    <span
                      class="dropdown-item"
                      v-for="(r, index) in roles"
                      v-bind:key="index"
                      >{{ r.role }}
                      <input
                        class="form-check-input checkb role_seclect"
                        type="checkbox"
                        v-model="selectedRole"
                        :value="r.id"
                    /></span>
                  </div>

                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="editRole()"
                    style="
                      padding-top: 15px;
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                    >Update</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <div class="table-responsive" style="overflow: visible">
            <table
              class="table border-0 custom-table comman-table table-striped table-hover mb-0"
            >
              <thead>
                <tr>
                  <th>
                    <div class="form-check check-tables" hidden="">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="something"
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th></th>
                  <th>Slug</th>
                  <th>Select</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="Permission in _filtered_rolePermission"
                  :key="Permission.id"
                >
                  <!-- <tr> -->
                  <td>
                    <div class="form-check check-tables" hidden="">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="something"
                      />
                    </div>
                  </td>
                  <td>{{ Permission.name }}</td>
                  <td></td>
                  <td>{{ Permission.code }}</td>
                  <td>
                    <input
                      class="form-check-input checkb"
                      type="checkbox"
                      v-model="rolePermission"
                      :value="Permission.id"
                    />
                  </td>

                  <td class="text-end"></td>
                </tr>

                <tr>
                  <td colspan="6">
                    <div class="col-12">
                      <div class="doctor-submit text-end" id="hereupcanc">
                        <span
                          type="submit"
                          class="btn btn-primary submit-form me-2"
                          @click="editPermission()"
                          >Update</span
                        >

                        <button
                          type="submit"
                          class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                          data-dismiss="modal"
                          @click="close_modal()"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- /.modal -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Settings_menu";

import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      _permissions: [],
      rolePermission: null,
      _filtered_rolePermission: [],
      permision_selector: "Doctor",
      option_list: [],
      role: null,
      role_id: null,
      roles: [],
      selectedRole: null,
      institution_id: null,
      hospital_name: null,
      // own_healthcare: localStorage.getItem("health_care_type"),
      // own_healthcare: "",
    };
  },
  props: {
    permissiondata: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      _getPermissions: "general/getPermissions",
      _editPermission: "general/editPermissionAndRole",
      _getrole: "general/getrole",
      _editRole: "general/editPermissionAndRole",
    }),
    getPermissions() {
      this._getPermissions()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this._permissions = this.response.data;
            this._permissions = this._permissions.reverse();
            for (const obj of this._permissions) {
              this.option_list.push(obj.category);
            }
            this._filtered_rolePermission = this._permissions.filter(
              (obj) => obj.category == this.permision_selector
            );

            this._filtered_rolePermission =
              this._filtered_rolePermission[0].permission;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    selectpermission(permission_name) {
      this.permision_selector = permission_name;
      this._filtered_rolePermission = this._permissions.filter(
        (obj) => obj.category == this.permision_selector
      );
      this._filtered_rolePermission =
        this._filtered_rolePermission[0].permission;
    },
    editPermission() {
      $(".inloader").show();

      this._editPermission({
        id: this.institution_id,
        payload: {
          type: "permissions",
          ids: this.rolePermission,
        },
      })
        .then((response) => {
          $(".inloader").hide();

          // this.Permission=''
          // this.description=''
          this.getPermissions();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            $("#permission_modal").modal("hide");
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    getrole() {
      this._getrole()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.roles = this.response.data;

            this.roles = this.roles.filter(
              (item) => item.own_healthcare == false
            );
            console.log(this.roles);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },

    editRole() {
      this._editRole({
        id: this.institution_id,
        payload: {
          type: "roles",
          ids: this.selectedRole,
        },
      })
        .then((response) => {
          this.getPermissions();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // window.location = "Settings";
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    assign_point() {
      this.rolePermission = this.permissiondata.permissions;
      this.selectedRole = this.permissiondata.roles;
      this.role = this.permissiondata.role_name;
      this.role_id = this.permissiondata.role_id;
      this.institution_id = this.permissiondata.institution_id;
      this.hospital_name = this.permissiondata.name;
    },
    close_modal() {
      $("#permission_modal").modal("hide");
    },
  },
  watch: {
    permissiondata: {
      handler() {
        this.assign_point();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getrole();
    this.getPermissions();

    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    // this.getPermissions();
    // this.getNonHodDoctor();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n2 {
  background-color: #14a79e;
}

.checkb {
  height: 25px;
  width: 25px;
}

.dropdown-item {
  font-size: 15px;
}

.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}

.dropdown-menu {
  height: 200px;
  width: 100%;
  border-radius: 8px;
  overflow-x: scroll;
}
.role_seclect {
  float: right;
  border: 1px solid #00d3c7;
}

@media (max-width: 1366px) {
}

@media (max-width: 1280px) {
}

@media (max-width: 800px) {
 #hereupcanc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

}

@media (max-width: 480px) {
  .modal-body {
    overflow-x: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }

  #permstogglebut {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  .ms-auto {
    margin-left: 0 !important;
    width: 100% !important;
  }
}
</style>
