<template>
  <Loader id="loadercomp" />


      <div class="page-wrapper">
        <div class="content">
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">Doctor Shedule</li>
                  <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
                  <li class="breadcrumb-item active">Add Schedule</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <form>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-heading">
                          <h4>Add Schedule</h4>
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6" v-if="role == 'Admin'">
                        <div class="input-block local-forms">
                          <label>Department <span class="login-danger">*</span></label>
                          <select class="sl" v-model="mcategory" @click="getdoctors()">
                            <option value="" disabled selected>Choose a Category</option>
                            <option :value="data" v-for="data in category" :key="data.id">
                              {{ data.category }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <!-- <div class="input-block local-forms"  v-if="role=='Doctor'">
                          <label>Doctor Name <span class="login-danger">*</span></label>
                          <input class="form-control" type="text" v-model="doctor_name">
                        </div> -->

                        <div class="input-block local-forms" v-if="role == 'Admin'">
                          <label>Doctor Name <span class="login-danger">*</span></label>
                          <select class="sl" v-model="doctor_id2">
                            <option value="" disabled selected>Choose a Doctor</option>
                            <option :value="d.id" v-for="d in doctors" :key="d.id">
                              {{ d.first_name }} {{ d.last_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-xl-6" v-if="role == 'Doctor'"></div>

                      <div class="col-12 col-md-6 col-xl-4">
                        <div class="input-block local-forms">
                          <label>Date <span class="login-danger">*</span></label>
                          <input
                            class="form-control"
                            type="date"
                            v-model="date"
                            id="edate"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-4">
                        <div class="input-block local-forms">
                          <label>Start Time <span class="login-danger">*</span></label>

                          <input type="time" class="form-control" v-model="start_time" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-4">
                        <div class="input-block local-forms">
                          <label>End Time <span class="login-danger">*</span></label>

                          <input type="time" class="form-control" v-model="end_time" />
                        </div>
                      </div>
                      <div class="col-12 col-sm-12">
                        <div class="input-block local-forms">
                          <label>Notes <span class="login-danger">*</span></label>
                          <textarea
                            class="form-control"
                            rows="3"
                            cols="30"
                            v-model="note"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-4">
                        <div class="input-block select-gender">
                          <label class="gen-label"
                            >Status <span class="login-danger">*</span></label
                          >
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="true"
                                v-model="status"
                              />Active
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="false"
                                v-model="status"
                              />In Active
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="doctor-submit text-end">
                          <span
                            type="submit"
                            class="btn btn-primary submit-form me-2"
                            @click="add_schedule()"
                            >Create Schedule</span
                          >
                          <span
                            type="submit"
                            class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                            @click="$router.go(-1)"
                            >Cancel</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
       
    </div>
  </div>
</template>
<script>
import Header from "../../plugins/Header";
import Sidebar from "../../plugins/Side_bar";
import Loader from "../../plugins/Loader.vue";

import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    
    Loader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      doctor_name: localStorage.getItem("Fname") + " " + localStorage.getItem("Lname"),
      category: "",
      mcategory: "",
      day: "",
      note: "",
      status: false,
      role: "",
      doctor_id1: localStorage.getItem("doc_id"),
      doctor_id2: 0,
      role: localStorage.getItem("role"),
      specialty: "",
      doctors: "",
      doctor_id: 0,
      start_time: "",
      end_time: "",
      date: "",
    };
  },

  methods: {
    ...mapActions({
      _add_schedule: "general/add_schedule",
      _Listcategory: "general/category",
      _getdoctors: "general/getdoctors",
    }),
    add_schedule() {
      // var to = $("#datetimepicker4").val();
      // var start = $("#datetimepicker3").val();
      // var date = $("#date").val();
      let status = this.status == "true" ? 1 : 0;

      if (this.role == "Doctor") {
        this.doctor_id = this.doctor_id1;
      } else if (this.role == "Admin") {
        this.doctor_id = this.doctor_id2;
      }

      this._add_schedule({
        date: this.date,
        start_time: this.start_time,
        end_time: this.end_time,
        note: this.note,
        isActive: status,
        doctor_id: this.doctor_id,
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
          window.location = "Schedules";
        })
        .catch((error) => {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },
    Listcategory() {
      this._Listcategory()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.category = this.response.data;
            console.log(this.category);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },

    getdoctors() {
      this.Listcategory();

      this._getdoctors(this.mcategory.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.doctors = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching docors", error);
        });
    },
  },

  mounted() {
    this.Listcategory();
    const currentDate = new Date().toISOString().split("T")[0];
    document.getElementById("edate").min = currentDate;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sl {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
