<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Settings</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Onboard Settings</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="settings-menu-links">
        <ul class="nav nav-tabs menu-tabs">
          <li class="nav-item active">
            <router-link to="/Settings" class="nav-link"
              >General Settings</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/View-Roles" class="nav-link"
              >View Roles</router-link
            >
          </li>
          <li class="nav-item not_for_admin" v-if="role == 'Super Admin'">
            <router-link to="/View-Category" class="nav-link"
              >View Categories</router-link
            >
          </li>
          <!-- <li class="nav-item hide_for_now" hidden="">
      <router-link to="/Assign-Permission" class="nav-link">Assign Permission</router-link>
    </li> -->
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Loacalization-Settings" class="nav-link"
              >Localization</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Payment-Settings" class="nav-link"
              >Payment Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Email-Settings" class="nav-link"
              >Email Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Login-Settings" class="nav-link"
              >Social Media Login</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Links" class="nav-link"
              >Social Links</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/SEO-Settings" class="nav-link"
              >SEO Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Theme-Settings" class="nav-link"
              >Theme Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Change-Password" class="nav-link"
              >Change Password</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Other-Settings" class="nav-link"
              >Others</router-link
            >
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <div class="table-responsive">
                <a-table :dataSource="data" :columns="columns">
                  <template #bodyCell="{ column }">
                    <template v-if="column.key == 'action'">
                      <div class="row text-center">
                        <div class="col-4">
                          <input
                            class="form-check-input checkb"
                            type="radio"
                            v-model="method"
                            value="bill_by_tablet"
                            @click="showbtnfunc('bill_by_tablet')"
                          />
                          <label for="option1"> Bill by Tablets</label>
                        </div>
                        <div class="col-4">
                          <input
                            class="form-check-input checkb"
                            type="radio"
                            v-model="method"
                            value="bill_by_card"
                            @click="showbtnfunc('bill_by_card')"
                          />
                          <label for="option1"> Bill by Card</label>
                        </div>
                        <div class="col-4" id="confmr">
                          <span
                            v-if="showbtn"
                            @click="confirmAction()"
                            class="w-100 btn btn-primary"
                          >
                            Confirm
                          </span>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Staff_Setting_Menu";
import { mapActions } from "vuex";
import Swal from "sweetalert2";

const columns = [
  {
    title: "Setting",
    dataIndex: "setting",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Action",
    // dataIndex: "action",
    key: "action",
  },
];

const data = [
  {
    setting: "Pharmacy Settings",
    description: "How do you want to bill your Prescriptions?",
  },
];

export default {
  name: "Onboard_settings",
  data() {
    return {
      method: null,
      columns,
      data,
      role: localStorage.getItem("role"),
      showbtn: true,
    };
  },
  methods: {
    ...mapActions({
      _onboard_settings: "general/onboard_settings",
    }),

    onboard_settings() {
      $(".inloader").show();
      this.showbtn = false;

      this._onboard_settings({ pharmacy_billing: this.method })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          if (this.response["status"] == true) {
            localStorage.setItem("settings", true);
            // $("#Onboard_settings").modal("hide");
            this.$toast.success("Settings updated successfully");
            localStorage.setItem("settings_info",JSON.stringify(this.response.data));
            this.method = this.response.data.Pharmacy_billing
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
        });
    },
    showbtnfunc(val) {
      if (this.method != val) {
        this.showbtn = true;
      } else {
        this.showbtn = false;
      }
    },
    confirmAction() {
      Swal.fire({
        title: "Are you sure you want to change this setting?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.onboard_settings();
          Swal.fire({
            title: "Settings Changed!",
            text: "Settings has been Changed.",
            icon: "success",
          });
        }
      });
    },
  },

  mounted() {
    const settings_info = JSON.parse(localStorage.getItem("settings_info"));
    // if(settings_info!='undefined'){
      this.method = settings_info?.Pharmacy_billing
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkb {
  block-size: 15px;
  inline-size: 15px;
  border: 2px solid black;
}
label {
  margin-inline-start: 10px;
}

@media (max-width: 480px){
  #confmr{
    width: 100px !important;
  }
}
</style>
