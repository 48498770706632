<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Accounts </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Finance Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="good-morning-blk not_for_admin">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>
                {{ getGreeting() }}, <span> {{ dash_name }}</span>
              </h2>
              <p>Have a nice day at {{ hospital_name }}</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img src="../../assets/assets/img/undraw_financial_data.svg" alt="" class="dashimg" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d1">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-credit-card dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Deposit Count</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  finance_data.deposits == 0 ||
                    finance_data.deposits == null ||
                    finance_data.deposits == ""
                    ? 0
                    : finance_data.deposits
                }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"><i class="feather-arrow-up-right me-1"></i>40%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d2">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-user dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Total Accounts</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  finance_data.total_accounts == 0 ||
                    finance_data.total_accounts == null ||
                    finance_data.total_accounts == ""
                    ? 0
                    : finance_data.total_accounts
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"><i class="feather-arrow-up-right me-1"></i>20%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d3">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-money dash_card_icons">&#xf0d6;</i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Cash Deposits</h4>
              <h2 class="text-light">
                ₦
                <span class="counter-up">{{
                  finance_data.monetary_deposits == 0 ||
                    finance_data.monetary_deposits == null ||
                    finance_data.monetary_deposits == ""
                    ? 0
                    : formatNumberWithCommas(finance_data.monetary_deposits)
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="negative-view"><i class="feather-arrow-down-right me-1"></i>15%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d4">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-wallet dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Earnings</h4>
              <h2 class="text-light">
                ₦
                <span class="counter-up">
                  {{
                    finance_data.earnings == 0 ||
                      finance_data.earnings == null ||
                      finance_data.earnings == ""
                      ? 0
                      : formatNumberWithCommas(finance_data.earnings)
                  }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <!-- Number.parseInt(dashboard_data?.earnings).toLocaleString("en-US") -->
                <span class="passive-view"><i class="feather-arrow-up-right me-1"></i>30%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="chart-title patient-visit mb-0">
                <h4>Monthly Transaction Statistics</h4>

                <div class="input-block mb-0">
                  <a-select class="w-100">
                    <a-select-option value="2024">2024</a-select-option>
                    <a-select-option value="2025">2025</a-select-option>
                    <a-select-option value="2026">2026</a-select-option>
                    <a-select-option value="2027">2027</a-select-option>
                  </a-select>
                </div>
              </div>
              <!-- <div id="apexcharts-area"></div> -->
              <apexchart type="line" :options="chart.chartOptions" :series="chart.series" height="400">
              </apexchart>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-md-12 col-lg-6 col-xl-4 d-flex">
              <div class="card">
                <div class="card-body">
                  <div id="radial-patients"></div>
                </div>
              </div>
            </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

// import FloatSideBar from "./plugins/float_sidebar";
// import Chat from "./plugins/chat";
// import Loader from "./plugins/Loader";

// import { store } from '@/store';

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      Tdeposits: 0,
      Taccounts: 0,
      Tdeposit: 0,
      Tearning: 0,
      hospital_name:
        localStorage.getItem("hospital") == "null"
          ? ""
          : localStorage.getItem("hospital"),
      dash_name:
        localStorage.getItem("name") == "null"
          ? ""
          : localStorage.getItem("name"),
      finance_data: "",
      financechartdata: "",
      monthly_stat: [],
      chart: {
        chartOptions: {
          chart: {
            height: 200,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            categories: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
          },
        },
        series: [
          {
            name: "Income",
            color: "#14a79e",
            data: [],
          },
        ],
      },
    };
  },

  methods: {
    ...mapActions({
      _getfinanceDashboard: "dashboards/getfinanceDashboard",
      _getfinancechart: "dashboards/getfinancechart",
    }),
    getfinanceDashboard() {
      //   this.getchartdata();
      this._getfinanceDashboard()
        .then((response) => {
          this.finance_data = response.data.data;
          console.log(this.finance_data);
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getfinancechart() {
      //   this.getchartdata();
      this.getfinanceDashboard();
      this._getfinancechart({ year: 2024 })
        .then((response) => {
          this.financechartdata = response.data.data.chart;
          this.monthly_stat = Object.values(this.financechartdata);
          console.log(this.monthly_stat);
          this.chart.series[0].data = this.monthly_stat;
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getGreeting() {
      const currentTime = new Date();
      const hours = currentTime.getHours();

      let greeting;

      if (hours < 12) {
        greeting = "Good morning";
      } else if (hours < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      return greeting;
    },
    formatNumberWithCommas(number) {
      if (number >= 1000000) {
        var result = (number / 1000000).toFixed(2);
        var numString = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M";
      } else {
        // Convert number to string and use regular expression to add commas
        var numString = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return numString;
    },
  },

  mounted() {
    setTimeout(() => {
      this.getfinanceDashboard();
      this.getfinancechart();
    }, 3000);
    var role = localStorage.getItem("role");
    if (role == "Super Admin" || role == "Admin") {
      $(".not_for_admin").hide();
    }
  },
};

// setTimeout(() => {
// 	location.reload()
// }, 3000);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.h2,
.h4 {
  color: white;
}

.d1 {
  /* background-color: #fc8955; */
  background-color: #14a79e;

  color: white;
}

.d2 {
  /* background-color: #0583d2; */
  background: rgb(255, 69, 96);
  /* background: #fbbc06; */
  color: white;
}

.d3 {
  /* background-color: #4fd6f7; */
  background: #2c50ed;
  color: white;
}

.d4 {
  /* background-color: #b8e3ff; */
  background: #222437;
  color: white;
}

.dash-boxs {
  /* background: #e8eff4; */
  background: #eff0f144;
}

.counter-up {
  color: white;
}

.dash_card_icons {
  color: white;
  font-size: 30px;
}

.sub_design {
  width: 53%;
  padding: 2px;
  border: 2px solid white;
  border-radius: 12px;
  background-color: white;
  color: black;
}

.dashimg {
  width: 100%;
  height: 130%;
  margin-left: -20%;
  /* margin-top: 650px; */
}
</style>
