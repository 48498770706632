<template>
  <!-- make_a_request -->

  <!-- third modal for confirmation -->

  <div
    class="modal animate__animated animate__zoomIn animate__faster add_billing"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Billing</h3>
            <p>Add Billing</p>
          </div>
          <div class="row request_row">
            <div class="col-6 ib">
              <div class="btn-group selectbtn">
                <button
                  type="button"
                  class="btn dropdown-toggle service_type_btn"
                  data-bs-toggle="dropdown"
                  id="service_type_btn"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ service_type || "Searvice List" }}
                </button>
                <div class="dropdown-menu">
                  <span class="dropdown-item" @click="customfunc()"
                    >Custom</span
                  >
                  <span
                    class="dropdown-item"
                    v-for="data in service_lists"
                    v-bind:key="data.id"
                    @click="get_service(data)"
                    >{{ data.service }}</span
                  >
                </div>
              </div>
            </div>

            <div class="col-6 ib">
              <div class="btn-group selectbtn" v-if="custom == false">
                <button
                  type="button"
                  class="btn department_btn"
                  id="dept_btn"
                  aria-expanded="false"
                >
                  <span v-if="service_price">₦ </span>
                  {{ formatNumberWithCommas(service_price) || "Price" }}
                </button>
              </div>

              <div
                class="input-block local-forms"
                style="height: 50px; margin-bottom: -20px"
                v-else-if="custom == true"
              >
                <label>Custom Service Price</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="custom_price"
                  placeholder=""
                />
              </div>
            </div>

            <div class="col-12 col-sm-12">
              <div class="input-block local-forms">
                <label>Description <span class="login-danger">*</span></label>
                <textarea
                  class="form-control"
                  rows="3"
                  cols="30"
                  v-model="description"
                >
Why are you requesting these tests and how critical is it?</textarea
                >
              </div>
            </div>
          </div>

          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-continue-btn subbtn btn-primary"
                  id="subbtn"
                  @click="add_billing()"
                >
                  Proceed
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-cancel-btn cancel cancel_request bg-danger text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { patient } from "@/store/adminStore";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},

  data() {
    return {
      reponse: "",
      service_price: "",
      service_id: "",
      service_type: "",
      service_lists: [],
      description: "",
      patient_id: localStorage.getItem("patient_id_billing"),
      custom: false,
      custom_price: "",
    };
  },

  methods: {
    ...mapActions({
      _add_billing: "finance/add_billing",
      _getServiceList: "finance/getServiceList",
    }),

    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    get_service(data) {
      this.service_price = data.price;
      this.service_id = data.id;
      this.service_type = data.service;
      this.custom = false;
    },

    getServiceList() {
      this._getServiceList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.service_lists = this.response.data;
            this.service_lists = this.service_lists.filter(
              (item) => item.status == true
            );
            this.service_lists = this.service_lists.reverse();
            // console.log(this.service_lists, "ll");
          }
        })
        .catch((error) => {
          console.log("Error fetching data", error);
        });
    },

    add_billing() {
      // alert(this.result_id)
      $(".inloader").show();

      this._add_billing({
        id: this.patient_id,
        payload: {
          description: this.description,
          serviceId: this.service_id,
          custom_service_fee: this.custom_price,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          // location.reload();
          $(".add_billing").modal("hide");

          this.service_price = "";
          this.service_type = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(response.data.message);
            this.getServiceList();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    customfunc() {
      if (this.custom === true) {
        this.custom = false;
      } else if (this.custom === false) {
        this.custom = true;
        this.service_type = "Custom";
        this.service_id = "";
      }
    },
  },

  mounted() {
    // $('.service_type_btn').click(function(){})
    this.getServiceList();
    // alert(this.type)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ib {
  display: inline-block;
  margin-bottom: 40px;
}

/* .request_row {
	padding-left: 3%
} */

.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}
.so {
  background-color: transparent;
}
.dropdown-menu {
  height: 90px;
  overflow-y: scroll;
  width: 100%;
  border-radius: 8px;
}
.topbadge {
  height: 35px;
  width: 100%;
  font-size: 16px;
  padding-top: 10px;
  float: right;
}
</style>
