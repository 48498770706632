<template>
  <div class="loadercont" id="loader">
    <div class="account-logo text-center">
      <!-- <img class="loader_img" src="../../assets/assets/img/Loader.gif"/> -->

      <img
        src="../../assets/assets/img/load.png"
        alt=""
        style="padding-bottom: 10px"
        class="loader_img"
       
      />
      <div class="logo-text">StellaSync</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  mounted() {
    setTimeout(() => {  
      window.location='/Login'
      // $(".loadercont").hide();
    }, 5000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background: #ffffff;
}
.imgcont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: fixed;
  width: 100%;
  /* height: 100vh; */
  z-index: 999999;

  height: 100%;
}
.account-logo {
  overflow: hidden;
}

.loader_img {
  display: block;
  margin: 0 auto; /* Centers the image horizontally */
  width: 250px;
  height: 250px;
  margin-top: 15%;
}
.logo-text {
  font-size: 38px;
  color: black;
  padding-top: 0px;
  margin-left: 10px;
  font-weight: bolder;
}



/* .logo-text {
   
    opacity: 0;
    animation: blink 1s infinite alternate;
}

.loader_img {
  
    opacity: 0;
    animation: blink 1s infinite alternate;
}

@keyframes blink {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} */

</style>
