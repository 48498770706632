<template>
  <!-- make_a_request -->

  <!-- third modal for confirmation -->

  <div
    class="modal animate__animated animate__zoomIn animate__faster lab_request lab_request"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Laboratory Request</h3>
            <p>Request a Laboratory Result</p>
          </div>
          <div class="row request_row">
            <div class="col-3 ib">
              <div class="btn-group selectbtn">
                <button
                  type="button"
                  class="btn dropdown-toggle test_type_btn"
                  data-bs-toggle="dropdown"
                  id="test_type_btn"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ test_type || "Test Type" }}
                </button>
                <div class="dropdown-menu">
                  <span
                    class="dropdown-item"
                    v-for="data in test_types"
                    v-bind:key="data.id"
                    @click="
                      testtype_get_dept_and_specialist(
                        data.lab_category_id,
                        data.lab_category,
                        data.test,
                        data.id
                      )
                    "
                    >{{ data.test }}</span
                  >
                </div>
              </div>
            </div>

            <div class="col-3 ib">
              <div class="btn-group selectbtn">
                <button
                  type="button"
                  class="btn department_btn"
                  id="dept_btn"
                  aria-expanded="false"
                >
                  {{ dept_name || "Choose a Department" }}
                </button>
                <!-- <div class="dropdown-menu"
											>
											<span class="dropdown-item">Hermatology</span>
											<span class="dropdown-item">Chemical pathology</span>
											<span class="dropdown-item">Micro Biology</span>
											<span class="dropdown-item">Histopathology</span>
										</div> -->
              </div>
            </div>

            <div class="col-3 ib">
              <div class="btn-group selectbtn so">
                <button
                  type="button"
                  class="btn dropdown-toggle lab_sceintist_btn"
                  data-bs-toggle="dropdown"
                  id="lab_sceintist_btn"
                  aria-haspopup="true"
                  aria-expanded="false"
                  disabled
                >
                  {{ lab_tech_name || "Choose a Lab Scientist" }}
                </button>
                <div class="dropdown-menu">
                  <span
                    class="dropdown-item"
                    v-for="data in filtered_lab_techs"
                    v-bind:key="data.id"
                    @click="
                      specialistname(data.first_name, data.last_name, data.id)
                    "
                    >{{
                      capitalize(data.first_name) +
                      " " +
                      capitalize(data.last_name)
                    }}</span
                  >
                  <span class="dropdown-item" v-if="filtered_lab_techs == ''"
                    >No option yet</span
                  >
                </div>
              </div>
            </div>

            <div class="col-3 ib">
              <div class="btn-group selectbtn">
                <button
                  type="button"
                  class="btn dropdown-toggle priority_btn"
                  data-bs-toggle="dropdown"
                  id="priority_btn"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ priority || "Choose Priority" }}
                </button>
                <div class="dropdown-menu" style="height: 90px">
                  <span class="dropdown-item" @click="Change_priority('High')"
                    >High</span
                  >
                  <span
                    class="dropdown-item"
                    @click="Change_priority('Moderate')"
                    >Moderate</span
                  >
                  <span class="dropdown-item" @click="Change_priority('Low')"
                    >Low</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12">
              <div class="input-block local-forms">
                <label>Description </label>
                <textarea
                  class="form-control"
                  rows="3"
                  cols="30"
                  v-model="description"
                >
Why are you requesting these tests and how critical is it?</textarea
                >
              </div>
            </div>
          </div>

          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-continue-btn subbtn btn-primary"
                  id="subbtn"
                  @click="make_request()"
                >
                  Proceed
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-cancel-btn cancel cancel_request bg-danger text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- last modal for comfirmation  -->

  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster confirm_tech_assign"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Assign to a laboratory scientist</h3>
            <p>Do You want to assign to a specific laboratory scientist?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn"
                  @click="activate_tech_list()"
                >
                  Yes
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn cancel bg-danger text-light"
                  @click="deactivate_tech_list()"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},

  data() {
    return {
      reponse: "",
      dept_name: "",
      lab_tech_name: "",
      test_type: "",
      test_types: [],
      lab_techs: "",
      filtered_lab_techs: "",
      result_id: localStorage.getItem("result_id"),

      lab_sci_id: null,
      test_type_id: null,
      lab_tech_id: null,
      description: "",
      priority: "Low",
      appointmentId: localStorage.getItem("apt_id"),
      // biodata:JSON.parse(localStorage.getItem("BioData"))
    };
  },

  methods: {
    ...mapActions({
      _get_test_type: "lab/getTestType",
      _get_lab_techs: "lab/get_lab_techs",
      _make_request: "lab/make_request",
    }),

    specialistname(name1, name2, L_id) {
      this.lab_tech_name =
        this.capitalize(name1) + " " + this.capitalize(name2);
      this.lab_tech_id = L_id;
    },

    Change_priority(priority) {
      this.priority = priority;
    },

    testtype_get_dept_and_specialist(L_id, D_name, T_name, T_id) {
      this.dept_name = D_name;
      this.lab_sci_id = L_id;
      this.test_type = T_name;
      this.test_type_id = T_id;

      this.filtered_lab_techs = this.lab_techs.filter(
        (lab_tech) => lab_tech.lab_category_id == this.lab_sci_id
      );

      console.log(this.filtered_lab_techs);
      $(".confirm_tech_assign").modal("show");
      $(".lab_request").modal("hide");
    },

    get_test_type() {
      this._get_test_type()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.test_types = this.response.data;
            this.test_types = this.test_types.reverse();
            console.log(this.test_types);
          }
        })
        .catch((error) => {
          console.log("Error fetching data", error);
        });
    },

    get_lab_techs() {
      this._get_lab_techs()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.lab_techs = this.response.data;
            this.lab_techs = this.lab_techs.reverse();
            console.log(this.lab_techs);
          }
        })
        .catch((error) => {
          console.log("Error fetching data", error);
        });
    },
    make_request() {
      // alert(this.result_id)
      $(".inloader").show();

      this._make_request({
        id: this.appointmentId,
        payload: {
          patient_result_id: this.result_id,
          lab_test_id: this.test_type_id,
          priority: this.priority,
          lab_category_id: this.lab_sci_id,
          labTech_id: this.lab_tech_id,
          description: this.description,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          location.reload();

          this.test_types = "";
          this.lab_tech_name = "";
          this.description = "";
          this.priority = "";
          this.dept_name = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(response.data.message);
            this.get_test_type();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },

    //copied from  to power doctor collaboration
    activate_tech_list() {
      $(".lab_sceintist_btn")
        .removeAttr("disabled")
        .css("background-color", "#00d3c7");
      $(".subbtn").removeAttr("disabled").addClass("btn-primary");
      $(".lab_request").modal("show");
    },

    deactivate_tech_list() {
      $(".lab_sceintist_btn")
        .attr("disabled", "disabled")
        .css("background-color", "transparent");
      $("#docbtn").removeClass("btn-primary");
      // $('.subbtn').removeAttr('disabled').addClass("btn-primary");
      $(".lab_request").modal("show");
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },

  mounted() {
    // $('.test_type_btn').click(function(){})
    this.get_test_type();
    this.get_lab_techs();
    // alert(this.type)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .comman-activitys {
	background: rgba(51, 52, 72, 0.05);
	border-radius: 0px 16px 16px 16px;
	padding: 20px;
	width: 70%;
} */

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

/* .request_row {
	padding-left: 3%
} */

.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}
.so {
  background-color: transparent;
}
.dropdown-menu {
  height: 90px;
  overflow-y: scroll;
  width: 100%;
  border-radius: 8px;
}
.topbadge {
  height: 35px;
  width: 100%;
  font-size: 16px;
  padding-top: 10px;
  float: right;
}
</style>
