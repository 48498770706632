<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="">Account </router-link>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Edit Profile</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <form>
        <div class="card-box">
          <h3 class="card-title">Basic Informations</h3>
          <div class="row">
            <div class="col-md-12">
              <div class="profile-img-wrap">
                <!-- <img v-if="profile_data.avatar == null" class="inline-block user-avatar"
                  src="../../assets/assets/img/user-512.png" alt="user" id="preview" /> -->
                <img
                  class="inline-block user-avatar"
                  :src="profile_data?.avatar"
                  :alt="profile_data?.first_name?.[0]"
                />

                <!-- <i
                  class="fa fa-user"
                  v-else-if="profile_data.avatar == null && previewer == 0"
                  style="font-size: 130px"
                ></i> -->

                <div class="fileupload btn">
                  <span class="btn-text">edit</span>
                  <input class="upload" type="file" id="upload" />
                </div>
              </div>
              <div class="profile-basic">
                <div class="row">
                  <div class="col-md-6" id="frstnam" v-if="profile_data.isAnInstitution">
                    <div class="input-block local-forms">
                      <label class="focus-label">Institution Name</label>
                      <input
                        type="text"
                        class="form-control floating"
                        v-model="profile_data.first_name"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    id="frstnam"
                    :hidden="profile_data.isAnInstitution"
                  >
                    <div class="input-block local-forms">
                      <label class="focus-label">First Name</label>
                      <input
                        type="text"
                        class="form-control floating"
                        v-model="profile_data.first_name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6" :hidden="profile_data.isAnInstitution">
                    <div class="input-block local-forms">
                      <label class="focus-label">Last Name</label>
                      <input
                        type="text"
                        class="form-control floating"
                        v-model="profile_data.last_name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6" :hidden="profile_data.isAnInstitution">
                    <div class="input-block local-forms">
                      <label class="focus-label">User Name</label>
                      <input
                        type="text"
                        class="form-control floating"
                        v-model="profile_data.username"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block local-forms">
                      <label class="focus-label" v-if="profile_data.isAnInstitution"
                        >Date Started</label
                      >
                      <label class="focus-label" v-else>Birth Date</label>
                      <div>
                        <!-- {{ dobValue }} -->
                        <input
                          class="form-control floating"
                          type="date"
                          v-model="date_started"
                          v-if="profile_data.isAnInstitution"
                        />
                        <input
                          class="form-control floating"
                          type="date"
                          v-model="dobValue"
                          v-else
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="profile_data.isAnInstitution">
                    <div class="input-block local-forms">
                      <label class="focus-label">Public Phone Number</label>
                      <input
                        type="text"
                        class="form-control floating"
                        v-model="profile_data.public_number"
                        @input="limitInput(this)"
                        maxlength="11"
                        id="phone"
                      />
                    </div>
                  </div>
                  <div class="col-md-6" v-if="profile_data.isAnInstitution">
                    <div class="input-block local-forms">
                      <label class="focus-label">Public E-mail</label>
                      <input
                        type="text"
                        class="form-control floating"
                        v-model="profile_data.public_email"
                        id="email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6" :hidden="profile_data.isAnInstitution">
                    <div class="input-block local-forms">
                      <label class="focus-label">Gender</label>
                      <a-select class="form-control" v-model:value="profile_data.sex">
                        <!-- <option selected disabled>
                          {{ sex }}
                        </option> -->
                        <a-select-option value="male">Male</a-select-option>
                        <a-select-option value="female">Female</a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-box">
          <h3 class="card-title">Contact Informations</h3>
          <div class="row">
            <div class="col-md-12" id="addrss">
              <div class="input-block local-forms">
                <label class="focus-label">Address</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="profile_data.address"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">State</label>
                <select class="form-control" v-model="state" @change="getLGA()">
                  <option disabled>Select State</option>
                  <option
                    v-for="state in state_list"
                    v-bind:key="state.name"
                    :value="state"
                  >
                    {{ state.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label>LGA <span class="login-danger">*</span></label>
                <select class="form-control" v-model="profile_data.lga">
                  <option disabled>Select Local Government</option>
                  <option
                    v-for="lga in lga_list"
                    v-bind:key="lga.state_id"
                    :value="lga.id"
                  >
                    {{ lga.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Zip Code</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="profile_data.zip_code"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Phone Number</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="profile_data.phone_number"
                  @input="limitInput(this)"
                  maxlength="11"
                  id="phone"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card-box">
          <h3 class="card-title">Biography</h3>
          <div class="row">
            <div class="col-md-12" id="biogrh">
              <div class="input-block local-forms">
                <label>Biography <span class="login-danger">*</span></label>
                <textarea
                  class="form-control"
                  rows="5"
                  cols="30"
                  v-model="this.profile_data.biography"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="card-box" :hidden="profile_data.isAnInstitution">
          <h3 class="card-title">Education Information</h3>
          <div class="row" v-for="(education, index) in educations" :key="index">
            <span class="badge badge-danger bdg">{{ index + 1 }}</span>
            <div class="col-md-6">
              <div class="input-block local-forms" id="instdd">
                <label class="focus-label">Institution</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="education.institution"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Subject</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="education.course"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Starting Date</label>
                <div>
                  <input
                    type="date"
                    class="form-control floating"
                    v-model="education.starting_date"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Complete Date</label>
                <div>
                  <input
                    type="date"
                    class="form-control floating"
                    v-model="education.complete_date"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Degree</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="education.degree"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Grade</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="education.grade"
                />
              </div>
            </div>
            <!--  -->
          </div>
          <div class="add-more" @click="add_more_instutions()">
            <span class="btn btn-primary"
              ><i class="fa fa-plus"></i> Add More Institute</span
            >
          </div>
        </div>
        <div class="card-box" :hidden="profile_data.isAnInstitution">
          <h3 class="card-title">Experience Information</h3>
          <div class="row" v-for="(experience, index) in experience" :key="index">
            <span class="badge badge-danger bdg">{{ index + 1 }}</span>

            <div class="col-md-6">
              <div class="input-block local-forms" id="compnam">
                <label class="focus-label">Company Name</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="experience.company_name"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Location</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="experience.location"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Job Position</label>
                <input
                  type="text"
                  class="form-control floating"
                  v-model="experience.job_position"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Period From</label>
                <div>
                  <input
                    type="date"
                    class="form-control floating"
                    v-model="experience.period_from"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block local-forms">
                <label class="focus-label">Period To</label>
                <div>
                  <input
                    type="date"
                    class="form-control floating"
                    v-model="experience.period_to"
                  />
                </div>
              </div>
            </div>
            <!-- <hr /> -->
          </div>
          <div class="add-more" @click="add_more_experience()">
            <span class="btn btn-primary"
              ><i class="fa fa-plus"></i> Add More Experience</span
            >
          </div>
        </div>
        <div class="text-center">
          <button
            class="btn btn-primary submit-btn mb-4"
            type="button"
            @click="updateProfile()"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      profile_data: {},
      educations: [
        {
          institution: null,
          course: null,
          starting_date: null,
          complete_date: null,
          degree: null,
          grade: null,
        },
      ],
      experience: [
        {
          index: null,
          location: null,
          job_position: null,
          period_from: null,
          period_to: null,
        },
      ],
      dob: "",
      sex: "",
      state_list: [],
      lga_list: [],
      state: {},
      previewer: 0,

      // state:{id:null,lga:[]},
    };
  },

  methods: {
    add_more_instutions() {
      if (
        this.educations[0].institution == null &&
        this.educations[0].course == null &&
        this.educations[0].starting_date == null &&
        this.educations[0].complete_date == null &&
        this.educations[0].degree == null &&
        this.educations[0].grade == null
      ) {
        this.$toast.error("You can't add another field, fill up the initial first.");
      } else {
        this.educations.push({
          institution: null,
          course: null,
          starting_date: null,
          complete_date: null,
          degree: null,
          grade: null,
        });
      }
    },
    add_more_experience() {
      if (
        this.experience[0].company_name == null &&
        this.experience[0].location == null &&
        this.experience[0].job_position == null &&
        this.experience[0].complete_date == null &&
        this.experience[0].period_from == null &&
        this.experience[0].period_to == null
      ) {
        this.$toast.error("You can't add another field, fill up the initial first.");
      } else {
        this.experience.push({
          company_name: null,
          location: null,
          job_position: null,
          period_from: null,
          period_to: null,
        });
      }
    },
    ...mapActions({
      _updateProfile: "general/update_profile",
      _getState: "general/getStateList",
    }),
    authProfile() {
      this.profile_data = JSON.parse(localStorage.getItem("ADMIN_DETAILS"));
      const gotten_educations = this.profile_data.education;
      const gotten_experience = this.profile_data.experience;
      if (
        gotten_educations !== null &&
        typeof gotten_educations !== "undefined" &&
        gotten_educations.length > 0
      ) {
        this.educations = gotten_educations;
      }

      if (
        gotten_experience !== null &&
        typeof gotten_experience !== "undefined" &&
        gotten_experience.length > 0
      ) {
        this.experience = gotten_experience;
      }

      console.log(this.profile_data);
    },
    getState() {
      this._getState()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.state_list = this.response.data;
            console.log(this.state_list);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getLGA() {
      this.lga_list = this.state.lga;
      this.profile_data.state = this.state.name;
    },
    updateProfile() {
      var input = document.getElementById("upload");
      this.profile_data.avatar = input.files[0];
      $(".inloader").show();

      this._updateProfile({
        first_name: this.profile_data.first_name,
        last_name: this.profile_data.isAnInstitution ? null : this.profile_data.last_name,
        email: this.profile_data.email,
        public_email: this.profile_data.isAnInstitution
          ? this.profile_data.public_email
          : null,
        phone_number: this.profile_data.phone_number,
        public_number: this.profile_data.isAnInstitution
          ? this.profile_data.public_number
          : null,
        education: this.educations,
        experience: this.experience,
        address: this.profile_data.address,
        lga_id: this.profile_data.lga,
        state_id: this.profile_data.state,
        // country: this.profile_data.country,
        zip_code: this.profile_data.zip_code,
        dob: this.profile_data.isAnInstitution ? null : this.dobValue,
        date_started: this.date_started,
        sex: this.sex,
        biography: this.profile_data.biography,
        username: this.profile_data.username,
        avatar: this.profile_data.avatar,
      })
        .then((response) => {
          this.response = response.data;
          $(".inloader").hide();

          this.$toast.success("Profile Updated");
          window.location = "Profile";
        })
        .catch((error) => {
          console.log(error);
          $(".inloader").hide();
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },
    imgprev() {},
    limitInput() {
      var input = document.getElementById("phone");
      // input.value = input.value.replace(/[^0-9]/g, ''); // Allow only numbers
      input.value = input.value.replace(/\D/g, "");
      if (input.value.length > input.maxLength) {
        input.value = input.value.slice(0, input.maxLength);
      }
    },
  },
  computed: {
    dobValue: {
      get() {
        return this.profile_data?.dob || "";
      },
      set(value) {
        if (this.profile_data) {
          this.profile_data.dob = value;
        }
      },
    },
    date_started: {
      get() {
        return this.profile_data?.date_started || "";
      },
      set(value) {
        if (this.profile_data) {
          this.profile_data.date_started = value;
        }
      },
    },

    stateOptions() {
      return this.state_list.map((state) => ({
        id: state.id,
        name: state.name,
      }));
    },
  },

  mounted() {
    this.authProfile();
    this.getState();
    $("input[type=file]").change(function () {
      var input = document.getElementById("upload");
      var preview = document.getElementById("preview");
      var file = input.files[0];
      var reader = new FileReader();

      reader.onload = function (e) {
        preview.src = e.target.result;

        // alert(this.previewer);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    });
    // this.profile_data.state = this.state.id;
    // this.profile_data;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bdg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 30px;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  #frstnam {
    margin-top: 10%;
  }

  #addrss {
    margin-top: 10%;
  }

  #biogrh {
    margin-top: 10%;
  }

  #instdd {
    margin-top: 10%;
  }

  #compnam {
    margin-top: 10%;
  }
}
</style>
