<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Room Allotment</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Room Number</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <SettingsMenu />

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Room Number</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></span>
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Room Number</th>
                      <th>Room Type</th>
                      <th>Admin</th>
                      <th>Date Added</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="room_number in room_numbers"
                      :key="room_number.id"
                    >
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td>{{ room_number.room_number }}</td>
                      <td>{{ room_number.room_type }}</td>
                      <td>{{ room_number.name }}</td>
                      <td>{{ formattedDate(room_number.created_at) }}</td>

                      <!-- <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a
                                href="#"
                                class="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><i class="fa fa-ellipsis-v"></i
                              ></a>
                              <div class="dropdown-menu dropdown-menu-end">
                                <a class="dropdown-item" href="edit-appointment.html"
                                  ><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_patient"
                                  ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                                >
                              </div>
                            </div>
                          </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 15%"
            v-if="main_data == '' || main_data == null"
          >
            <i
              class="fas fa-box-open"
              style="font-size: 150px; color: #14a79e; opacity: 0.4"
            ></i>
            <div class="font-size:24px">No data yet</div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Room Number</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <di class="col-12"></di>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label
                    >Add Room Number <span class="login-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g 1B"
                    v-model="room_number"
                  />
                </div>
              </div>
              <di class="col-12"></di>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label
                    >Add Room Type <span class="login-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    type="text"
                    name="cat"
                    v-model="room_type"
                  >
                    <option value="">Select Room Type</option>
                    <option
                      :value="room_type.id"
                      v-for="room_type in room_types"
                      :key="room_type.id"
                    >
                      {{ room_type.room_type }}
                    </option>
                    <!-- <option value="Syrup">Syrup</option> -->
                  </select>
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="addRoomNumber()"
                  >
                    Submit
                  </span>
                  <button
                    type="submit"
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import SettingsMenu from "../plugins/Room_setting";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      room_numbers: [],
      room_types: [],

      room_number: "",
      room_type: "",
    };
  },

  methods: {
    ...mapActions({
      _getRoomTypes: "roomAllotment/getRoomTypes",
      _getRoomNumbers: "roomAllotment/getRoomNumbers",
      _addRoomNumbers: "roomAllotment/addRoomNumber",
    }),

    getRoomNumbers() {
      this._getRoomNumbers()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.room_numbers = this.response.data;
            // this.$toast.success(this.response["message"]);
            console.log(this.room_numbers);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    getRoomTypes() {
      this._getRoomTypes()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.room_types = this.response.data;
            // this.$toast.success(this.response["message"]);
            console.log(this.room_numbers);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    addRoomNumber() {
      this._addRoomNumbers({
        room_number: this.room_number,
        room_type_id: this.room_type,
      }).then((response) => {
        this.getRoomNumbers();
        this.response = response.data;
        // console.log(response);
        // if (this.response["status"] == true) {
        this.$toast.success(this.response["message"]);
        $("#con-close-modal").modal("hide");
        // }
      });
      // .catch((error) => {
      //   console.log(error);
      //   this.$toast.error(error.response.data.data.message);
      // });
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    this.getRoomNumbers();
    this.getRoomTypes();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
