<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Patients </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Patient List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Patient List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="btn btn-primary add-pluss ms-2 add_patient"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></span>
                          <a href="#" class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2" @click="exportToPDF()"><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg" alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2" @click="exportToCSV('table.csv')"><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg" alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt=""
                        @click="exportToExcel()" /></a>
                  </div>
                </div>

                <!-- /Table Header -->
                <div class="table-responsive" style="overflow: visible">
                  <a-table :dataSource="patients" :columns="columns">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key == 'name'">
                        <span @click="getAppointmentHistory(record)" style="cursor:pointer">
                          {{ record.first_name }}
                          {{ record.last_name }}
                        </span>
                      </template>
                      <template v-if="column.key == 'patient_type'">
                        <a>
                          {{
                            transformString(record?.patient_type?.patient_type)
                          }}
                        </a>
                      </template>
                      <template v-if="column.key == 'status'">
                        <span class="custom-badge bg-dark text-light"
                          v-if="record.have_existing_appointment == false">No Appointment</span>
                        <span class="custom-badge status-green" v-if="record.have_existing_appointment == true">Has
                          Appointment</span>
                      </template>
                      <template v-if="column.key == 'created_at'">
                        <a>
                          {{ formattedDate(record.created_at) }}
                        </a>
                      </template>
                      <template v-if="column.key == 'check_in'">
                        <span class="btn btn-primary" id="binprime" @click="routetobiodata(record.id)" v-if="
                          record.have_existing_appointment == true &&
                          record.is_checked_in == false
                        ">Check in</span>
                        <span class="btn btn-danger" id="bindange" @click="Checkout_confirmAction(record.id)" v-if="
                          record.have_existing_appointment == true &&
                          record.is_checked_in == true
                        ">Check out</span>
                        <!-- Checkin_confirmAction(record.id) -->
                      </template>
                      <template v-if="column.key == 'action'">
                        <a>
                          <div class="dropdown dropdown-action">
                            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                              aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <!-- <span
                                  class="dropdown-item"
                                  title="View Patient"
                                  @click="patientBiodata(patient.id)"
                                >
                                  <i class="fa fa-tachometer m-r-5"></i> View
                                  Dashboard</span
                                > -->
                              <!-- hidden vitals nav -->
                              <span class="dropdown-item" @click="routetobiodata(record.id)"><i
                                  class="fa fa-heartbeat m-r-5"></i>Add
                                Vitals</span>
                              <span v-if="
                                $store.getters.canAccess([
                                  'book_appointment',
                                ]) &&
                                record.have_existing_appointment == false
                              " class="dropdown-item" @click="showmodal(record.id, 'book')"><i
                                  class="fa fa-calendar m-r-5" aria-hidden="true"></i>
                                Book Appointment</span>
                              <span class="dropdown-item" @click="
                                showmodal(record.appointment_id, 'reschedule')
                                " v-if="record.have_existing_appointment == true"><i class="fa fa-calendar m-r-5"
                                  aria-hidden="true"></i>
                                Reschedule Appointment</span>
                              <span class="dropdown-item" v-if="record.have_existing_appointment == true"
                                @click="confirmAction(record.appointment_id)" style="cursor: pointer"><i
                                  class="fa fa-x m-r-5" aria-hidden="true"></i>
                                Cancel Appointment</span>
                              <!-- <router-link class="dropdown-item" to="/Edit-patient"
                                  ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                                  Edit</router-link
                                >
                                <router-link class="dropdown-item" to="/Patient-Bio-Data"
                                  ><i class="fa fa-trash-alt m-r-5"></i>
                                  Delete</router-link
                                > -->
                            </div>
                          </div>
                        </a>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            style="text-align: center; margin-top: 15%"
            v-if="patients == '' || patients == null"
          >
            <i
              class="fas fa-box-open"
              style="font-size: 150px; color: #14a79e; opacity: 0.4"
            ></i>
            <div class="font-size:24px">No data yet</div>
          </div> -->
        </div>
      </div>

      <!-- fixed -->

      <!-- sample modal content ;overflow-y:scroll-->

      <div id="book_appointment"
        class="modal modal-lg animate__animated animate__zoomIn animate__faster book_appointment"
        style="margin-top:200px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Book Appointment</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <form>
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <div class="input-block local-forms" style="height: 40px">
                      <label>Date Of Appointment<span class="login-danger">*</span></label>
                      <input class="form-control" type="date" v-model="date_of_appointment" min="2024-02-22"
                        id="indate" />
                    </div>
                  </div>

                  <div class="col-12 col-sm-3">
                    <div class="input-block local-forms" style="height: 40px">
                      <label>Time Of Appointment<span class="login-danger">*</span></label>
                      <input id="timeInput" class="form-control" type="time" v-model="time_of_appointment" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-3">
                    <div class="input-block local-forms">
                      <label>Patient Type <span class="login-danger">*</span></label>
                      <a-select name="" id="" class="w-100" @change="activateinput()" v-model:value="new_patient">
                        <a-select-option value="true">New Patient</a-select-option>
                        <a-select-option value="false">Old Patient</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3">
                    <div class="input-block local-forms">
                      <label>Service <span class="login-danger">*</span></label>

                      <a-select v-model:value="service_id" class="w-100" :disabled="new_patient == 'true'">
                        <!-- < option value =" Select Service Type" disabled></option> -->
                        <a-select-option :value="x.id" v-for="x in service_lists" :key="x">
                          {{ x.service }} - {{ formatAmount(x.price, true) }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end" id="txtend">
                      <span type="submit" class="btn btn-primary submit-form me-2" @click="Bookappointment()">
                        Submit
                      </span>
                      <span class="btn btn-primary cancel-book_form bg-danger text-light">Cancel</span>
                    </div>
                  </div>
                </div>
              </form>

              <!-- body ends here -->
            </div>
          </div>
          <!-- /.modal -->
        </div>
      </div>
      <!-- second modal for confirmation -->
      <div class="modal modal-lg animate__animated animate__zoomIn animate__faster add_patient_modal" id="modchoose"
        role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Choose action</h3>
                <p>How do you want to add patient?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row" id="chrtsearch">
                  <div class="col-6" id="crtnew">
                    <span @click="route_to('Add-Patient')" class="w-100 btn btn-primary paid-continue-btn">
                      Create New Patient
                    </span>
                  </div>
                  <div class="col-6" id="crtnew">
                    <button class="w-100 btn btn-primary paid-continue-btn show_search">
                      Search Patient
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal -->

      <div id="search_modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster"
        style="margin-top:80px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Search Patient</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <div class="row">
                <div class="col-12 col-md-6 col-xl-6" id="acctnumb">
                  <div class="input-block local-forms">
                    <label>Account number <span class="login-danger">*</span></label>
                    <input class="form-control" type="text" v-model="account_number" @keyup="checkAccount"
                      maxlength="10" />
                  </div>
                </div>
                <!-- <div class="col-12 col-md-6 col-xl-2 text center">
                  <span class="fa fa-exchange" id="faz"></span>
                </div> -->
                <div class="col-12 col-md-6 col-xl-6">
                  <div class="input-block local-forms">
                    <label>Patient Name <span class="login-danger">*</span></label>
                    <input class="form-control" type="text" v-model="patient_name" disabled />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span type="submit" class="btn btn-primary submit-form me-2" @click="OTPcall()">Transfer</span>
                  <span class="btn btn-primary cancel_option cancel-form bg-danger text-light cancel-form">Cancel</span>
                </div>
              </div>
              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>

      <!-- sample modal content ;overflow-y:scroll-->

      <div id="reschedule_appointment"
        class="modal modal-lg animate__animated animate__zoomIn animate__faster reschedule_appointment"
        style="margin-top:200px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Reschedule Appointment</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <form>
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="input-block local-forms" style="height: 40px" id="bookapp">
                      <label>Date Of Appointment<span class="login-danger">*</span></label>
                      <input class="form-control" type="date" v-model="date_of_reschedule_appointment" min="2024-02-22"
                        id="indate" />
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="input-block local-forms" style="height: 40px">
                      <label>Time Of Appointment<span class="login-danger">*</span></label>
                      <input id="timeInput" class="form-control" type="time" v-model="time_of_reschedule_appointment" />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2" @click="Reschedule_appointment()">
                        Submit
                      </span>
                      <span class="btn btn-primary cancel-book_form bg-danger text-light">Cancel</span>
                    </div>
                  </div>
                </div>
              </form>

              <!-- body ends here -->
            </div>
          </div>
          <!-- /.modal -->
        </div>
      </div>

      <!-- external modal -->
      <OTP />
      <Billing />
      <AddObservationChart @proceed="execute" />
      <History :history_data_recieved="history_data" />

    </div>
  </div>
</template>
<script>
import Billing from "./plugin/Billing2.vue";

import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";
import OTP from "../plugins/OTP2";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";
import AddObservationChart from "./plugin/Observation Chart.vue";

import History from "./plugin/view_history.vue";



import XLSX from "xlsx";
import { formatAmount } from "@/utils/formatter";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Patient Type",
    key: "patient_type",
  },
  {
    title: "Sex",
    dataIndex: "sex",
    key: "sex",
  },
  {
    title: "Mobile",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Account Number/PID/Folder Number",
    dataIndex: "account_number",
    key: "account_number",
  },
  {
    title: "Appointment Status",
    key: "status",
  },
  {
    title: "Check In",
    key: "check_in",
  },
  {
    title: "Date Joined",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    key: "action",
  },
];

export default {
  name: "PatientList_Page",
  components: {
    Loader,
    Inloader,
    OTP,
    Billing,
    AddObservationChart,
    History
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      formatAmount,
      patients: [],
      issue: "",
      get_id: "",
      date_of_appointment: "",
      time_of_appointment: "",
      account_number: null,
      patient_name: "",
      patient_id: null,
      search: "",
      patient_reserve: [],
      service_id: "",
      service_lists: [],
      new_patient: "true",
      columns,
      time_of_reschedule_appointment: null,
      date_of_reschedule_appointment: null,
      temp_id: null,
      history_data: [],

    };
  },

  methods: {
    route_to(route) {
      window.location = route;
    },
    // get_idfunc(id){},
    ...mapActions({
      _getPatientList: "patient/patientList",
      _Bookappointment: "general/Bookappointment",
      _Reschedule_appointment: "general/Reschedule_appointment",
      _Delete_appointment: "general/Delete_appointment",
      _patientBoidata: "patient/patientBoidata",
      _patientCheckin: "patient/patientCheckin",

      _checkAccount: "finance/checkAccount",
      _OTP_call: "general/Transfer_OTP_call_in",
      _getServiceList: "finance/getServiceList",


      _getAppointmentHistory: "patient/getAppointmentHistory",

    }),
    getPatientList() {
      this._getPatientList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.patients = this.response.data;
            this.patients = this.patients.reverse();
            this.patient_reserve = this.patients;
            console.log(this.patients);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    transformString(input) {
      if (input === undefined || input === null) {
        return "";
      }

      // Check if the input is a string
      if (typeof input !== "string") {
        return input.toString();
      }
      return input.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
    },
    showmodal: function (id, instance) {
      if (instance == "book") {
        $("#book_appointment").modal("show");
      } else {
        $("#reschedule_appointment").modal("show");
      }
      this.get_id = id;
      // alert(this.get_id)
    },

    Bookappointment() {
      if (this.new_patient == "true") {
        this.new_patient = 1;
      } else if (this.new_patient == "false") {
        this.new_patient = 0;
      }
      $(".inloader").show();

      this._Bookappointment({
        patientId: this.get_id,
        payload: {
          issue: this.issue,
          date: this.date_of_appointment,
          time: this.time_of_appointment,
          new_patient: this.new_patient,
          service_id: this.service_id,
        },
      })
        .then((response) => {
          this.date_of_appointment = "";
          this.time_of_appointment = "";
          $("#book_appointment").modal("hide");
          $(".inloader").hide();
          this.getPatientList()
          this.issue = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          $("#book_appointment").modal("hide");
          this.issue = "";
        });
    },

    // patientBiodata(id) {
    //   this._patientBoidata(id)
    //     .then((response) => {
    //       this.response = response.data;
    //       if (this.response["status"] == true) {
    //         this.main_data1 = this.response.data;
    //         // var timeline = this.main_data1
    //         // console.log(this.main1)
    //         var result = this.main_data1.patient_result;
    //         var full_name = this.main_data1.first_name + " " + this.main_data1.last_name;
    //         console.log(result, full_name);

    //         if (this.main_data1 == null) {
    //           this.$toast.error("no data to view");
    //         } else {
    //           localStorage.setItem("patient_name", full_name);
    //           localStorage.setItem("this_patient_id", id);
    //           // localStorage.setItem("type", type);
    //           // localStorage.setItem("collaboration", collaboration);
    //           localStorage.setItem("result", JSON.stringify(result));

    //           // console.log(result)

    //           window.location = "My-Patient-Dashboard";
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log("Error fetching data", error);
    //     });
    // },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    //OTHERS..............................

    checkAccount() {
      // if (this.account.length === 10) {
      // this.removeSpaces(this.account)
      if (this.account_number.length === 10) {
        $(".inloader").show();

        this._checkAccount({ account: this.account_number })
          .then((response) => {
            $(".inloader").hide();

            this.response = response.data;
            if (this.response["status"] == true) {
              // this.patient_detail = this.response.data
              this.patient_name = `${this.response.data.first_name}  ${this.response.data.last_name}`;
              this.returned_account = this.response.data.account_number;
              this.patient_id = this.response.data.id;
              // this.$toast.success(this.id);
            } else if (this.response["status"] == false) {
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log(error);
          });
      }
    },

    updateTimerDisplay(timeInSeconds) {
      // Get the timer element
      const timerElement = document.getElementById("count-down");

      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      timerElement.textContent = formattedTime;
    },
    performAction() {
      $("#otp_modal").modal("hide");
      $(".xvx").show();
    },
    startCountdown() {
      const countdownTime = 5 * 60; // 5 minutes * 60 seconds
      let timeRemaining = countdownTime;
      // Update the display initially
      this.updateTimerDisplay(timeRemaining);

      let _vm = this;
      // Set an interval to update the countdown every second
      const countdownInterval = setInterval(function () {
        timeRemaining--;

        // Update the display
        _vm.updateTimerDisplay(timeRemaining);

        // Check if the countdown reached 0
        if (timeRemaining <= 0) {
          // Clear the interval
          clearInterval(countdownInterval);

          // Perform the action when the countdown reaches 0
          _vm.performAction();
        }
      }, 1000); // Update every 1000 milliseconds (1 second)
    },

    OTPcall() {
      localStorage.setItem("v_patient_id", this.patient_id);

      $(".inloader").show();
      this._OTP_call(this.patient_id)
        .then((response) => {
          $("#search_modal").modal("hide");

          this.account_number = "";
          this.patient_name = "";
          $(".inloader").hide();
          this.test_types = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            $("#otp_modal").modal("show");
            this.startCountdown();
            $("#search_modal").modal("hide");
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          $("#search_modal").modal("hide");

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          $(".inloader").hide();
        });
    },
    routetobiodata(tran) {
      this.temp_id = tran

      const stat = localStorage.getItem('biodata_added')
      if (stat == 1) {
        this.Checkin_confirmAction(tran)
        // localStorage.setItem('biodata_added',0)
      }
      else {
        localStorage.setItem("this_patient_id", tran);
        $(".observation_chart_modal").modal("show");
      }
      // window.location.href = "Patient-Biodata";
    },


    search_the_list() {
      if (this.search == "") {
        this.patients = this.patient_reserve;
      } else {
        this.patients = this.patient_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.patients.length; i++) {
          var acct = this.patients[i].account_number;
          var email = this.patients[i].email.toLowerCase();
          var first_name = this.patients[i].first_name.toLowerCase();
          var last_name = this.patients[i].last_name.toLowerCase();
          var phone_number = this.patients[i].phone_number;
          var account_number = this.patients[i].account_number;
          // var sex = this.patients[i]?.sex?.toLowerCase();
          if (
            acct.includes(searchLetter) ||
            email.includes(searchLetter) ||
            first_name.includes(searchLetter) ||
            last_name.includes(searchLetter) ||
            phone_number.includes(searchLetter) ||
            account_number.includes(searchLetter)
            // ||sex.includes(searchLetter)
          ) {
            searchResult.push(this.patients[i]);
          }
        }
      }
      if (this.search == "") {
        this.patients = this.patient_reserve;
      } else {
        this.patients = searchResult;
      }
    },
    exportToPDF() {
      html2pdf()
        .from(document.getElementById("table-responsive"))
        .save("converted.pdf");
      // html2pdf(document.getElementById("table-responsive"), {
      //   // margin: 1,
      //   filename: "table-file.pdf",
      // });
    },
    downloadCSV(csv, filename) {
      var csvFile;
      var downloadLink;

      // CSV file
      csvFile = new Blob([csv], { type: "text/csv" });

      // Download link
      downloadLink = document.createElement("a");

      // File name
      downloadLink.download = filename;

      // Create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);

      // Hide download link
      downloadLink.style.display = "none";

      // Add the link to DOM
      document.body.appendChild(downloadLink);

      // Click download link
      downloadLink.click();
    },
    exportToCSV(filename) {
      var csv = [];
      var rows = document.querySelectorAll("table tr");

      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");

        for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);

        csv.push(row.join(","));
      }

      // Download CSV file
      this.downloadCSV(csv.join("\n"), filename);
    },

    getServiceList() {
      this._getServiceList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.service_lists = this.response.data;
            this.service_lists = this.service_lists.filter(
              (item) => item.status == true
            );
            this.service_lists = this.service_lists.reverse();
            // console.log(this.service_lists, "ll");
          }
        })
        .catch((error) => {
          console.log("Error fetching data", error);
        });
    },

    activateinput() {
      // if (this.new_patient == "false") {
      //   var si = document.getElementById("service_select_input");
      //   si.disabled = false;
      // } else if (this.new_patient == "true") {
      //   var si = document.getElementById("service_select_input");
      //   si.disabled = true;
      //   this.service_id = "";
      // }
    },

    openbilling(id) {
      $(".add_billing").modal("show");
      localStorage.setItem("patient_id_billing", id);
      // alert(id)
    },

    Reschedule_appointment() {
      $(".inloader").show();

      this._Reschedule_appointment({
        patientId: this.get_id,
        payload: {
          date: this.date_of_reschedule_appointment,
          time: this.time_of_reschedule_appointment,
        },
      })
        .then((response) => {
          this.date_of_reschedule_appointment = "";
          this.time_of_reschedule_appointment = "";
          $("#reschedule_appointment").modal("hide");
          $(".inloader").hide();

          // this.issue = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          $("#reschedule_appointment").modal("hide");
          this.issue = "";
        });
    },
    Delete_appointment(apt_id) {
      $(".inloader").show();

      this._Delete_appointment(apt_id)
        .then((response) => {
          $(".inloader").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            Swal.fire({
              title: "Appointment cancelled!",
              text: "Appointment has been cancelled.",
              icon: "success",
            });
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          $("#con-close-modal").modal("hide");
          this.issue = "";
        });
    },

    confirmAction(apt_id) {
      Swal.fire({
        title: "Are you sure you want cancel this appointment?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.Delete_appointment(apt_id);
        }
      });
    },
    Checkin_confirmAction(p_id) {
      Swal.fire({
        title: "Are you sure you want check in this patient?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, check in",
      }).then((result) => {
        if (result.isConfirmed) {
          this.patientCheckin(p_id);
        }
      });
    },
    Checkout_confirmAction(p_id) {
      Swal.fire({
        title: "Are you sure you want check out this patient?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, check out",
      }).then((result) => {
        if (result.isConfirmed) {
          this.patientCheckin(p_id);
        }
      });
    },

    patientCheckin(id) {
      $(".inloader").show();

      this._patientCheckin(id)
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            this.getPatientList();
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
        });
    },

    execute(message) {
      this.Checkin_confirmAction(this.temp_id)
    },
    getAppointmentHistory(Q) {
      $(".inloader").show();

      this._getAppointmentHistory(Q.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const apt_history = this.response.data;
            $(".inloader").hide();
            this.history_data = apt_history.reverse();
            $("#history-modal").modal("show");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
          $(".inloader").hide();
        });
    },
  },

  mounted() {
    this.getServiceList();

    $(".cancel-billing_form").click(function () {
      $(".add_billing").modal("hide");
    });

    $('input[type="time"]').change(function () {
      $("timeInput").attr("type", "text");
    });

    var role = localStorage.getItem("role");
    if (role == "Super Admin") {
      $(".for_Super_admin").show();
    }
    this.getPatientList();
    $(".add_patient").click(function () {
      $(".add_patient_modal").modal("show");
    });

    $(".show_search").click(function () {
      $("#search_modal").modal("show");
      $(".add_patient_modal").modal("hide");
    });

    $(".cancel-form").click(function () {
      $("#search_modal").modal("hide");
    });

    $(".cancel-book_form").click(function () {
      $("#con-close-modal").modal("hide");
    });

    const currentDate = new Date().toISOString().split("T")[0];
    // Set the minimum date for the date input
    document.getElementById("indate").min = currentDate;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#modchoose {
  margin-top: 0px;
  margin-left: 100px;
}

.text-area {
  height: 100px;
}

.for_Super_admin {
  display: none;
}

#faz {
  font-size: 20px;
  display: inline-block;
  background-color: #00d3c7;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}



/* .btn {
  float: right;
  align-content: end;
} */



@media (max-width: 800px) {


  #search_modal {
    margin-left: 0px !important;
  }

  #modchoose {
    margin-top: -35% !important;
    margin-left: 15% !important;
  }

  #reschedule_appointment {
    margin-left: 0px !important;
  }

}

@media (max-width: 480px) {

  .row {
    overflow-x: auto !important;
  }

  #reschedule_appointment {
    margin-left: 0px !important;
    margin-top: 100px !important;
  }

  #bookapp {
    margin-top: 5%;
  }

  .text-end {
    text-align: center !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #modchoose {
    margin-left: 0px !important;
    margin-top: 10% !important;
  }

  #chrtsearch {
    /* border:1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  #crtnew {
    width: 60% !important;
  }

  #crtnew {
    width: 60% !important;
  }

  #search_modal {
    margin-left: 0px !important;
  }

  #acctnumb {
    margin-top: 10%;
  }


}
</style>
