<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Hospitals </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Offline Hospital List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Offline Hospital List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            to="/Add-Offline-Hospital"
                            class="btn btn-primary add-pluss ms-2"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></router-link>
                          <!-- <a href="#" class="btn btn-primary ms-2"
                                ><img
                                  src="../../assets/assets/img/icons/re-fresh.svg"
                                  alt=""
                              /></a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 ms-auto">
                    <div class="btn-group selectbtn" hidden>
                      <button
                        type="button"
                        class="btn dropdown-toggle btn-primary btn-xl"
                        data-bs-toggle="dropdown"
                      >
                        {{ list_selector || "Permissions" }}
                      </button>
                      <div class="dropdown-menu dm">
                        <span class="dropdown-item text-bg-secondary"
                          >Select List</span
                        >
                        <span
                          class="dropdown-item"
                          v-for="(role, index) in option_list"
                          v-bind:key="index"
                          @click="selectList(role)"
                          >{{ role }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow-x: scroll">
                <a-table :dataSource="hospital_list" :columns="columns">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'status'">
                      <div
                        v-if="record.is_active == 1"
                        :id="`toggleContainer${record.institution_id}`"
                        class="toggle-container on"
                        @click="change_acount_status(record.institution_id)"
                      >
                        <div class="toggle-circle"></div>
                      </div>

                      <div
                        v-if="record.is_active == 0"
                        :id="`toggleContainer${record.institution_id}`"
                        class="toggle-container off"
                        @click="change_acount_status(record.institution_id)"
                      >
                        <div class="toggle-circle"></div>
                      </div>
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a
                          href="#"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a class="dropdown-item" @click="edit_record(record)"
                            ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                            Edit Device</a
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- second modal for confirmation -->
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";

const columns = [
  {
    title: "Hospital Name",
    key: "hospital_name",
    dataIndex: "hospital_name",
  },
  {
    title: "Device ID",
    key: "device_id",
    dataIndex: "device_id",
  },
  {
    title: "Mac Address",
    dataIndex: "mac_address",
  },
  {
    title: "Last Sync Date",
    dataIndex: "last_sync_date",
  },
  {
    title: "Date created",
    dataIndex: "created_at",
    key: "created_at",
  },

  {
    // title: "Status",
    key: "status",
  },
  {
    // title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
  },

  data() {
    return {
      hospital_list: null,
      search: "",
      hospital_list_reserve: [],
      state: 0,
      dataTransport: {},
      columns,
    };
  },

  methods: {
    ...mapActions({
      _get_offline_hospital: "general/get_offline_hospitalList",
      _change_acount_status: "general/change_acount_status",
    }),
    get_offline_hospital() {
      this._get_offline_hospital()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.hospital_list = this.response.data;
            this.hospital_list = this.hospital_list.reverse();
            this.hospital_list_reserve = this.hospital_list;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    showmodal: function (id) {
      $("#con-close-modal").modal("show");
      this.doctor_id = id;
      // alert(this.doctor_id);
    },

    search_the_list() {
      // this.hospital_list = this.hospital_list_reserve;
      var searchLetter = this.search.toLowerCase();
      var searchResult = [];
      for (var i = 0; i < this.hospital_list.length; i++) {
        var name = this.hospital_list[i].name.toLowerCase();
        var email = this.hospital_list[i].email.toLowerCase();
        // var state = this.hospital_list[i]?.state.toLowerCase();
        // var country = this.hospital_list[i].country.toLowerCase();
        if (
          name.includes(searchLetter) ||
          email.includes(searchLetter)
          // ||
          // state.includes(searchLetter) ||
          // country.includes(searchLetter)
        ) {
          searchResult.push(this.hospital_list[i]);
        }
        // if (searchResult == []) {
        //   this.hospital_list = this.hospital_list;
        // }
      }

      if (this.search == "") {
        this.hospital_list = this.hospital_list_reserve;
      } else {
        this.hospital_list = searchResult;
      }
    },
    toggle(id) {
      this.state = 1 - this.state; // Toggle this.state between 0 and 1
      const container = document.getElementById(`toggleContainer${id}`);

      if (this.state === 1) {
        container.classList.remove("off");
        container.classList.add("on");
      } else {
        container.classList.remove("on");
        container.classList.add("off");
      }
    },

    change_acount_status(id) {
      $(".inloader").show();
      this._change_acount_status({
        id: id,
        payload: { hospital: "true" },
      })
        .then((response) => {
          $(".inloader").hide();

          this.toggle(id);
          this.get_offline_hospital();
          this.response = response.data;
          this.$toast.success(this.response["message"]);
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Fa9iled to Change Status.");
            }
          }
        });
    },
    edit_hospital(hospital) {
      window.location = "/Edit-Hospital";
      localStorage.setItem("hospitalData", JSON.stringify(hospital));
    },
  },

  mounted() {
    this.get_offline_hospital();

    $(".cancel_change_cat").click(function () {
      $("#con-close-modal").modal("hide");
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}

.dropdown-menu {
  height: auto;
  width: 100%;
  border-radius: 8px;
}

.dm {
  height: 250px;
  border-radius: 8px;
  overflow-x: scroll;
}

.toggle-container {
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-circle {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background-color: #14a79e;
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background-color: #16161649;
}

.fa-solid {
  color: #131313;
}
@media (max-width: 1366px) {
  .fa-solid {
    color: #131313;
  }
}
@media (max-width: 1280px) {
  .fa-solid {
    color: #131313;
  }
}

@media (max-width: 800px) {
  .fa-solid {
    color: #131313;
  }
}

@media (max-width: 480px) {
  .fa-solid {
    color: #131313;
  }

  .align-items-center{
    width: 100% !important
  }
}
</style>
