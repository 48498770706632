<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Pharmacy </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Medicine List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center" id="mediclister">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Medicine List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                                @input="click()"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            to="/Add-Medicine"
                            class="bntn btn-primary add-pluss ms-2"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></router-link>
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 ms-auto" id="buttonmediclister">
                    <div class="btn-group selectbtn">
                      <button type="button" class="btn  btn-dark btn-xl" @click="filterlist('all')">
                        All
                      </button>

                    </div>
                  </div>
                  <div class="col-1 ms-auto" id="buttonmediclister">
                    <div class="btn-group selectbtn">
                      <button type="button" class="btn  btn-primary btn-xl" @click="filterlist('expiry_date')">
                        Expiring Soon
                      </button>

                    </div>
                  </div>
                  <div class="col-1 ms-auto" id="buttonmediclister">
                    <div class="btn-group selectbtn" @click="filterlist('low_stock')">
                      <button type="button" class="btn btn-warning btn-xl">
                        Low Stock
                      </button>
                    </div>
                  </div>
                  <div class="col-1 ms-auto" id="buttonmediclister">
                    <div class="btn-group selectbtn">
                      <button type="button" class="btn  btn-danger btn-xl" @click="filterlist('out_of_stock')">
                        Out of Stock
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <div class="table-responsive" style="overflow: scroll">
            <a-table :dataSource="drug_lists" :columns="columns">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'name'">
                  <span
                    class="tb_name"
                    @click="getDrugHistory(record)"
                    style="font-size: 20px; text-transform: capitalize"
                  >
                    {{ record.drug_name }}
                  </span>
                  <div class="tooltip-container">
                    {{ record.drugType }}
                    <div>
                      <span
                        class="tooltip-text"
                        v-if="
                          record.drugType == 'Tablets' &&
                          record.tablet_type == 'pack'
                        "
                      >
                        <span
                          >{{ record.drug_name }} {{ record.drugType }}</span
                        >
                        {{ record?.card_per_pack ?? 0 }} - Cards per
                        {{ record.tablet_type }}
                        <br />
                        {{ record?.tablet_per_card ?? 0 }} - Tablets per card
                      </span>
                      <span
                        class="tooltip-text"
                        v-else-if="
                          record.drugType == 'Tablets' &&
                          record.tablet_type == 'bottle'
                        "
                      >
                        <span>{{ record.drug_name }}</span>
                        {{ record?.tablet_per_card ?? 0 }} - Tablets per
                        {{ record.tablet_type }}
                      </span>

                      <span
                        class="tooltip-text"
                        v-else-if="record.drugType != 'Tablets'"
                      >
                        <span>{{ record.drug_name }}</span>
                        Quantity - {{ record?.quantity ?? 0 }}
                      </span>
                    </div>
                  </div>
                </template>
                <template v-if="column.key == 'price'">
                  <span> ₦ {{ formatNumberWithCommas(record.price) }} </span>
                </template>
                <template v-if="column.key == 'excess_card'">
                  <span>
                    {{ record?.excess_card || 0 }}
                  </span>
                </template>
                <template v-if="column.key == 'excess_tablet'">
                  <span>
                    {{ record?.excess_tablet || 0 }}
                  </span>
                </template>
                <template v-if="column.key == 'total_tab_quantity'">
                  <span>
                    {{ record.total_tab_quantity }}
                  </span>
                </template>
                <template v-if="column.key == 'restuck'">
                  <button
                    type="submit"
                    class="btn me-2 restock-btn"
                    style="border: #14a79e 1px solid; padding: 10px"
                    @click="save_restock_data(record)"
                  >
                    Restock
                  </button>
                </template>
                <template v-if="column.key == 'action'">
                  <div class="dropdown dropdown-action">
                    <a
                      href="#"
                      class="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="fa fa-ellipsis-v"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-end">
                      <a
                        class="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#edit-close-modal"
                        @click="onEditMedModalMounted(record)"
                        ><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a
                      >
                      <!-- <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            > -->
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:50px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Medicine</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <Add_MedModalVue />
          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>

  <div
    id="edit-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:50px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Medicine</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <!-- <Edit_MedModalVue /> -->

          <Edit_MedModalVue :formData="edit_data"></Edit_MedModalVue>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>

  <!-- second modal for confirmation -->
  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster restock_modal1"
    role="dialog"
    id="meandu"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Choose action</h3>
            <p>What do you want to Restock?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row_ali">
              <div class="item">
                <span
                  @click="retuck_show('Pack')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Packs
                </span>
              </div>
              <div
                class="item"
                v-if="
                  this.restock_data?.drugType == 'Tablets' &&
                  this.restock_data?.tablet_type == 'pack'
                "
              >
                <span
                  @click="retuck_show('Card')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Cards
                </span>
              </div>
              <div class="item" v-if="this.restock_data?.drugType == 'Tablets'">
                <span
                  @click="retuck_show('Tablets')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Tablets
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal -->

  <Restuck
    :med_data_recieved="{
      data: restock_data,
      type: restock_type,
      quantity: 0,
      answer: 0,
    }"
  />
  <History :history_data_recieved="history_data" />
  <!-- /.modal -->
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import Add_MedModalVue from "./Add_MedModal";
import Edit_MedModalVue from "./Edit_MedModal.vue";
import Restuck from "./plugin/Restuck.vue";
import History from "./plugin/view_history.vue";
import jquery from "jquery";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Price",
    key: "price",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiry_date",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
  },
  {
    title: "Excess Card",
    dataIndex: "excess_card",
  },
  {
    title: "Excess Tablet",
    dataIndex: "excess_tablet",
  },
  {
    title: "Total Tablets",
    key: "total_tab_quantity",
  },
  {
    key: "restuck",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Edit_MedModalVue,
    Add_MedModalVue,
    Loader,
    Inloader,
    Restuck,
    History,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      drug_lists: [],
      search: "",
      drug_lists_reserve: [],
      edit_data: {},
      history_data: [],
      columns,
      restock_data: {},
      restock_type: "",
    };
  },

  methods: {
    ...mapActions({
      _getDrugList: "pharmacy/getDrugList",
      _restock: "pharmacy/getDrugHistory",
      _getDrugHistory: "pharmacy/getDrugHistory",
    }),
    save_restock_data(data) {
      this.restock_data = data;
      $(".restock_modal1").modal("show");
    },
    getDrugList() {
      this._getDrugList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_lists = this.response.data;
            this.drug_lists = this.drug_lists.reverse();
            this.drug_lists_reserve = this.drug_lists;
            // this.$toast.success(this.response["message"]);
            // console.log(this.drug_categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    onEditMedModalMounted(selectedMedicine) {
      this.edit_data = selectedMedicine;
    },
    search_the_list() {
      if (this.search == "") {
        this.drug_lists = this.drug_lists_reserve;
      } else {
        this.drug_lists = this.drug_lists_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.drug_lists.length; i++) {
          var drug_name = this.drug_lists[i].drug_name.toLowerCase();
          // var category = this.drug_lists[i].category.toLowerCase();
          // var company_name = this.drug_lists[i].company_name.toLowerCase();
          // var price = this.drug_lists[i]?.price;
          var expiry_date = this.drug_lists[i].expiry_date;
          if (
            drug_name.includes(searchLetter) ||
            // price.includes(searchLetter) ||
            expiry_date.includes(searchLetter)
          ) {
            searchResult.push(this.drug_lists[i]);
          }
          // if (searchResult == []) {
          //   this.drug_lists = this.drug_lists;
          // }
        }
      }
      if (this.search == "") {
        this.drug_lists = this.drug_lists_reserve;
      } else {
        this.drug_lists = searchResult;
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number != null) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    retuck_show(restock_type) {
      $(".restock_modal1").modal("hide");
      $("#restuck-modal").modal("show");
      this.restock_type = restock_type;
    },
    getDrugHistory(Q) {
      $(".inloader").show();

      this._getDrugHistory(Q.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const drug_history = this.response.data;
            $(".inloader").hide();
            this.history_data = drug_history.reverse();
            // localStorage.setItem('drug_history', JSON.stringify(drug_history))
            $("#history-modal").modal("show");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
          $(".inloader").hide();
        });
    },

    filterlist(target) {
      if (target == 'low_stock') {
        const data_here = this.drug_lists_reserve
        this.drug_lists = data_here.filter(item => item.quantity <= 5);
      }
      else if (target == 'all') {
        this.drug_lists = this.drug_lists_reserve
      }
      else if (target == 'out_of_stock') {
        const data_here = this.drug_lists_reserve
        this.drug_lists = data_here.filter(item => item.quantity == 0);
      }
      else if (target == 'expiry_date') {
        const data_here = this.drug_lists_reserve
        this.drug_lists = data_here.filter(item => this.getDaysDifference(item.expiry_date) <= 30);
        
      }
    },
    getDaysDifference(expiryDateStr) {
      const currentDate = new Date();
      const expiryDate = new Date(expiryDateStr);
      const timeDifference = expiryDate - currentDate; // Time difference in milliseconds
      const dayDifference = timeDifference / (1000 * 3600 * 24); // Convert ms to days
      return Math.floor(dayDifference); // Round down to the nearest whole number
    }
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });

    this.getDrugList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa-solid {
  color: #131313;
}

#meandu {
  margin-top: 0px;
  margin-left: 100px;
}

/* #con-close-modal{width:60%} */
.tb_name:hover {
  color: #14a79e;
  cursor: pointer;
}

.tooltip-container {
  position: relative;
  /* display: block; */
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  display: none;

  width: 400px;
  height: auto;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  /* Position the tooltip above the text */
  left: 150%;
  margin-left: -100px;
  /* Use negative margin to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  /* Arrow pointing down */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  display: inline-block;
}

.tooltip-text span {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.row_ali {
  align-items: center;
  text-align: center;
  display: flex;
}

.item {
  flex: 1;
  /* Each item will take an equal share of the available space */
  min-width: 0;
  /* Prevent overflow issues */
  margin-left: 5px;
  margin-right: 5px;
}

.selectbtn {
  inline-size: 100%;
  /* margin-left: 20%; */
  background-color: #00d3c7;
  block-size: 35px;
  color: white;
}

.ant-table-container {
    width: 100%;
  }
  
  .ant-table-thead th, .ant-table-tbody td {
    width: auto;
    white-space: nowrap;
  }

  #mediclister {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }

  #buttonmediclister {
    width: 10%;
  }

@media (max-width: 1380px) {
  
}

@media (max-width: 1280px) {
  #meandu {
    margin-top: -30%;
  }

  #mediclister {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  #buttonmediclister {
    width: 100%;
  }

  .ant-table-container {
    width: 100%;
  }
  
  .ant-table-thead th, .ant-table-tbody td {
    width: auto;
    white-space: nowrap;
  }
}

@media (max-width: 800px) {
  #meandu {
    margin-top: -30%;
  }

  #mediclister {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  #buttonmediclister {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #meandu {
    margin-left: 0px;
    margin-top: -20%;
  }

  #mediclister {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  #buttonmediclister {
    width: 100%;
  }
}
</style>
