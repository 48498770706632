<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="hospitals">Hospitals </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Hospital</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Hospitals Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label
                        >Hospital Name
                        <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="text"
                        v-model="hospital_name"
                        required
                        minlength="8"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="phone_number"
                        maxLength="11"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Email <span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        type="email"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-4"> </div> -->

                  <div
                    class="col-12 col-md-6 col-xl-4"
                    v-if="
                      type == 'Doctor' || type == 'Radiology' || type == 'Lab'
                    "
                  >
                    <div class="input-block local-forms">
                      <label
                        >Category <span class="login-danger">*</span></label
                      >

                      <a-select
                        class="w-100"
                        v-model:value="category_id"
                        showSearch
                        :filterOption="
                          (input, option) => filterObject(option, input, 'key')
                        "
                      >
                        <a-select-option
                          value="Choose a Category"
                          disabled
                          selected
                        >
                          Choose a Category
                        </a-select-option>
                        <a-select-option
                          :value="data.id"
                          v-for="data in category"
                          :key="data.category"
                        >
                          {{ data.category }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Country <span class="login-danger">*</span></label>
                      <countries v-model:value="country" />
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-xl-4"
                    v-if="country == 'Nigeria'"
                  >
                    <div class="input-block local-forms">
                      <label>State <span class="login-danger">*</span></label>
                      <a-select
                        class="w-100"
                        v-model:value="state_id"
                        @change="getLGA()"
                        showSearch
                        :filterOption="
                          (input, option) => filterObject(option, input, 'key')
                        "
                      >
                        <a-select-option disabled>Select State</a-select-option>
                        <a-select-option
                          v-for="state in state_list"
                          :key="state.name"
                          :value="state.id"
                        >
                          {{ state.name }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-xl-4"
                    v-if="country == 'Nigeria'"
                  >
                    <div class="input-block local-forms">
                      <label>LGA <span class="login-danger">*</span></label>
                      <a-select
                        class="w-100"
                        v-model:value="lga"
                        showSearch
                        :filterOption="
                          (input, option) => filterObject(option, input, 'key')
                        "
                      >
                        <a-select-option disabled
                          >Select Local Government</a-select-option
                        >
                        <a-select-option
                          v-for="lga in lga_list"
                          v-bind:key="lga.name"
                          :value="lga.id"
                        >
                          {{ lga.name }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>City <span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="city"
                        placeholder="Enter your city"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label
                        >Zip Code <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="postal_code"
                        maxLength="6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label
                        >Consult Fee <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="consult_fee"
                        placeholder="Hospital basic consult fee"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label
                        >Specializations
                        <span class="login-danger">*</span></label
                      >
                      <textarea
                        class="form-control"
                        rows="3"
                        cols="30"
                        v-model="specializations"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <textarea
                        class="form-control"
                        rows="3"
                        cols="30"
                        v-model="address"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label
                        >Health Care Type
                        <span class="login-danger">*</span></label
                      >
                      <a-select
                        class="w-100"
                        v-model:value="role_id"
                        showSearch
                        :filterOption="
                          (input, option) => filterObject(option, input, 'key')
                        "
                      >
                        <a-select-option
                          value="Choose a Role"
                          disabled
                          selected
                        >
                          Choose a Health Care Type
                        </a-select-option>
                        <a-select-option
                          :value="data.id"
                          v-for="data in roles"
                          :key="data.role"
                        >
                          {{ data.role }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-3">
                    <div class="input-block local-forms">
                      <label>Avatar<span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        type="file"
                        placeholder=""
                        accept="image/*"
                        id="avatar"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-sm-3">
                    <div class="input-block local-forms">
                      <label>Document<span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        type="file"
                        placeholder=""
                        accept="*"
                        id="document"
                        multiple
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3" hidden>
                    <div class="input-block local-forms">
                      <label
                        >Password <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="password"
                        v-model="password"
                        placeholder="Set hospitl Temporary password"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end" id="hereupcanc">
                      <span
                        @click="Register_hospital()"
                        class="btn btn-primary submit-form me-2"
                        >Submit</span
                      >
                      <span
                        type="submit"
                        class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)"
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { filterObject } from "@/utils/helpers";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
  },

  data() {
    return {
      filterObject,
      email: "",
      hospital_name: "",
      phone_number: "",
      hospital_address: "",
      consult_fee: "",
      specializations: "",
      city: "",
      country: "Nigeria",
      state_id: null,
      lga: "Select LGA",
      postal_code: "",
      status: "Active",
      state_list: [],
      lga_list: [],
      state: {},
      type: "",
      data: null,
      avatar: null,
      document: null,
      password: "",
      role_id: "",
      roles: [],
    };
  },

  methods: {
    ...mapActions({
      _Register_hospital: "general/Register_hospital",
      _getState: "general/getStateList",
      _getrole: "general/getrole",
    }),
    stringToArray(str) {
      return str.split(",");
    },

    Register_hospital() {
      var avatar_ele = document.getElementById("avatar");
      var doc_ele = document.getElementById("document");

      this.avatar = avatar_ele.files[0];
      const file_document = doc_ele.files;
      this.document = Array.from(file_document);
      console.log(this.document);

      const spec_array = this.stringToArray(this.specializations);
      var inputs = document.querySelectorAll("input");

      for (var i = 0; i < inputs.length; i++) {
        var inputValue = inputs[i].value;
      }
      if (inputValue === "") {
        this.$toast.error("Please fill in all fields.");
      } else if (
        this.phone_number.length < 11 ||
        this.phone_number.length > 11
      ) {
        //alert(this.phone.length)
        this.$toast.error(
          "Phone number cannot be more than or less than 11 digits"
        );
      } else if (isNaN(this.phone_number)) {
        this.$toast.error("This phone number input only accepts digits");
      } else {
        $(".inloader").show();
        // alert(this.dob)
        this._Register_hospital({
          email: this.email,
          hospital: this.hospital_name,
          phone_number: this.phone_number,
          // category_id: this.category_id == "Choose a Category" ? null : this.category_id,
          consult_fee: this.consult_fee,
          specializations: spec_array,
          hospital_address: this.address,
          city: this.city,
          country: this.country,
          state_id: this.state_id,
          lga_id: this.lga,
          zip_code: this.postal_code,
          documents: this.document,
          avatar: this.avatar,
          password: this.password,
          role_id: this.role_id,
        })
          .then((response) => {
            this.response = response.data;
            $(".inloader").hide();
            this.$router.push({ name: "Hospitals" });
            this.$toast.success(this.response["message"]);
          })
          .catch((error) => {
            $(".inloader").hide();

            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422) {
                this.$toast.error(error.response.data.message);
              }
              // } else if (status === 401) {
              //   // Handle specific status code (e.g., Unauthorized)
              //   console.error("Unauthorized:", data.error);
              // }
            }
          });
      }
    },

    getState() {
      this._getState()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.state_list = this.response.data;
            console.log(this.state_list);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getLGA() {
      this.lga_list = this.state_list.find(
        (state) => state.id == this.state_id
      )?.lga;
    },

    getrole() {
      this._getrole()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.roles = this.response.data;

            this.roles = this.roles.filter(
              (item) => item.own_healthcare == true
            );
            console.log(this.roles);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },
  },

  mounted() {
    this.getState();
    this.getrole();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 800px) {
  #hereupcanc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .col-sm-3 {
    width: 50%;
  }
}
</style>
