import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store";
import Toaster, { createToaster } from "@meforma/vue-toaster";

import Admin_Dashboard from "../components/Dashboard/Admin_Dashboard";
import FrontDesk_Dashboard from "../components/Dashboard/FrontDesk_Dashboard";
import FrontDesk_Dashboard_for_clinic from "../components/Dashboard/FrontDesk_Dashboard_for_clinic.vue";
import Clinic_Dashboard from "../components/Dashboard/Clinic_Dashboard";
import Doctor_Dashboard from "../components/Doctor/Doctor_Dashboard";
import Patient_Dashboard from "../components/Patient/Patient_Dashboard";
import Patient_Dashboard_For_Doctor from "../components/Patient/Patient_Dashboard_for_doctor";
import Finance_Dashboard from "../components/Accounts/Finance_Dashboard";
import Laboratory_Dashboard from "../components/Laboratory/Lab_Dashboard";
import Pharmacy_Dashboard from "../components/Pharmacy/Pharmacy_Dashboard";
import Radiology_Dashboard from "../components/Radiology/Radiology_Dashboard.vue";
import Nurse_Dashboard from "../components/Dashboard/Nurse_Dashboard.vue";

import Login from "../components/_General/Login";
import Chief_Loader from "../components/_General/Chief_Loader.vue";
import Lock_Screen from "../components/_General/Lock_Screen";
import Password from "../components/_General/Password";
import Forgot_Password from "../components/_General/Forgot_Password";
import Reset_Password from "../components/_General/Reset_Password";

import Doctor_Profile from "../components/Doctor/Doctor_Profile";
import Note from "../components/Doctor/Note";
import Category_GOPD from "../components/Doctor/Category_GOPD";

import Patients from "../components/Patient/Patient_List";
import Add_Patient from "../components/Patient/Add_Patient";
import Edit_Patient from "../components/Patient/Edit_Patient";
import Patient_Profile from "../components/Patient/Patient_Profile";
import Patient_Timeline from "../components/Patient/Patient_Timeline";
import Patient_Bio_Data from "../components/Patient/PatientBioData";
import Hospital_Transfer from "../components/Patient/Transfer_Patient";
import Requests_History from "../components/Patient/Requests_History";

import Add_Staff from "../components/Staff/Add_Staff";
import Edit_Staff from "../components/Staff/Edit_Staff";
import Staffs from "../components/Staff/Staff_List";
import Staff_Profile from "../components/Staff/Staff_Profile";
import Leave from "../components/Staff/Staff_Leaves";
import Attendance from "../components/Staff/Staff_Attendance";
import Holiday from "../components/Staff/Staff_Holidays";
import Add_Category from "../components/Staff/Add_Category";

import Add_Hospital from "../components/Hospital/Add_Hospital";
import Edit_Hospital from "../components/Hospital/Edit_Hospital";
import Hospitals from "../components/Hospital/Hospital_List";
import Hospital_Profile from "../components/Hospital/Hospital_Profile";

import Offline_Hospital_List from "../components/Offline/Offline_Hospital_List.vue";
import Add_Offline_Hospital from "../components/Offline/Add_Offline_Hospital.vue";
import Edit_Offline_Hospital from "../components/Offline/Edit_Offline_Hospital.vue";

import Appointments from "../components/Appointment/Appointments";
import Book_Appointment from "../components/Appointment/Book_Appointment";
import Edit_Appointment from "../components/Appointment/Edit_Appointment";

import Doctor_Pending_Appointments from "../components/Appointment/Doctor_Pending_Appointments";
import Doctor_Accepted_Appointments from "../components/Appointment/Doctor_Accepted_Appointments";
import Doctor_Rejected_Appointments from "../components/Appointment/Doctor_Rejected_Appointments";
import Upaid_Appointments from "../components/Appointment/Upaid_Appointments.vue";

import Schedules from "../components/Doctor/Schedule/Schedules";
import Add_Schedule from "../components/Doctor/Schedule/Add_Schedule";
import Edit_Schedule from "../components/Doctor/Schedule/Edit_Schedule";

import Departments from "../components/Departments/Departments";
import Add_Department from "../components/Departments/Add_Department";
import Edit_Department from "../components/Departments/Edit_Department";

import Expenses from "../components/Accounts/Expenses";
import Invoices from "../components/Accounts/Invoices";
import Payments from "../components/Accounts/Payments";
import Provident_Funds from "../components/Accounts/Provident_Funds";
import Taxes from "../components/Accounts/Taxes";
import Add_Payment from "../components/Accounts/Add_Payment";
import Service_Fees from "../components/Accounts/Service_Fee";
import Pending_Payments from "../components/Accounts/Pending_Payments";

import Employee_Salary from "../components/Payroll/Employee_Salary";
import Pay_Slip from "../components/Payroll/Pay_Slip";

import Voice_call from "../components/Call/Voice_Call";
import Video_call from "../components/Call/Video_Call";
import Incoming_call from "../components/Call/Incoming_Call";

import Compose_Email from "../components/Email/Compose_Email";
import Inbox from "../components/Email/Inbox";
import Mail_view from "../components/Email/Mail_View";

import Chat from "../components/_General/Chat";
import Activities from "../components/_General/Activities";
import Assets from "../components/_General/Assets";

import Theme_Settings from "../components/Settings/Theme_Settings";
import Change_Password from "../components/Settings/Change_Password";
import Email_Settings from "../components/Settings/Email_Settings";
import Loacalization_Settings from "../components/Settings/Loacalization";
import Other_Settings from "../components/Settings/Others";
import Payment_Settings from "../components/Settings/Payment_Settings";
import SEO_Settings from "../components/Settings/SEO_Settings";
import Social_Links from "../components/Settings/Social_Links";
import Social_Login_Settings from "../components/Settings/Social_Login_Settings";
import Settings1 from "../components/Settings/General_Settings";
import Onboard_Settings from "../components/Settings/Onboard_Settings_page.vue";
import Assign_Permission from "../components/Settings/Assign_Permission";
import Edit_Permission from "../components/Settings/Edit_Permission";
import View_Roles from "../components/Settings/View_Roles";
import View_Category from "../components/Settings/View_Category";

import Profile from "../components/Profile/Profile";
import Edit_Profile from "../components/Profile/Edit_Profile";

import Expense_Report from "../components/Reports/Expense_Report";
import Invoice_Report from "../components/Reports/Invoice_Report";

import Add_Invoice from "../components/Invoice_List/Add_Invoice";
import Edit_Invoice from "../components/Invoice_List/Edit_Invoice";
import Invoice_List from "../components/Invoice_List/Invoice_List";
import Invoice_Settings from "../components/Invoice_List/Invoice_Settings";
import Invoice_Grid from "../components/Invoice_List/Invoice_grid";
import View_Invoice from "../components/Invoice_List/View_Invoice";

import Allotted_rooms from "../components/Room_Allotment/Alloted_rooms";
import new_Allotment from "../components/Room_Allotment/New_Allotment";
import Room_Category from "../components/Room_Allotment/Room_Category";
import Room_Number from "../components/Room_Allotment/Room_Number";

// Pharmacy
import Medicine_List from "../components/Pharmacy/Medicine_List";
import Add_Medicine from "../components/Pharmacy/Add_Medicine";
import Pharmacy_Category from "../components/Pharmacy/Pharmacy_Category";
import Drug_type from "../components/Pharmacy/Drug_type";
import Dispensory from "../components/Pharmacy/Dispensory.vue";
import Dispensed_Drugs from "../components/Pharmacy/Dispensed_Drugs.vue";

import Stock_List from "../components/Inventory/Item_Stock_List";
import Issued_Items from "../components/Inventory/Issued_Items";

import Birth_records from "../components/Records/Birth_Records";
import Death_records from "../components/Records/Death_Records";

import Ambulance_Call_List from "../components/Ambulance/Abulance_Call_List";
import Ambulance_List from "../components/Ambulance/Ambulance_List";

import GOPD_Dashboard from "../components/GOPD/GOPD_Dashboard";
import GOPD_Approved_List from "../components/GOPD/GOPD_Approved_List";
import GOPD_Pending_List from "../components/GOPD/GOPD_Pending_List";

// import Lab_Request from "../components/Laboratory/Laboratory_requests";
import Lab_Queue from "../components/Laboratory/Lab_Queue";
import Scientist_list from "../components/Laboratory/Scientist_list";
import Lab_Department_list from "../components/Laboratory/Lab_Department_List";
import Reports from "../components/Laboratory/Reports";
import Add_Lab_Department from "../components/Laboratory/Add_Department.vue";
import Add_Lab_Type from "../components/Laboratory/Add_Lab_Type";
import Add_Lab_Note from "../components/Laboratory/Note";
import Lab_History from "../components/Laboratory/Lab_History";

import Radiology_Queue from "../components/Radiology/Radiology_Queue";
import Radiologist_list from "../components/Radiology/Radiologist_list";
import Radiology_Department_list from "../components/Radiology/Radiology_Department_List";
import Radiology_Reports from "../components/Radiology/Reports";
import Add_Radiology_Department from "../components/Radiology/Add_Department";
import Add_Radiology_Type from "../components/Radiology/Add_Radiology_Type";
import Add_Radiology_Note from "../components/Radiology/Note";
import Radiology_History from "../components/Radiology/Radiology_History";

import Subscription_list from "../components/Subscription/Subscription_List.vue";
import Subscriber_list from "../components/Subscription/Subscribers_list.vue";
import Add_Subscription from "../components/Subscription/Add_Subscription.vue";
import Edit_Subscription from "../components/Subscription/Edit_Subscription.vue";
import Custom_Subscription from "../components/Subscription/Custom_Subscription.vue";
import InOut_Patient from "@/components/Patient/In-Out_Patient.vue";

import Add_Booking from "@/components/Service_Booking/Add_Booking.vue";
import Booking_List from "@/components/Service_Booking/Booking_List.vue";

import Biodata_Table_Page from "@/components/Patient/Biodata_Table_Page.vue";
import Specialization_Settings from "@/components/Service_Specializations/Specialization_Settings.vue";
import Specialization_Requests from "@/components/Service_Specializations/Specialization_Requests.vue";
import Specialization_History from "@/components/Service_Specializations/Specialization_History.vue";
import Template_List from "@/components/Template/Template_List.vue";
import Add_Request_Note from "@/components/Service_Specializations/Add_Request_Note.vue";
import Authenticate from "@/components/plugins/Authenticate.vue";

// import Administer_Drugs from "@/components/Drug_Adminitsration/Administer_Drugs.vue";
// import Prescription_List from "@/components/Drug_Adminitsration/Prescription_List.vue";

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Welcome",
    component: Chief_Loader,
  },

  {
    path: "/authenticate/:data",
    name: "Authenticate",
    beforeEnter: (to, from, next) => {
      const profileData = to.params.data;
      console.log(JSON.parse(profileData));
      store.dispatch("loginAdmin", JSON.parse(profileData));
    },
  },
  {
    path: "/Lock-Screen",
    name: "Lock_Screen",
    component: Lock_Screen,
  },

  {
    path: "/Password-Manager",
    name: "Password",
    component: Password,
  },

  {
    path: "/Forgot-Password",
    name: "Forgot_Password",
    component: Forgot_Password,
  },

  {
    path: "/Reset-Password",
    name: "Reset_Password",
    component: Reset_Password,
  },

  {
    path: "/Hospital-Dashboard",
    name: "Admin_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Admin_Dashboard,
  },
  {
    path: "/FrontDesk-Dashboard",
    name: "FrontDesk_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: FrontDesk_Dashboard,
  },
  {
    path: "/Clinic-FrontDesk-Dashboard",
    name: "FrontDesk_Dashboard_for_clinic",
    meta: { requiresAuth: true, permission: "" },
    component: FrontDesk_Dashboard_for_clinic,
  },
  {
    path: "/Clinic-Dashboard",
    name: "Clinic_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Clinic_Dashboard,
  },

  {
    path: "/Doctor-Dashboard",
    name: "Doctor_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Doctor_Dashboard,
  },
  {
    path: "/Patient-Dashboard",
    name: "Patient_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Patient_Dashboard,
  },
  {
    path: "/My-Patient-Dashboard",
    name: "My-Patient-Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Patient_Dashboard_For_Doctor,
  },
  {
    path: "/Finance-Dashboard",
    name: "Finance_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Finance_Dashboard,
  },
  {
    path: "/Laboratory-Dashboard",
    name: "Laboratory_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Laboratory_Dashboard,
  },
  {
    path: "/Nurse-Dashboard",
    name: "Nurse_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Nurse_Dashboard,
  },
  {
    path: "/Pharmacy-Dashboard",
    name: "Pharmacy_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Pharmacy_Dashboard,
  },
  {
    path: "/Radiology-Dashboard",
    name: "Radiology_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: Radiology_Dashboard,
  },

  {
    path: "/Doctor-Profile",
    name: "Doctor_Profile",
    component: Doctor_Profile,
    meta: { requiresAuth: true, permission: "view_doctor" },
  },
  {
    path: "/Patient-Note",
    name: "Note",
    meta: { requiresAuth: true, permission: "" },
    component: Note,
  },
  {
    path: "/Category-GOPD",
    name: "Category_GOPD",
    meta: { requiresAuth: true, permission: "view_category_appointment" },
    component: Category_GOPD,
  },

  {
    path: "/Patients",
    name: "Patients",
    meta: { requiresAuth: true, permission: "" },
    component: Patients,
  },
  {
    path: "/Add-Patient",
    name: "Add_Patient",
    meta: { requiresAuth: true, permission: "add_patient" },
    component: Add_Patient,
  },
  {
    path: "/Transfer-Patient",
    name: "Tansfer_Patient",
    meta: { requiresAuth: true, permission: "" },
    component: Hospital_Transfer,
  },
  {
    path: "/Requests-History",
    name: "Requests_History",
    meta: { requiresAuth: true, permission: "" },
    component: Requests_History,
  },
  {
    path: "/Edit-Patient",
    name: "Edit_Patient",
    meta: { requiresAuth: true, permission: "update_patient" },
    component: Edit_Patient,
  },
  {
    path: "/Patient-Profile",
    name: "Patient_Profile",
    meta: { requiresAuth: true, permission: "" },
    component: Patient_Profile,
  },
  {
    path: "/Patient-Timeline",
    name: "Patient_Timeline",
    meta: { requiresAuth: true, permission: "" },
    component: Patient_Timeline,
  },
  {
    path: "/Patient-Biodata",
    name: "Patient_Bio_Data",
    meta: { requiresAuth: true, permission: "" },
    component: Patient_Bio_Data,
  },

  {
    path: "/Staffs",
    name: "Staffs",
    meta: { requiresAuth: true, permission: "view_staff" },
    component: Staffs,
  },
  {
    path: "/Add-Staff",
    name: "Add_Staff",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Staff,
  },
  {
    path: "/Edit-Staff",
    name: "Edit_Staff",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Staff,
  },
  {
    path: "/Staff-Profile",
    name: "Staff_Profile",
    meta: { requiresAuth: true, permission: "" },
    component: Staff_Profile,
  },
  {
    path: "/Staff-Leave",
    name: "Leave",
    meta: { requiresAuth: true, permission: "" },
    component: Leave,
  },
  {
    path: "/Staff-Attendance",
    name: "Attendance",
    meta: { requiresAuth: true, permission: "" },
    component: Attendance,
  },
  {
    path: "/Staff-Holiday",
    name: "Holiday",
    meta: { requiresAuth: true, permission: "" },
    component: Holiday,
  },
  {
    path: "/Staff-Attendance",
    name: "Attendance",
    meta: { requiresAuth: true, permission: "" },
    component: Attendance,
  },

  {
    path: "/Add-Category",
    name: "Add_Category",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Category,
  },

  {
    path: "/Hospitals",
    name: "Hospitals",
    meta: { requiresAuth: true, permission: "view_Hospital" },
    component: Hospitals,
  },
  {
    path: "/Add-Hospital",
    name: "Add_Hospital",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Hospital,
  },
  {
    path: "/Edit-Hospital",
    name: "Edit_Hospital",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Hospital,
  },
  {
    path: "/Hospital-Profile",
    name: "Hospital_Profile",
    meta: { requiresAuth: true, permission: "" },
    component: Hospital_Profile,
  },

  {
    path: "/Offline-Hospitals",
    name: "Offline_Hospitals",
    meta: { requiresAuth: true, permission: "view_Offline_Hospital" },
    component: Offline_Hospital_List,
  },
  {
    path: "/Add-Offline-Hospital",
    name: "Add_Offline_Hospital",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Offline_Hospital,
  },
  {
    path: "/Edit-Offline-Hospital",
    name: "Edit_Offline_Hospital",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Offline_Hospital,
  },

  // {
  //   path: "/Appointments",
  //   name: "Appointments",
  //   meta: { requiresAuth: true, permission: "view_appointment" },
  //   component: Appointments
  // },
  {
    path: "/Book-Appointment",
    name: "Book_Appointment",
    meta: { requiresAuth: true, permission: "book_appointment" },
    component: Book_Appointment,
  },
  {
    path: "/Edit-Appointment",
    name: "Edit_Appointment",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Appointment,
  },

  {
    path: "/Doctor-Pending-Appointments",
    name: "Doctor_Pending_Appointments",
    meta: { requiresAuth: true, permission: "view_pending_appointment" },
    component: Doctor_Pending_Appointments,
  },
  {
    path: "/Doctor-Accepted-Appointments",
    name: "Doctor_Accepted_Appointments",
    meta: { requiresAuth: true, permission: "view_accepted_appointment" },
    component: Doctor_Accepted_Appointments,
  },
  {
    path: "/Doctor-Rejected-Appointments",
    name: "Doctor_Rejected_Appointments",
    // meta: { requiresAuth: true, permission: "view_rejected_appointment" },
    component: Doctor_Rejected_Appointments,
  },
  {
    path: "/Unpaid-Appointments",
    name: "Unpaid-Appointments",
    meta: { requiresAuth: true },
    component: Upaid_Appointments,
  },
  {
    path: "/In-Out-Patients",
    name: "In-Out-Patients",
    meta: { requiresAuth: true },
    component: InOut_Patient,
  },

  {
    path: "/Schedules",
    name: "Schedules",
    meta: { requiresAuth: true, permission: "working_hours" },
    component: Schedules,
  },
  {
    path: "/Add-Schedule",
    name: "Add_Schedule",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Schedule,
  },
  {
    path: "/Edit-Schedule",
    name: "Edit_Schedule",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Schedule,
  },

  {
    path: "/Departments",
    name: "Departments",
    meta: { requiresAuth: true, permission: "" },
    component: Departments,
  },
  {
    path: "/Add-Department",
    name: "Add_Department",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Department,
  },
  {
    path: "/Edit-Department",
    name: "Edit_Department",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Department,
  },

  {
    path: "/Expenses",
    name: "Expenses",
    meta: { requiresAuth: true, permission: "" },
    component: Expenses,
  },
  {
    path: "/Invoices",
    name: "Invoice",
    meta: { requiresAuth: true, permission: "" },
    component: Invoices,
  },
  {
    path: "/Payments",
    name: "Payment",
    meta: { requiresAuth: true, permission: "" },
    component: Payments,
  },
  {
    path: "/Provident-Funds",
    name: "Provident_funds",
    meta: { requiresAuth: true, permission: "" },
    component: Provident_Funds,
  },
  {
    path: "/Taxes",
    name: "Taxes",
    meta: { requiresAuth: true, permission: "" },
    component: Taxes,
  },
  {
    path: "/Add-Payment",
    name: "Add_Payment",
    meta: { requiresAuth: true, permission: "add_funds" },
    component: Add_Payment,
  },
  {
    path: "/Service-Charges",
    name: "Service_Fees",
    meta: { requiresAuth: true, permission: "" },
    component: Service_Fees,
  },
  {
    path: "/Pending-Payments",
    name: "Pending_Payments",
    meta: { requiresAuth: true, permission: "" },
    component: Pending_Payments,
  },
  {
    path: "/Employee-Salary",
    name: "Employee_Salary",
    meta: { requiresAuth: true, permission: "" },
    component: Employee_Salary,
  },
  {
    path: "/Pay-Slip",
    name: "Pay_Slip",
    meta: { requiresAuth: true, permission: "" },
    component: Pay_Slip,
  },

  {
    path: "/Voice-call",
    name: "Voice_call",
    meta: { requiresAuth: true, permission: "" },
    component: Voice_call,
  },
  {
    path: "/Video-call",
    name: "Video_call",
    meta: { requiresAuth: true, permission: "" },
    component: Video_call,
  },
  {
    path: "/Incoming-call",
    name: "Incoming_call",
    meta: { requiresAuth: true, permission: "" },
    component: Incoming_call,
  },

  {
    path: "/Compose-Email",
    name: "Compose_Email",
    meta: { requiresAuth: true, permission: "" },
    component: Compose_Email,
  },
  {
    path: "/Inbox",
    name: "Inbox",
    meta: { requiresAuth: true, permission: "" },
    component: Inbox,
  },
  {
    path: "/Mail-view",
    name: "Mail_view",
    meta: { requiresAuth: true, permission: "" },
    component: Mail_view,
  },

  {
    path: "/Chat",
    name: "Chat",
    meta: { requiresAuth: true, permission: "" },
    component: Chat,
  },
  {
    path: "/Activities",
    name: "Activities",
    meta: { requiresAuth: true, permission: "" },
    component: Activities,
  },
  {
    path: "/Assets",
    name: "Assets",
    meta: { requiresAuth: true, permission: "" },
    component: Assets,
  },

  {
    path: "/Settings",
    name: "Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Onboard_Settings,
  },
  {
    path: "/Change-Password",
    name: "Change_Password",
    meta: { requiresAuth: true, permission: "" },
    component: Change_Password,
  },
  {
    path: "/Email-Settings",
    name: "Email_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Email_Settings,
  },
  {
    path: "/Loacalization-Settings",
    name: "Loacalization_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Loacalization_Settings,
  },
  {
    path: "/Theme-Settings",
    name: "Theme_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Theme_Settings,
  },
  {
    path: "/Other-Settings",
    name: "Other_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Other_Settings,
  },
  {
    path: "/Payment-Settings",
    name: "Payment_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Payment_Settings,
  },
  {
    path: "/SEO-Settings",
    name: "SEO_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: SEO_Settings,
  },
  {
    path: "/Social-Links",
    name: "Social_Links",
    meta: { requiresAuth: true, permission: "" },
    component: Social_Links,
  },
  {
    path: "/Social-Login-Settings",
    name: "Social_Login_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Social_Login_Settings,
  },
  {
    path: "/View-Category",
    name: "View_Category",
    meta: { requiresAuth: true, permission: "" },
    component: View_Category,
  },
  {
    path: "/View-Roles",
    name: "View_Roles",
    meta: { requiresAuth: true, permission: "" },
    component: View_Roles,
  },

  {
    path: "/Assign-Permission",
    name: "Assign_Permission",
    meta: { requiresAuth: true, permission: "" },
    component: Assign_Permission,
  },
  {
    path: "/Edit-Permission",
    name: "Edit_Permission",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Permission,
  },

  {
    path: "/Profile",
    name: "Profile",
    meta: { requiresAuth: true, permission: "" },
    component: Profile,
  },
  {
    path: "/Edit-Profile",
    name: "Edit_Profile",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Profile,
  },

  {
    path: "/Expense-Report",
    name: "Expense_Report",
    meta: { requiresAuth: true, permission: "" },
    component: Expense_Report,
  },
  {
    path: "/Invoice-Report",
    name: "Invoice_Report",
    meta: { requiresAuth: true, permission: "" },
    component: Invoice_Report,
  },

  {
    path: "/Add-Invoice",
    name: "Add_Invoice",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Invoice,
  },
  {
    path: "/Edit-Invoice",
    name: "Edit_Invoice",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Invoice,
  },
  {
    path: "/Invoice-List",
    name: "Invoice_List",
    meta: { requiresAuth: true, permission: "" },
    component: Invoice_List,
  },
  {
    path: "/Invoice-Settings",
    name: "Invoice_Settings",
    meta: { requiresAuth: true, permission: "" },
    component: Invoice_Settings,
  },
  {
    path: "/Invoice-Grid",
    name: "Invoice_Grid",
    meta: { requiresAuth: true, permission: "" },
    component: Invoice_Grid,
  },
  {
    path: "/View-Invoice",
    name: "View_Invoice",
    meta: { requiresAuth: true, permission: "" },
    component: View_Invoice,
  },

  {
    path: "/Allotted-rooms",
    name: "Allotted_rooms",
    meta: { requiresAuth: true, permission: "" },
    component: Allotted_rooms,
  },
  {
    path: "/new-Allotment",
    name: "new_Allotment",
    meta: { requiresAuth: true, permission: "allocate_room" },
    component: new_Allotment,
  },
  {
    path: "/Room_Category",
    name: "Room_Category",
    meta: { requiresAuth: true, permission: "add_room_type" },
    component: Room_Category,
  },
  {
    path: "/Room_Number",
    name: "Room_Number",
    meta: { requiresAuth: true, permission: "" },
    component: Room_Number,
  },

  // Pharmacy
  {
    path: "/Medicine-List",
    name: "Medicine_List",
    meta: { requiresAuth: true, permission: "" },
    component: Medicine_List,
  },
  {
    path: "/Add-Medicine",
    name: "Add_Medicine",
    meta: { requiresAuth: true, permission: "add_drug" },
    component: Add_Medicine,
  },
  {
    path: "/Pharmacy_Category",
    name: "Pharmacy_Category",
    meta: { requiresAuth: true, permission: "add_drug_category" },
    component: Pharmacy_Category,
  },
  {
    path: "/Drug_type",
    name: "Drug_type",
    meta: { requiresAuth: true, permission: "add_drug_type" },
    component: Drug_type,
  },
  {
    path: "/Dispensory",
    name: "Dispensory",
    meta: { requiresAuth: true },
    component: Dispensory,
  },
  {
    path: "/Dispensed-Drugs",
    name: "Dispensed-Drugs",
    meta: { requiresAuth: true },
    component: Dispensed_Drugs,
  },
  {
    path: "/Stock-List",
    name: "Stock_List",
    meta: { requiresAuth: true, permission: "" },
    component: Stock_List,
  },
  {
    path: "/Issued-Items",
    name: "Issued_Items",
    meta: { requiresAuth: true, permission: "" },
    component: Issued_Items,
  },

  {
    path: "/Birth-Records",
    name: "Birth_records",
    meta: { requiresAuth: true, permission: "register_birth" },
    component: Birth_records,
  },
  {
    path: "/Death-Records",
    name: "Death_records",
    meta: { requiresAuth: true, permission: "register_death" },
    component: Death_records,
  },

  {
    path: "/Ambulance-Call-List",
    name: "Ambulance_Call_List",
    meta: { requiresAuth: true, permission: "" },
    component: Ambulance_Call_List,
  },
  {
    path: "/Ambulance-List",
    name: "Ambulance_List",
    meta: { requiresAuth: true, permission: "" },
    component: Ambulance_List,
  },

  {
    path: "/GOPD-Dashboard",
    name: "GOPD_Dashboard",
    meta: { requiresAuth: true, permission: "" },
    component: GOPD_Dashboard,
  },

  {
    path: "/GOPD-Pending-List",
    name: "GOPD_Pending_List",
    meta: { requiresAuth: true, permission: "view_gopd_appointment" },
    component: GOPD_Pending_List,
  },

  {
    path: "/GOPD-Approved-List",
    name: "GOPD_Approved_List",
    meta: { requiresAuth: true, permission: "view_gopd_appointment" },
    component: GOPD_Approved_List,
  },
  {
    path: "/Specimen-Hub",
    name: "Lab_Queue",
    meta: { requiresAuth: true, permission: "lab_list" },
    component: Lab_Queue,
  },
  {
    path: "/Scientist-List",
    name: "Scientist_list",
    meta: { requiresAuth: true, permission: "lab_list" },
    component: Scientist_list,
  },
  {
    path: "/Lab-Department-list",
    name: "Lab_Department_list",
    meta: { requiresAuth: true, permission: "lab_list" },
    component: Lab_Department_list,
  },
  {
    path: "/Laboratory-Reports",
    name: "Reports",
    meta: { requiresAuth: true, permission: "lab_list" },
    component: Reports,
  },
  {
    path: "/Add-Laboratory-Department",
    name: "Add_Lab_Department",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Lab_Department,
  },
  {
    path: "/Add-Laboratory-Type",
    name: "Add_Lab_Type",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Lab_Type,
  },
  {
    path: "/Lab-History",
    name: "Lab_History",
    meta: { requiresAuth: true, permission: "" },
    component: Lab_History,
  },
  // SPECIALIZATION

  {
    path: "/Specialization-Settings",
    name: "Add_Specs_Type",
    meta: { requiresAuth: true, permission: "specialization_list" },
    component: Specialization_Settings,
  },
  {
    path: "/Specialization-Requests",
    name: "Specialization_Requests",
    meta: { requiresAuth: true, permission: "specialization_list" },
    component: Specialization_Requests,
  },
  {
    path: "/Specialization-Requests/:id",
    name: "Specialization_Requests_Add_Report",
    meta: { requiresAuth: true, permission: "specialization_list" },
    component: Add_Request_Note,
  },
  {
    path: "/Specialization-History",
    name: "Specialization_History",
    meta: { requiresAuth: true, permission: "specialization_list" },
    component: Specialization_History,
  },
  //
  {
    path: "/Specimen-Hub-Radiology",
    name: "Radiology_Queue",
    meta: { requiresAuth: true, permission: "radiology_list" },
    component: Radiology_Queue,
  },
  {
    path: "/Radiologist-List",
    name: "Radiologist_list",
    meta: { requiresAuth: true, permission: "radiology_list" },
    component: Radiologist_list,
  },
  {
    path: "/Radiology-Department-list",
    name: "Radiology_Department_list",
    meta: { requiresAuth: true, permission: "radiology_list" },
    component: Radiology_Department_list,
  },
  {
    path: "/Radiology-Reports",
    name: "Radiology_Reports",
    meta: { requiresAuth: true, permission: "radiology_list" },
    component: Radiology_Reports,
  },
  {
    path: "/Add-Radiology-Department",
    name: "Add_Radiology_Department",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Radiology_Department,
  },
  {
    path: "/Add-Radiology-Type",
    name: "Add_Radiology_Type",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Radiology_Type,
  },
  {
    path: "/Add-Radiology-Note",
    name: "Add_Radiology_Note",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Radiology_Note,
  },
  {
    path: "/Radiology-History",
    name: "Radiology_History",
    meta: { requiresAuth: true, permission: "" },
    component: Radiology_History,
  },

  {
    path: "/Add-Lab-Note",
    name: "Add_Lab_Note",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Lab_Note,
  },

  // subsciption routes

  {
    path: "/Plans",
    name: "Subscription_list",
    meta: { requiresAuth: true, permission: "" },
    component: Subscription_list,
  },
  {
    path: "/Subscriber-List",
    name: "Subscriber_list",
    meta: { requiresAuth: true, permission: "" },
    component: Subscriber_list,
  },
  {
    path: "/Add-Subscription",
    name: "Add_Subscription",
    meta: { requiresAuth: true, permission: "" },
    component: Add_Subscription,
  },
  {
    path: "/Edit-Subscription",
    name: "Edit_Subscription",
    meta: { requiresAuth: true, permission: "" },
    component: Edit_Subscription,
  },
  {
    path: "/Custom-Subscription",
    name: "Custom_Subscription",
    meta: { requiresAuth: true, permission: "" },
    component: Custom_Subscription,
  },
  {
    path: "/Add-Booking",
    name: "Add-Booking",
    meta: { requiresAuth: true, permission: "quick_services" },
    component: Add_Booking,
  },
  {
    path: "/Booking-List",
    name: "Booking-List",
    meta: { requiresAuth: true, permission: "quick_services" },
    component: Booking_List,
  },
  {
    path: "/Patient-Biodata-Table",
    name: "Patient-Biodata-Table",
    meta: { requiresAuth: true, permission: "" },
    component: Biodata_Table_Page,
  },
  {
    path: "/Template-List",
    name: "Template-List",
    meta: { requiresAuth: true, permission: "" },
    component: Template_List,
  },

  // {
  //     path: "/Administer-Drugs",
  //     name: "Administer_Drugs",
  //     meta: { requiresAuth: true, permission: "" },
  //     component: Administer_Drugs,
  //   },
  //   {
  //     path: "/Prescription-List",
  //     name: "Prescription_List",
  //     meta: { requiresAuth: true, permission: "" },
  //     component: Prescription_List,
  //   },
];

//
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isAuth) {
      let permission = to.meta.permission;
      // Check if the Admin Does not have this permission
      if (permission && !store.getters.adminHasPermission(permission)) {
        createToaster({}).error("Access Denied");
        next(from.fullPath);
      }
      next();
    } else {
      next("/");
    }
    return;
  }
  next();
  return;
});

export default router;
