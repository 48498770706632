<template>
<Loader id="loadercomp" />
<Inloader class="inloader" id="inloader" />
  
      
        <div class="page-wrapper">
          <div class="content">
			
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="schedule.html">Doctor Shedule </a></li>
              <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
              <li class="breadcrumb-item active">Edit Schedule</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
        
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Edit Schedule</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">  
                    <div class="input-block local-forms">
                      <label >Doctor Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" value="Dr.William Stephin" >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Department  <span class="login-danger">*</span></label>
                      <a-select class="w-100">
                        <a-select-option>Choose Department</a-select-option>
                        <a-select-option value="Cardiology">Cardiology</a-select-option>
                        <a-select-option value="Urology">Urology</a-select-option>
                        <a-select-option value="Radiology">Radiology</a-select-option>
                        </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms cal-icon">
                      <label >Available Days  <span class="login-danger">*</span></label>
                      <input class="form-control datetimepicker" type="text" value="28-11-22"  >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>From <span class="login-danger">*</span></label>
                      <div class="time-icon">
                        <input type="text" class="form-control" id="datetimepicker3" value="09:00">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>To <span class="login-danger">*</span></label>
                      <div class="time-icon">
                        <input type="text" class="form-control" id="datetimepicker4" value="06:00">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Notes  <span class="login-danger">*</span></label>
                      <textarea class="form-control" rows="3" cols="30"></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block select-gender">
                      <label class="gen-label">Status <span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="gender" class="form-check-input" checked>Active
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="gender" class="form-check-input">In Active
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Create Schedule</button>
                      <button type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>							
        </div>					
      </div>
          </div>        

          
  </div>
    
</template>            
<script>





import Header from "../../plugins/Header";
import Sidebar from "../../plugins/Side_bar";
import Loader from "../../plugins/Loader.vue";
import Inloader from "../../plugins/inloader";


export default {
  name: "Index_Page",
  components: {
    
Loader,
Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
     
    };
  },

  methods: {
    
  },

  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>