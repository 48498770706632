<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="/Settings">Settings </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Edit Permission</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="settings-menu-links" hidden>
        <ul class="nav nav-tabs menu-tabs">
          <li class="nav-item active">
            <router-link to="/Settings" class="nav-link"
              >General Settings</router-link
            >
          </li>
          <li class="nav-item active">
            <router-link to="/View-Roles" class="nav-link"
              >View Roles</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/View-Category" class="nav-link"
              >View Categories</router-link
            >
          </li>
          <!-- <li class="nav-item hide_for_now" hidden="">
      <router-link to="/Assign-Permission" class="nav-link">Assign Permission</router-link>
    </li> -->
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Loacalization-Settings" class="nav-link"
              >Localization</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Payment-Settings" class="nav-link"
              >Payment Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Email-Settings" class="nav-link"
              >Email Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Login-Settings" class="nav-link"
              >Social Media Login</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Links" class="nav-link"
              >Social Links</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/SEO-Settings" class="nav-link"
              >SEO Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Theme-Settings" class="nav-link"
              >Theme Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Change-Password" class="nav-link"
              >Change Password</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Other-Settings" class="nav-link"
              >Others</router-link
            >
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Permisions</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <!-- <span
                                class="bntn btn-primary add-pluss ms-2 add_room"
                                ><img
                                  src="../../assets/assets/img/icons/plus.svg"
                                  alt=""
                              /></span> -->
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-end float-end ms-auto" id="docsbutton">
                    <div class="btn-group selectbtn">
                      <button
                        type="button"
                        class="btn dropdown-toggle btn-primary btn-xl"
                        data-bs-toggle="dropdown"
                      >
                        {{ permision_selector || "Permissions" }}
                      </button>
                      <div class="dropdown-menu">
                        <span class="dropdown-item text-bg-secondary"
                          >Select List</span
                        >
                        <span
                          class="dropdown-item"
                          v-for="(name, index) in option_list"
                          v-bind:key="index"
                          @click="selectpermission(name)"
                          >{{ name }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-2 text-end float-end ms-auto"
                    id="newrolehere"
                  >
                    <input
                      class="form-control"
                      type="text"
                      v-model="role"
                      placeholder="Add a new role"
                      disabled
                    />
                  </div>

                  <div
                    class="col-2 text-end float-end ms-auto"
                    v-if="selectedRole?.length > 0"
                  >
                    <select
                      class="form-control select"
                      v-model="own_healthcare"
                    >
                      <option value="Select Institution Type" disabled>
                        Create as an Institution
                      </option>
                      <option :value="1">True</option>
                      <option :value="0">False</option>
                    </select>
                  </div>

                  <div
                    class="col-2 text-end float-end ms-auto"
                    v-if="selectedRole?.length > 0"
                   >
                    <div class="btn-group selectbtn">
                      <button
                        type="button"
                        class="btn dropdown-toggle btn-primary btn-xl"
                        data-bs-toggle="dropdown"
                      >
                        Select roles for this institution
                      </button>
                      <div class="dropdown-menu">
                        <span class="dropdown-item text-bg-secondary"
                          >Select Roles</span
                        >
                        <span
                          class="dropdown-item"
                          v-for="(r, index) in roles"
                          v-bind:key="index"
                          >{{ r.role }}
                          <input
                            class="form-check-input checkb role_seclect"
                            type="checkbox"
                            v-model="selectedRole"
                            :value="r.id"
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <a-table
                  :dataSource="_filtered_rolePermission"
                  :columns="columns"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'select'">
                      <input
                        class="form-check-input checkb"
                        type="checkbox"
                        v-model="rolePermission"
                        :value="record.id"
                      />
                    </template>
                  </template>
                </a-table>

                <br />
                <div class="col-12">
                  <div class="doctor-submit text-end" id="updacance">
                    <span
                      v-if="selectedRole?.length > 0"
                      type="submit"
                      class="btn btn-primary submit-form me-2"
                      @click="editRole()"
                      >Update</span
                    >
                    <span
                      v-else
                      type="submit"
                      class="btn btn-primary submit-form me-2"
                      @click="editPermission()"
                      >Update</span
                    >

                    <button
                      type="submit"
                      class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                      @click="$router.go(-1)"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <LabRequest />
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Settings_menu";

import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Slug",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Select",
    key: "select",
  },
  // {
  //   key: "action",
  // },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      _permissions: [],
      rolePermission: JSON.parse(localStorage.getItem("edit_permissions")),
      _filtered_rolePermission: [],
      permision_selector: "Doctor",
      option_list: [],
      role: localStorage.getItem("role_name"),
      role_id: localStorage.getItem("role_id"),
      roles: [],
      selectedRole: JSON.parse(localStorage?.getItem("roles")),
      // own_healthcare: localStorage.getItem("health_care_type"),
      own_healthcare: "",
      columns,
    };
  },

  methods: {
    ...mapActions({
      _getPermissions: "general/getPermissions",
      _editPermission: "general/editPermission",
      _getrole: "general/getrole",
      _editRole: "general/editRole",
    }),
    getPermissions() {
      this._getPermissions()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this._permissions = this.response.data;
            this._permissions = this._permissions.reverse();
            for (const obj of this._permissions) {
              this.option_list.push(obj.category);
            }
            this._filtered_rolePermission = this._permissions.filter(
              (obj) => obj.category == this.permision_selector
            );

            this._filtered_rolePermission =
              this._filtered_rolePermission[0].permission;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    selectpermission(permission_name) {
      this.permision_selector = permission_name;
      this._filtered_rolePermission = this._permissions.filter(
        (obj) => obj.category == this.permision_selector
      );
      this._filtered_rolePermission =
        this._filtered_rolePermission[0].permission;
    },
    editPermission() {
      this._editPermission({
        id: this.role_id,
        payload: {
          role: this.role,
          permission_id: this.rolePermission,
        },
      })
        .then((response) => {
          // this.Permission=''
          // this.description=''
          this.getPermissions();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // window.location = "View-Roles";
            window.location = "Settings";
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    getrole() {
      this._getrole()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.roles = this.response.data;

            this.roles = this.roles.filter(
              (item) => item.own_healthcare == false
            );
            console.log(this.roles);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },

    editRole() {
      if (this.own_healthcare == "true") {
        this.own_healthcare = 1;
      } else if (this.own_healthcare == "false") {
        this.own_healthcare = 0;
      }
      this._editRole({
        id: this.role_id,
        payload: {
          role: this.role,
          permission_ids: this.rolePermission,
          own_healthcare: this.own_healthcare,
          roles_id: this.selectedRole,
        },
      })
        .then((response) => {
          this.getPermissions();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            window.location = "View-Roles";
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
  },

  mounted() {
    this.getrole();
    this.getPermissions();
    if (localStorage.getItem("health_care_type") == true) {
      this.own_healthcare = 1;
    } else {
      this.own_healthcare = 0;
    }

    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    // this.getPermissions();
    // this.getNonHodDoctor();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n2 {
  background-color: #14a79e;
}

.checkb {
  height: 25px;
  width: 25px;
}

.dropdown-item {
  font-size: 15px;
}

.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}

.dropdown-menu {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  overflow-x: scroll;
}
.role_seclect {
  float: right;
  border: 1px solid #00d3c7;
}

@media (max-width: 1280px) {
  
  #updacance{
    padding: 20px !important;
  }
}

@media (max-width: 800px) {
  
  #updacance{
    padding: 20px !important;
  }
}

@media (max-width: 480px) {
  .page-table-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  #docsbutton {
    width: 100%;
    margin-top: 5%;
    margin-bottom: -10%;
  }

  #newrolehere {
    width: 100%;
    margin-top: 20%;
  }

  .top-nav-search form {
    width: 370px;
  }

  .doctor-table-blk {
    margin-bottom: 3%;
  }

  #updacance{
    padding: 10px !important;
  }

  .dropdown-menu{
    position: absolute !important; 
    inset: 0px auto auto 0px; 
    margin: 0px; 
    transform: translate3d(0px, 52px, 0px); 
    overflow-y: scroll;
    max-height: 60vh;
    scrollbar-width: thin;
    scrollbar-color: #14a79e  #3334480d;
    z-index: 999; 
}
/* #docsbutton{height: 500px;} */
}
</style>
