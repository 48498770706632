<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Radiology</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Department</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- <SettingsMenu /> -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Department List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></span>
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <!-- <th>Head Of Department</th> -->
                      <th>Description</th>
                      <th>Status</th>
                      <th>Date Added</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="Department in categories" :key="Department.id">
                      <!-- <tr> -->
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td>{{ Department.category }}</td>
                      <!-- <td>{{Department.hod_name}}</td> -->
                      <td>{{ Department.description }}</td>
                      <td>
                        <span class="custom-badge status-green">{{
                          Department.status
                        }}</span>
                      </td>
                      <td>{{ formattedDate(Department.date_created) }}</td>

                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              class="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#edit-modal"
                              @click="addDepartment()"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Department</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label
                    >Add Department <span class="login-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    placeholder=""
                    v-model="department"
                  />
                </div>

                <div class="input-block local-forms">
                  <label>Description <span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder=""
                    v-model="description"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="addDepartment()"
                  >
                    Submit
                  </span>
                  <button
                    type="submit"
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Radiology_Setting_Menu";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      categories: [],
      nonHodDoctors: [],
      editDepartment: {},

      department: "",
      description: "",
      type: "radiology",
    };
  },

  methods: {
    ...mapActions({
      _getDepartment: "radiology/getDepartment",
      _addDepartment: "radiology/addDepartment",
    }),

    getDepartment() {
      this._getDepartment()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.categories = this.response.data;
            this.categories = this.categories.reverse();
            console.log(this.categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    addDepartment() {
      this._addDepartment({
        category: this.department,
        description: this.description,
        type: this.type,
      })
        .then((response) => {
          $("#con-close-modal").modal("hide");
          this.department = "";
          this.description = "";
          this.getDepartment();
          this.response = response.data;
          // console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
  },

  mounted() {
    this.getDepartment();

    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    // this.getDepartment();
    // this.getNonHodDoctor();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n2 {
  background-color: #14a79e;
}
</style>
