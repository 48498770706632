<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Dashboard </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Radiology Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="good-morning-blk not_for_admin">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>
                {{ getGreeting() }}, <span>{{ dash_name }}</span>
              </h2>
              <p>Have a nice day at work.</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img
                src="../../assets/assets/img/undraw_medical_research.svg"
                class="dashimg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d1">
            <div class="dash-boxs comman-flex-center">
              <i class="fa dash_card_icons">&#xf271;</i>
            </div>
            <div class="dash-content dash-count">
              <h4>Total Requests</h4>
              <h2 class="text-light">
                <span class="conter-up">
                  {{
                    radiology_data.request == 0 ||
                    radiology_data.request == null ||
                    radiology_data.request == ""
                      ? 0
                      : radiology_data.request
                  }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>40%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d2">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-check dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Completed</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  radiology_data.completed == 0 ||
                  radiology_data.completed == null ||
                  radiology_data.completed == ""
                    ? 0
                    : radiology_data.completed
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>20%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d3">
            <div class="dash-boxs comman-flex-center">
              <i class="fa dash_card_icons">&#xf0a3;</i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Signed Reports</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  radiology_data.signed == 0 ||
                  radiology_data.signed == null ||
                  radiology_data.signed == ""
                    ? 0
                    : radiology_data.signed
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="negative-view"
                  ><i class="feather-arrow-down-right me-1"></i>15%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d4">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-wallet dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Earnings</h4>
              <h2 class="text-light">
                ₦
                <span class="counter-up">
                  {{
                    radiology_data.earnings == 0 ||
                    radiology_data.earnings == null ||
                    radiology_data.earnings == ""
                      ? 0
                      : formatNumberWithCommas(radiology_data.earnings)
                  }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <!-- Number.parseInt(dashboard_data?.earnings).toLocaleString("en-US") -->
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>30%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-12">
          <div class="card">
            <div class="card-header pb-0">
              <h4 class="card-title d-inline-block">
                Recent Radiology Requests
              </h4>
              <router-link
                to="Radiology-History"
                class="float-end patient-views"
                >Show all</router-link
              >
            </div>
            <div class="card-block table-dash">
              <div class="table-responsive" style="overflow: visible">
                <table class="table mb-0 border-0 custom-table">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Sample Number</th>
                      <th>Test</th>
                      <th>Category</th>
                      <th>Cost</th>
                      <th>Priority</th>
                      <th>Payment Status</th>
                      <th>Handler</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="radiology in Radiology"
                      v-bind:key="radiology.id"
                    >
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="table-image">{{ radiology.sample_number }}</td>
                      <td>{{ radiology.test }}</td>
                      <td>{{ radiology.category }}</td>

                      <td>₦ {{ formatNumberWithCommas(radiology.cost) }}</td>
                      <td>
                        <span
                          class="badge badge-danger"
                          v-if="radiology?.priority == 'High'"
                          >{{ radiology?.priority }}</span
                        >
                        <span
                          class="badge badge-warning"
                          v-if="radiology?.priority == 'Moderate'"
                          >{{ radiology?.priority }}</span
                        >
                        <span
                          class="badge badge-primary"
                          v-if="radiology?.priority == 'Low'"
                          >{{ radiology?.priority }}</span
                        >
                      </td>
                      <td>{{ radiology.payment_status }}</td>
                      <td>{{ radiology.handled_by }}</td>
                      <td>{{ radiology.date }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LabRequest />
    <div class="sidebar-overlay" data-reff=""></div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

// import FloatSideBar from "./plugins/float_sidebar";
// import Chat from "./plugins/chat";
// import Loader from "./plugins/Loader";

// import { store } from '@/store';
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      dash_name:
        localStorage.getItem("name") == "null"
          ? ""
          : localStorage.getItem("name"),
      radiology_data: "",
      radiologychartdata: "",
      monthly_stat: [],
      hospital_name:
        localStorage.getItem("hospital") == "null"
          ? ""
          : localStorage.getItem("hospital"),
      Radiology: null,
    };
  },

  methods: {
    ...mapActions({
      _getradiologyDashboard: "dashboards/getradiologyDashboard",
      _get_radiology_requests: "radiology/get_radiology_requests",
    }),
    getradiologyDashboard() {
      //   this.getchartdata();
      this._getradiologyDashboard()
        .then((response) => {
          this.radiology_data = response.data.data;
          this.Radiology = this.radiology_data.radiology;
          this.Radiology = this.Radiology.reverse().slice(0, 5);
          console.log(this.radiology_data);
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    getGreeting() {
      const currentTime = new Date();
      const hours = currentTime.getHours();

      let greeting;

      if (hours < 12) {
        greeting = "Good morning";
      } else if (hours < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      return greeting;
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  mounted() {
    setTimeout(() => {
      this.getradiologyDashboard();
    }, 3000);
    var role = localStorage.getItem("role");
    if (role == "Super Admin" || role == "Admin") {
      $(".not_for_admin").hide();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.h2,
.h4 {
  color: white;
}

.d1 {
  /* background-color: #fc8955; */
  background-color: #14a79e;

  color: white;
}

.d2 {
  /* background-color: #0583d2; */
  background: rgb(255, 69, 96);
  /* background: #fbbc06; */
  color: white;
}

.d3 {
  /* background-color: #4fd6f7; */
  background: #2c50ed;
  color: white;
}

.d4 {
  /* background-color: #b8e3ff; */
  background: #222437;
  color: white;
}

.dash-boxs {
  /* background: #e8eff4; */
  background: #eff0f144;
}
.counter-up {
  color: white;
}
.dash_card_icons {
  color: white;
  font-size: 30px;
}

.sub_design {
  width: 53%;
  padding: 2px;
  border: 2px solid white;
  border-radius: 12px;
  background-color: white;
  color: black;
}
.dashimg {
  width: 100%;
  height: 130%;
  margin-left: -20%;
  /* margin-top: 650px; */
}
</style>
