<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Room Allotment </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Allotted Rooms</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Allotted Rooms</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <!-- <span
                                class="bntn btn-primary add-pluss ms-2 add_room"
                                ><img
                                  src="../../assets/assets/img/icons/plus.svg"
                                  alt=""
                              /></span> -->
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="allotedRooms" :columns="columns">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'name'">
                      <span>
                        <img
                          width="28"
                          height="28"
                          src="../../assets/assets/img/profiles/avatar-02.jpg"
                          class="rounded-circle m-r-5"
                          alt=""
                        />
                        {{ record.patient_name }}
                      </span>
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a
                          href="#"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#con-close-modal"
                            @click="setSelectedAllottedRoom(record)"
                            class="dropdown-item add_room"
                            ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                            Edit</a
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- fixed -->

      <!-- sample modal content -->

      <div
        id="con-close-modal"
        class="modal modal-lg animate__animated animate__zoomIn animate__faster"
        style="margin-top:100px;margin-left:100px;margin-right"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">New Allotment</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <div>
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Account Number
                        <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="text"
                        placeholder=""
                        v-model="selectedAllottedRoom.account_number"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Full Name <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="text"
                        placeholder=""
                        v-model="selectedAllottedRoom.patient_name"
                        disabled
                      />
                    </div>
                  </div>

                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Email <span class="login-danger">*</span></label>
                      <input class="form-control" type="email" placeholder="" />
                    </div>
                  </div> -->
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Room Type <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div> -->
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Admit Date <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="text"
                        placeholder=""
                        v-model="selectedAllottedRoom.admitted_date"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Room Type <span class="login-danger">*</span></label
                      >
                      <select
                        class="form-control"
                        type="text"
                        name="cat"
                        v-model="selectedRoomType"
                      >
                        <option value="" disabled>Select A Room Type</option>
                        <option value="Multiple">Multiple</option>
                        <option value="Single">Single</option>
                      </select>
                    </div>
                  </div>
                  <!-- {{ filteredRoomNames }} -->

                  <div
                    class="col-12 col-md-6 col-xl-6"
                    v-if="selectedRoomType == 'Multiple'"
                  >
                    <div class="input-block local-forms">
                      <label
                        >Room Name <span class="login-danger">*</span></label
                      >
                      <select
                        class="form-control"
                        type="text"
                        name="cat"
                        v-model="selectedRoomId"
                      >
                        <option value="" disabled selected>
                          Select Room Type
                        </option>
                        <option
                          v-for="room_type in filteredRoomNames"
                          :key="room_type.id"
                          :value="room_type.id"
                        >
                          {{ room_type.room_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-xl-6"
                    v-if="selectedRoomType === 'Single'"
                  >
                    <div class="input-block local-forms">
                      <label
                        >Room Name <span class="login-danger">*</span></label
                      >
                      <select
                        class="form-control"
                        type="text"
                        name="cat"
                        v-model="selectedRoomId"
                      >
                        <option value="" disabled selected>
                          Select Room Type
                        </option>
                        <option
                          v-for="room_type in filteredRoomNames"
                          :value="room_type.id"
                          :key="room_type.id"
                        >
                          {{ room_type.room_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-xl-3"
                    v-if="
                      selectedRoomId != '' && selectedRoomType == 'Multiple'
                    "
                  >
                    <div class="input-block local-forms" v-if="selectedRoom">
                      <label
                        >Occupants <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="number"
                        readonly
                        v-model="selectedRoom.occupants"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-xl-3"
                    v-if="selectedRoomId != ''"
                  >
                    <div class="input-block local-forms" v-if="selectedRoom">
                      <label
                        >Room Price <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="selectedRoom.room_price"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-top-form">
                      <label class="local-top"
                        >Avatar <span class="login-danger">*</span></label
                      >
                      <div class="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onchange="loadFile(event)"
                          class="hide-input"
                        />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button
                        type="submit"
                        class="btn btn-primary submit-form me-2"
                        id="submitButton"
                        @click.prevent="allocateNewRoom()"
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        id="cancelButton"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";
import jquery from "jquery";

const columns = [
  {
    title: "Patient Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Gender",
    dataIndex: "sex",
    key: "sex",
  },
  {
    title: "Room Name",
    dataIndex: "room_name",
    key: "room_name",
  },
  {
    title: "Room Type",
    dataIndex: "room_type",
    key: "room_type",
  },
  {
    title: "Room Number",
    dataIndex: "room_number",
    key: "room_number",
  },
  {
    title: "Admit Date",
    dataIndex: "admitted_date",
    key: "admitted_date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  // {
  //   title: "Discharge Date",
  //   dataIndex: "admitted_date",
  //   key: "admitted_date",
  // },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      room_numbers: [],
      room_types: [],
      allotedRooms: [],
      reserve_allotedRooms: [],
      selectedAllottedRoom: {},
      selectedRoomType: "",
      filtered: "",
      search: "",
      selectedRoomId: "",
      selectedRoom: "",
      columns,
    };
  },

  methods: {
    ...mapActions({
      _allotedRooms: "roomAllotment/getRoomAllotmentRecords",
      _allocateNewRoom: "roomAllotment/new_room_allotment",
      _getRoomTypes: "roomAllotment/getRoomTypes",
      _getRoomNumbers: "roomAllotment/getRoomNumbers",
    }),

    getRoomNumbers() {
      this._getRoomNumbers()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.room_numbers = this.response.data;
            // this.$toast.success(this.response["message"]);
            console.log(this.room_numbers);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    getRoomTypes() {
      this._getRoomTypes()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.room_types = this.response.data;
            // this.$toast.success(this.response["message"]);
            console.log(this.room_types);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    setSelectedAllottedRoom(selectedAllottedRoom) {
      this.selectedAllottedRoom = selectedAllottedRoom;
    },

    allocateNewRoom() {
      $(".inloader").show();

      // alert(this.selectedAllottedRoom.room_number)
      this._allocateNewRoom({
        id: this.selectedAllottedRoom.id,
        payload: {
          // room_number: this.selectedAllottedRoom.room_number,
          room_type_id: this.selectedRoomId,
        },
      })
        .then((response) => {
          $(".inloader").hide();

          this.getAllottedRooms();
          this.response = response.data;
          // console.log(response);
          // if (this.response["status"] == true) {
          this.$toast.success(this.response["message"]);
          $("#con-close-modal").modal("hide");
        })
        .catch((error) => {
          console.log(error);
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet connectivity."
              );
            }
          }
        });
    },

    getAllottedRooms() {
      this._allotedRooms()
        .then((response) => {
          this.response = response.data.data;
          // if (this.response["status"] == true) {
          this.allotedRooms = this.response;
          this.allotedRooms = this.allotedRooms.reverse();
          this.reserve_allotedRooms = this.allotedRooms;
          console.log(this.allotedRooms);
          $("#con-close-modal").modal("hide");
          // }
          // console.log(this.allotedRooms);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              // this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    search_the_list() {
      if (this.search == "") {
        this.allotedRooms = this.reserve_allotedRooms;
      } else {
        this.allotedRooms = this.reserve_allotedRooms;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.allotedRooms.length; i++) {
          var patient_name = this.allotedRooms[i].patient_name.toLowerCase();
          var sex = this.allotedRooms[i].sex.toLowerCase();
          var admitted_date = this.allotedRooms[i].admitted_date.toLowerCase();
          var status = this.allotedRooms[i].status.toLowerCase();

          if (
            patient_name.includes(searchLetter) ||
            sex.includes(searchLetter) ||
            status.includes(searchLetter) ||
            admitted_date.includes(searchLetter)
          ) {
            searchResult.push(this.allotedRooms[i]);
          }
          // if (searchResult == []) {
          //   this.allotedRooms = this.allotedRooms;
          // }
        }
      }
      if (this.search == "") {
        this.allotedRooms = this.reserve_allotedRooms;
      } else {
        this.allotedRooms = searchResult;
      }
    },
  },

  computed: {
    filteredRoomNames() {
      return this.room_types.filter(
        (room_type) => room_type.room_type == this.selectedRoomType
      );
    },

    selectedRoom() {
      this.selectedRoom = this.room_types.find(
        (room_type) => room_type.id === this.selectedRoomId
      );
      return this.room_types.find(
        (room_type) => room_type.id === this.selectedRoomId
      );
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });
    $("#cancelButton").click(function () {
      $("#con-close-modal").modal("hide");
    });
    this.getAllottedRooms();
    this.getRoomTypes();
    // this.filteredRoomNames();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{width:60%} */
</style>
