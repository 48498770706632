<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Radiology</li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Radiologist List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Radiologist List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden="">
                            <img src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header table-bordered-->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="main_data" :columns="columns">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'sample_number'">
                      <div class="tooltip-container">
                        <span @click="view_patient_details(record)" class="tb_name">{{ record.sample_number }}</span>

                        <div>
                          <span class="tooltip-text">
                            <span>Patient Name - {{ record.patient_name }}</span>
                            <span>Moblie Number -
                              {{ record.mobile_number }}</span>

                            <span>Patient Age - {{ record.patient_age }}</span>
                            <span>Patient Gender - {{ record.patient_sex }}</span>
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-if="column.key == 'priority'">
                      <span>
                        <span class="badge badge-danger w-100" v-if="record?.priority == 'High'">{{ record?.priority }}</span>
                        <span class="badge badge-warning w-100" v-if="record?.priority == 'Moderate'">{{ record?.priority
                          }}</span>
                        <span class="badge badge-primary w-100" v-if="record?.priority == 'Low'">{{ record?.priority }}</span>
                        <span class="badge badge-secondary w-100" v-if="record?.priority == 'QS'">Quick Service</span>

                      </span>
                    </template>
                    <template v-if="column.key == 'cost'">
                      <span> ₦ {{ formatNumberWithCommas(record.cost) }} </span>
                    </template>
                    <template v-if="column.key == 'status'">
                      <span class="badge badge-success">{{
                        record.payment_status
                      }}</span>
                    </template>
                    <template v-if="column.key == 'date'">
                      <span> {{ formatDate(record.created_at, true) }} </span>
                    </template>
                    <!-- <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <span class="dropdown-item" title="Collect Sample" @click="collect(record.id)">
                            <i class="fa fa-check m-r-5"></i>Collect</span>
                          <span class="dropdown-item" title="Make Report" @click="report(record.id)">
                            <i class="fa fa-tachometer m-r-5"></i> Make
                            Report</span>
                          <span class="dropdown-item" @click="completed(record.id)"><i class="fa fa-check m-r-5"></i>
                            Completed</span>
                        </div>
                      </div>
                    </template> -->


                    <template v-if="column.key == 'action'">
                      <div class="d-flex gap-2 justify-content-end">
                        <span class="btn btn-primary" title="Make Report" @click="collect(record.id)"
                          v-if="!record.collected">
                          <i class="fa fa-pen m-r-5"></i>Collect Specimen</span>
                        <button class="btn btn-primary" title="Make Report" @click="report(record?.id,record?.note)" v-else>
                          <i class="fa fa-pen m-r-5"></i>{{ record?.note ? "Edit Report" : "Make Report" }}</button>

                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <span class="dropdown-item" @click="completed(record.id)"><i class="fa fa-check m-r-5"></i>
                              Completed</span>
                            <!-- <span class="dropdown-item" title="Print Report"
                              @click="downloadLabReport(record?.id)" v-if="record.note">
                              <i class="fa fa-print m-r-5"></i>Print Out</span> -->


                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PatientDetails :patient_details_data_recieved="send_record" />

</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { formatDate } from "@/utils/formatter";
import PatientDetails from "./plugin/patient_details.vue";

import { mapActions } from "vuex";

const columns = [

  {
    title: "Sample number",
    dataIndex: "sample_number",
    key: "sample_number",
  },
  {
    title: "Test",
    dataIndex: "test",
    key: "test",
  },
  {
    title: "Department",
    dataIndex: "radiology",
    key: "department",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Date",
    // dataIndex: "date",
    key: "date",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    PatientDetails

  },

  data() {
    return {
      reponse: "",
      main_data: [],
      main_data1: [],
      main_data_reserve: [],
      apt_id: 0,
      reason: "",
      columns,
      formatDate,
      send_record: {}

    };
  },
  methods: {
    ...mapActions({
      _get_radiology_requests: "radiology/get_radiology_requests",
      _completed: "radiology/completed",
      _collect: "radiology/collect_sample",
    }),
    view_patient_details(record) {
      this.send_record = record;
      $(".patient_details-modal").modal("show");
    },
    downloadLabReport(labTestId) {
      this.$store
        .dispatch("general/downloadReport", { targetId: labTestId, target: patientNoteTargets.lab });
    },
    get_radiology_requests() {
      this._get_radiology_requests({ type: "mine" })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data.map((data) => ({
              ...data,
              patient_sex: data?.patient?.sex,
              patient_name: data?.patient?.name,
              patient_age: `${data?.patient?.age} Yrs.`,
              mobile_number: data?.patient?.mobile_number,
            }));
            this.main_data_reserve = this.main_data;
            console.log(this.main_data);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    completed(id) {
      // alert(id)
      this._completed({
        id: id,
        payload: { analyze: "sample" },
      })
        .then((response) => {
          this.get_radiology_requests();

          // this.response = response.data;
          if (this.response["status"] == true) {
            // this.response = this.response.data
            // console.log(this.response)
            this.$toast.success("Radiology test is completed");
            this.get_radiology_requests();
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    collect(id) {
      this._collect({
        id: id,
        payload: { collect: "sample" },
      })
        .then((response) => {
          // this.response = response.data;
          this.get_radiology_requests();

          if (this.response["status"] == true) {
            // this.response = this.response.data
            // console.log(this.response)
            this.$toast.success("Sample Collected");
            this.get_radiology_requests();
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    report(id) {
      localStorage.setItem("radiology_id", id);
      localStorage.setItem("k_value", 1);
      if (previousNote) {
        localStorage.setItem("previousNote", previousNote);
      } else {
        localStorage.removeItem("previousNote");
      }
      window.location = "Add-Radiology-Note";
    },
    edit_text(text) {
      const formatted_text =
        text.charAt(0).toUpperCase() +
        text.slice(1).replace("_", " ").replace("p", "P");
      return formatted_text;
    },
    edit_text2(text) {
      const formatted_text = text.replace("-", " ");
      return formatted_text;
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var test = this.main_data[i].test.toLowerCase();
          var lab = this.main_data[i].radiology.toLowerCase();
          var priority = this.main_data[i].priority.toLowerCase();

          if (
            test.includes(searchLetter) ||
            lab.includes(searchLetter) ||
            priority.includes(searchLetter)
          ) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
  },
  mounted() {
    this.get_radiology_requests();
    // this.cat();

    $(".cancel_transfer").click(function () {
      $(".invite_modal").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 70%;
}

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

.invite_row {
  padding-left: 3%;
}

.selectbtn {
  width: 200px;
  background-color: #00d3c7;
}
</style>
