<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Accounts </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Service List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Service List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                                @input="click()"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_fees"
                            ><img src="../../assets/assets/img/icons/plus.svg" alt=""
                          /></span>
                          <span class="btn btn-primary doctor-refresh ms-2" hidden=""
                            ><img src="../../assets/assets/img/icons/re-fresh.svg" alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto text-end float-end ms-auto download-grp">
                <a href="javascript:;" class="me-2"
                  ><img src="../../assets/assets/img/icons/pdf-icon-01.svg" alt=""
                /></a>
                <a href="javascript:;" class="me-2"
                  ><img src="../../assets/assets/img/icons/pdf-icon-02.svg" alt=""
                /></a>
                <a href="javascript:;" class="me-2"
                  ><img src="../../assets/assets/img/icons/pdf-icon-03.svg" alt=""
                /></a>
                <a href="javascript:;"
                  ><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt=""
                /></a>
              </div>
            </div>
          </div>
          <!-- /Table Header -->
          <div class="table-responsive" style="overflow: visible">
            <a-table :dataSource="service_lists" :columns="columns">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'service'">
                  <span @click="getServiceHistory(record)">
                    {{ record.service }}
                  </span>
                </template>
                <template v-if="column.key == 'price'">
                  <span> ₦ {{ formatNumberWithCommas(record.price) }} </span>
                </template>
                <template v-if="column.key == 'date'">
                  <span>
                    {{ getDatetime(record.created_at) }}
                  </span>
                </template>
                <template v-if="column.key == 'action'">
                  <div
                    v-if="record.status == true"
                    :id="`toggleContainer${record.id}`"
                    class="toggle-container on"
                    @click="changeServiceStatus(record.id)"
                  >
                    <div class="toggle-circle"></div>
                  </div>

                  <div
                    v-if="record.status == false"
                    :id="`toggleContainer${record.id}`"
                    class="toggle-container off"
                    @click="changeServiceStatus(record.id)"
                  >
                    <div class="toggle-circle"></div>
                  </div>
                </template>

                <template v-if="column.key == 'action2'">
                  <div class="dropdown dropdown-action">
                    <a
                      href="#"
                      class="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="fa fa-ellipsis-v"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-end">
                      <a class="dropdown-item edit_fees" @click="show_edit(record)"
                        ><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a
                      >
                      <a class="dropdown-item" href="#" @click="deleteService(record.id)"
                        ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                      >
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Service Charges</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="row">
            <div class="col-12 col-md-6 col-xl-6">
              <div class="input-block local-forms">
                <label>Service Name<span class="login-danger">*</span></label>
                <input
                  class="form-control"
                  type="text"
                  placeholder=""
                  v-model="service_name"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
              <div class="input-block local-forms">
                <label>Price <span class="login-danger">*</span></label>
                <input
                  class="form-control"
                  type="number"
                  placeholder=""
                  v-model="service_price"
                />
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="doctor-submit text-end">
              <button
                type="submit"
                class="btn btn-primary submit-form me-2"
                @click="addService()"
              >
                Submit
              </button>
              <span type="submit" class="btn btn-primary cancel-form bg-danger text-light"
                >Cancel</span
              >
            </div>
          </div>
          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>

  <div
    id="edit-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Service Charges</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="row">
            <div class="col-12 col-md-6 col-xl-6">
              <div class="input-block local-forms">
                <label>Service Name<span class="login-danger">*</span></label>
                <input
                  class="form-control"
                  type="text"
                  placeholder=""
                  v-model="edit_service_name"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
              <div class="input-block local-forms">
                <label>Price <span class="login-danger">*</span></label>
                <input
                  class="form-control"
                  type="number"
                  placeholder=""
                  v-model="edit_service_price"
                />
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="doctor-submit text-end">
              <button
                type="submit"
                class="btn btn-primary submit-form me-2"
                @click="editService()"
              >
                Submit
              </button>
              <span type="submit" class="btn btn-primary cancel-form bg-danger text-light"
                >Cancel</span
              >
            </div>
          </div>
          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal -->
  <!-- </div> -->
  <!-- </div> -->
  <History :history_data_recieved="history_data" />
</template>
<script>
import { data } from "jquery";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import History from "./plugin/view_service_history.vue";

import jquery from "jquery";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Service Name",
    key: "service",
  },
  {
    title: "Price",
    key: "price",
  },
  {
    title: "Date Added",
    key: "date",
  },
  {
    // title: "Action",
    key: "action",
  },
  {
    // title: "Action",
    key: "action2",
  },
];
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    History,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      service_lists: [],
      search: "",
      service_lists_reserve: [],
      service_price: "",
      service_name: "",
      edit_service_price: "",
      edit_service_name: "",
      service_id: "",
      history_data: [],
      columns,
    };
  },

  methods: {
    ...mapActions({
      _getServiceList: "finance/getServiceList",
      _addService: "finance/addService",
      _editService: "finance/editService",
      _deleteService: "finance/deleteService",
      _changeServiceStatus: "finance/changeServiceStatus",
      _getServiceHistory: "finance/getServiceHistory",
    }),
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    getServiceList() {
      this._getServiceList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.service_lists = this.response.data.reverse();
            this.service_lists_reserve = this.service_lists;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    search_the_list() {
      if (this.search == "") {
        this.service_lists = this.service_lists_reserve;
      } else {
        this.service_lists = this.service_lists_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.service_lists.length; i++) {
          var drug_name = this.service_lists[i].drug_name.toLowerCase();
          var category = this.service_lists[i].category.toLowerCase();
          var company_name = this.service_lists[i].company_name.toLowerCase();
          var selling_price = this.service_lists[i].selling_price;
          var expiry_date = this.service_lists[i].expiry_date;
          if (
            drug_name.includes(searchLetter) ||
            category.includes(searchLetter) ||
            company_name.includes(searchLetter) ||
            selling_price.includes(searchLetter) ||
            expiry_date.includes(searchLetter)
          ) {
            searchResult.push(this.service_lists[i]);
          }
          // if (searchResult == []) {
          //   this.service_lists = this.service_lists;
          // }
        }
      }
      if (this.search == "") {
        this.service_lists = this.service_lists_reserve;
      } else {
        this.service_lists = searchResult;
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addService() {
      $(".inloader").show();

      this._addService({
        service_name: this.service_name,
        service_price: this.service_price,
      })
        .then((response) => {
          $(".inloader").hide();
          $("#con-close-modal").modal("hide");

          // window.location.reload();
          this.getServiceList();
          this.response = response.data;
          if (this.response["status"] === true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
          // } else {
          //   this.$toast.error("An unexpected error occurred.");
          // }
        });
    },
    editService() {
      $(".inloader").show();

      this._editService({
        id: this.service_id,
        payload: {
          service_name: this.edit_service_name,
          service_price: this.edit_service_price,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          $("#edit-close-modal").modal("hide");

          this.edit_service_name = "";
          this.edit_service_price = "";
          // window.location.reload();
          this.getServiceList();
          this.response = response.data;
          if (this.response["status"] === true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
          // } else {
          //   this.$toast.error("An unexpected error occurred.");
          // }
        });
    },
    show_edit(data) {
      this.edit_service_name = data.service;
      this.edit_service_price = data.price;
      this.service_id = data.id;
      $("#edit-close-modal").modal("show");
    },
    deleteService(id) {
      $(".inloader").show();

      this._deleteService(id)
        .then((response) => {
          $(".inloader").hide();
          $("#con-close-modal").modal("hide");
          this.getServiceList();
          this.response = response.data;
          if (this.response["status"] === true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },
    changeServiceStatus(id) {
      $(".inloader").show();

      this._changeServiceStatus(id)
        .then((response) => {
          this.toggle(id);
          this.getServiceList();
          this.response = response.data;
          this.$toast.success(this.response["message"]);
          $(".inloader").hide();
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet connectivity.");
            }
          }
        });
    },

    toggle(id) {
      this.state = 1 - this.state; // Toggle this.state between 0 and 1
      const container = document.getElementById(`toggleContainer${id}`);

      if (this.state === 1) {
        container.classList.remove("off");
        container.classList.add("on");
      } else {
        container.classList.remove("on");
        container.classList.add("off");
      }
    },

    getServiceHistory(Q) {
      $(".inloader").show();
      // localStorage.setItem('service_history','')

      this._getServiceHistory(Q.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const service_history = this.response.data;
            $(".inloader").hide();
            // localStorage.setItem('service_history', JSON.stringify(service_history))
            this.history_data = service_history;
            $("#service-history-modal").modal("show");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
          $(".inloader").hide();
        });
    },
  },

  mounted() {
    $(".add_fees").click(function () {
      $("#con-close-modal").modal("show");
    });
    // $(".edit_fees").click(function () {});
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    this.getServiceList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#con-close-modal {
  margin-top: 50px;
  margin-left: 100px;
}

#edit-close-modal {
  margin-top: 50px;
  margin-left: 100px;
}

.toggle-container {
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-circle {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background-color: #14a79e;
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background-color: #16161649;
}

.tb_name:hover {
  color: #14a79e;
  cursor: pointer;
}

@media (max-width: 800px) {
  #con-close-modal {
    margin-left: 20px !important;
  }

  .modal-dialog {
    margin-left: 0px !important;
  }

  .modal-content {
    width: 750px !important;
  }

  #edit-close-modal {
    margin-left: 20px !important;
  }
}

@media (max-width: 480px) {
  #con-close-modal {
    margin-left: 3px !important;
  }

  .modal-dialog {
    margin-left: 0px !important;
  }

  .modal-content {
    width: 100% !important;
  }

  .local-forms.input-block {
    margin-top: 2%;
  }

  /* #request_lab_test {
    margin-left: 3px !important;
  } */

  #edit-close-modal {
    margin-left: 3px !important;
  }
}
</style>
