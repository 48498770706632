<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Radiology</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Radiology Test Type</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- <SettingsMenu /> -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>List of Radiology Tests</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></span>
                          <!-- <span class="btn btn-primary doctor-refresh ms-2" hidden=''
                                ><img
                                  src="../../assets/assets/img/icons/re-fresh.svg"
                                  alt=""
                              /></span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="test_types" :columns="columns">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'cost'">
                      <span> ₦ {{ formatNumberWithCommas(record.cost) }} </span>
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a
                          href="#"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a
                            class="dropdown-item"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-close-modal"
                            @click="setSelectedLabTestType(record)"
                            ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                            Edit</a
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <!-- Add Radiology Test Type -->
  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Radiology Test Type</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Lab Type <span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g Gastro test"
                    v-model="test"
                  />
                </div>
              </div>

              <div class="col-6 col-md-6 col-xl-6">
                <div class="input-block local-forms">
                  <label>Department <span class="login-danger"></span></label>
                  <select
                    class="form-control"
                    type="text"
                    name="cat"
                    v-model="department"
                  >
                    <option value="" disabled>Select Department</option>
                    <option :value="d.id" v-for="d in depts" v-bind:key="d.id">
                      {{ d.category }}
                    </option>
                    <!-- <option value="new_dept">Request new department</option>
                  @change="request_radiology_test()"
                    -->
                  </select>
                </div>
              </div>
              <div class="col-6 col-md-6 col-xl-6 text-center" id="radiologistpro">
                <span
                  type="submit"
                  class="btn btn-primary btn_r"
                  @click="request_radiology_test()"
                >
                  Request new department
                </span>
              </div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Amount <span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g 5000"
                    v-model="cost"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="add_radiology_type()"
                  >
                    Submit
                  </span>
                  <span
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>

  <!-- Edit Radiology Test Type -->
  <div
    id="edit-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Radiology Test Type</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label
                    >radiology Type <span class="login-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g Gastro test"
                    v-model="SelectedRadiologyTestType.test"
                  />
                </div>

                <div class="input-block local-forms">
                  <label>Department <span class="login-danger"></span></label>
                  <select
                    class="form-control"
                    type="text"
                    name="cat"
                    v-model="SelectedRadiologyTestType.radiology_category_id"
                  >
                    <option value="" disabled>Select Department</option>
                    <option
                      :value="department.id"
                      v-for="department in depts"
                      v-bind:key="department.id"
                    >
                      {{ department.category }}
                    </option>
                  </select>
                </div>

                <div class="input-block local-forms">
                  <label>Amount <span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g 5000"
                    v-model="SelectedRadiologyTestType.cost"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="edit_radiology_type()"
                  >
                    Submit
                  </span>
                  <span
                    class="btn btn-primary edit-cancel-form bg-danger text-light cancel-form"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>

  <!-- request new lab test -->
  <div
    id="request_radiology_test"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Request Radiology Category</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <!-- <div class="col-12"></div> -->
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label
                    >Radiology Type <span class="login-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Department name"
                    v-model="department_name"
                  />
                </div>

                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Description </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      cols="30"
                      v-model="about_department"
                      placeholder="About department"
                    >
                    </textarea>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="request_Category()"
                  >
                    Submit
                  </span>
                  <span
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Radiology_Setting_Menu";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Test Type",
    dataIndex: "test",
    key: "test",
  },
  {
    title: "Department",
    dataIndex: "radiology_category",
    key: "radiology_category",
  },
  {
    title: "Amount",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Date Added",
    dataIndex: "date_added",
    key: "date_added",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      test_types: [],
      test: "",
      cost: "",
      radiology_category_id: 0,
      type: "radiology",
      depts: [],
      department: "",
      columns,
      SelectedRadiologyTestType: {},
      about_department: "",
      department_name: "",
    };
  },

  methods: {
    ...mapActions({
      _get_test_type: "radiology/getTestType",
      _add_radiology_type: "radiology/add_radiology_type",
      _edit_radiology_type: "radiology/edit_radiology_type",
      _get_depts: "radiology/getDepartment",
      _addCategory: "general/add_global_category",
    }),
    represent(id) {
      this.radiology_category_id = id;
      // alert(this.radiology_category_id)
    },

    get_test_type() {
      this._get_test_type()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.test_types = this.response.data;
            this.test_types = this.test_types.reverse();
            console.log(this.test_types);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    add_radiology_type() {
      this._add_radiology_type({
        test: this.test,
        cost: this.cost,
        type: this.type,
        radiology_category_id: this.department,
      })
        .then((response) => {
          $("#con-close-modal").modal("hide");
          this.department = "";
          this.description = "";
          this.test = "";
          this.cost = "";
          this.get_test_type();
          this.response = response.data;
          // console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        });
    },

    setSelectedRadiologyTestType(SelectedRadiologyTestType) {
      this.SelectedRadiologyTestType = SelectedRadiologyTestType;
    },

    edit_radiology_type() {
      this._edit_radiology_type({
        id: this.SelectedRadiologyTestType.id,
        payload: {
          test: this.SelectedRadiologyTestType.test,
          cost: this.SelectedRadiologyTestType.cost,
          type: this.SelectedRadiologyTestType.type,
          radiology_category_id:
            this.SelectedRadiologyTestType.radiology_category_id,
        },
      })
        .then((response) => {
          $("#edit-close-modal").modal("hide");
          this.department = "";
          this.description = "";
          this.test = "";
          this.cost = "";
          this.get_test_type();
          this.response = response.data;
          // console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        });
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    get_depts() {
      this._get_depts()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.depts = this.response.data;
            console.log(this.depts);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    request_radiology_test() {
      // if (this.department == "new_dept") {
      $("#request_radiology_test").modal("show");
      $("#con-close-modal").modal("hide");
      // }
    },
    request_Category() {
      $(".inloader").show();
      this._addCategory({
        category: this.department_name,
        description: this.about_department,
        type: "Radiology",
      })
        .then((response) => {
          $("#con-close-modal").modal("hide");
          this.response = response.data;
          // console.log(response);
          $("#request_radiology_test").modal("hide");

          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            this.about_department = "";
            this.department_name = "";
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });

    $(".edit-cancel-form").click(function () {
      $("#edit-close-modal").modal("hide");
    });

    this.get_test_type();
    this.get_depts();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n1 {
  background-color: #14a79e;
}
.btn_r {
  height: 50px;
  padding-top: 15px;
  width: 80%;
}

@media (max-width: 800px){
  #radiologistpro{
    margin-bottom: 5%;
  }
}

@media (max-width: 480px){
  #radiologistpro{
    margin-bottom: 5%;
  }
}
</style>
