<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Dashboard </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Doctor Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="good-morning-blk not_for_admin">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>
                {{ getGreeting() }}, <span>Dr. {{ doctor_name }}</span>
              </h2>
              <p>Have a nice day at work.</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img src="../../assets/assets/img/morning-img-02.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="doctor-list-blk">
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div class="doctor-widget border-right-bg">
              <div class="doctor-box-icon flex-shrink-0">
                <img src="../../assets/assets/img/icons/doctor-dash-01.svg" alt="" />
              </div>
              <div class="doctor-content dash-count flex-grow-1">
                <h4>
                  <span class="counter-up">
                    {{
                      main_data.accepted_appointments == 0 ||
                        main_data.accepted_appointments == null ||
                        main_data.accepted_appointments == ""
                        ? 0
                        : main_data.accepted_appointments
                    }} </span><span>/
                    {{
                      main_data.pending_appointments == 0 ||
                        main_data.pending_appointments == null ||
                        main_data.pending_appointments == ""
                        ? 0
                        : main_data.pending_appointments
                    }}</span>
                  <!-- <span class="status-pink">-20%</span> -->
                </h4>
                <h5>Appointments</h5>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="doctor-widget border-right-bg">
              <div class="doctor-box-icon flex-shrink-0">
                <img src="../../assets/assets/img/icons/doctor-dash-02.svg" alt="" />
              </div>
              <div class="doctor-content dash-count flex-grow-1">
                <h4>
                  <span class="counter-up">
                    {{
                      main_data.admitted == 0 ||
                        main_data.admitted == null ||
                        main_data.admitted == ""
                        ? 0
                        : main_data.admitted
                    }} </span><span>/
                    {{
                      main_data.discharged == 0 ||
                        main_data.discharged == null ||
                        main_data.discharged == ""
                        ? 0
                        : main_data.discharged
                    }}</span>
                  <!-- <span class="status-pink">-20%</span> -->
                </h4>
                <h5>Admitted | Discharged Patients</h5>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="doctor-widget border-right-bg">
              <div class="doctor-box-icon flex-shrink-0">
                <img src="../../assets/assets/img/icons/doctor-dash-03.svg" alt="" />
              </div>
              <div class="doctor-content dash-count flex-grow-1">
                <h4>
                  <span class="counter-up">
                    {{
                      main_data.operations == 0 ||
                        main_data.operations == null ||
                        main_data.operations == ""
                        ? 0
                        : main_data.operations
                    }}</span>
                  <span>/0</span>
                  <!-- <span class="status-green">+40%</span> -->
                </h4>
                <h5>Operations</h5>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="doctor-widget">
              <div class="doctor-box-icon flex-shrink-0">
                <!-- <img src="../../assets/assets/img/icons/doctor-dash-04.svg" alt=""> -->
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <div class="doctor-content dash-count flex-grow-1">
                <h4>
                  <span class="counter-up">
                    {{
                      main_data.accepted_requests == 0 ||
                        main_data.accepted_requests == null ||
                        main_data.accepted_requests == ""
                        ? 0
                        : main_data.accepted_requests
                    }}</span><span>/
                    {{
                      main_data.pending_requests == 0 ||
                        main_data.pending_requests == null ||
                        main_data.pending_requests == ""
                        ? 0
                        : main_data.pending_requests
                    }}</span>
                  <!-- <span class="status-green">+50%</span> -->
                </h4>
                <h5>Requests</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 col-xl-7">
          <div class="card">
            <div class="card-body">
              <div class="chart-title patient-visit mb-0">
                <h4>Activity Chart</h4>

                <div class="input-block mb-0">
                  <a-select class="w-100">
                    <a-select-option value="2024">2024</a-select-option>
                    <a-select-option value="2025">2025</a-select-option>
                    <a-select-option value="2026">2026</a-select-option>
                    <a-select-option value="2027">2027</a-select-option>
                  </a-select>
                </div>
              </div>
              <!-- <div id="apexcharts-area"></div> -->
              <apexchart type="line" :options="chart.chartOptions" :series="chart.series" height="400">
              </apexchart>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-xl-8">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title d-inline-block">Recent Appointments</h4>
                  <router-link to="Doctor-Pending-Appointments" class="patient-views float-end">Show all</router-link>
                </div>
                <div class="card-body p-0 table-dash">
                  <div class="table-responsive" style="overflow: visible">
                    <table class="table mb-0 border-0 custom-table">
                      <!-- <thead><tr><th></th><th></th><th></th></tr></thead> -->
                      <tbody>
                        <!-- {{ displayedAppointments }} -->
                        <tr v-for="(data, index) in main_data2" :key="index">
                          <td class="table-image appoint-doctor">
                            <img width="28" height="28" class="rounded-circle"
                              src="../../assets/assets/img/profiles/avatar-02.jpg" alt="" />
                            <h2>{{ data.full_name }}</h2>
                          </td>
                          <td class="appoint-time">
                            <h6>{{ formattedDate(data.time) }}</h6>
                          </td>
                          <td>
                            <button class="check-point status-green me-1" @click="acceptPatient(data.id, data.type)">
                              <i class="feather-check"></i>
                            </button>
                            <button class="check-point status-pink" @click="showmodal(data.id, data.type)">
                              <i class="feather-x"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style="text-align: center; margin-top: 12%" v-if="main_data2 == '' || main_data2 == null">
                    <i class="fas fa-box-open" style="font-size: 100px; color: #14a79e; opacity: 0.4"></i>
                    <div class="font-size:16px">No data yet</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-xl-4 d-flex">
              <div class="card wallet-widget">
                <div class="circle-bar circle-bar2">
                  <br />
                  <br />
                  <div class="circle-graph2" :data-percent="countdownpercent">
                    <b><img src="../../assets/assets/img/icons/timer.svg" alt="" /></b>
                  </div>
                </div>
                <div class="main-limit">
                  <p>Next Appointment in</p>
                  <h4 id="countdown">00:00:00</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fixedcountdown"></div>

        <div class="col-12 col-lg-12 col-xl-5 d-flex">
          <div class="card flex-fill comman-shadow">
            <div class="card-header">
              <h4 class="card-title d-inline-block">Recent Appointments</h4>
              <router-link to="Doctor-Accepted-Appointments" class="patient-views float-end">Show all</router-link>
            </div>
            <div class="card-body">
              <div class="teaching-card">
                <!-- <ul class="steps-history">
                  <li>08:00</li>
                </ul> -->
                <ul class="activity-feed">
                  <li class="feed-item d-flex align-items-center">
                    <div class="dolor-activity hide-activity">
                      <ul class="doctor-date-list mb-2">
                        <div v-for="x in doctor_apt_data" :key="x.index">
                          <li class="stick-line" v-if="x.status == 'Completed'">
                            <i class="fas fa-circle me-2"></i>{{ x.date }}
                            <span>{{ x.patient_name }}</span>
                          </li>
                        </div>
                        <div v-for="x in doctor_apt_data" :key="x.index">
                          <div v-if="x.status != 'Completed'">
                            <li @mouseenter="show_apt_dtl(x.appointment_id)" :id="`hide${x.appointment_id}`" it="hide">
                              <i class="fas fa-circle me-2"></i>{{ x.date }}
                              <span>{{ x.patient_name }}</span>
                            </li>
                            <li class="dropdown ongoing-blk none" :id="`show${x.appointment_id}`" style="display: none"
                              it="show">
                              <a href="#" class="dropdown-toggle active-doctor" data-bs-toggle="dropdown">
                                <i class="fas fa-circle me-2 active-circles"></i>{{ x.date }} <span>{{ x.patient_name
                                  }}</span>
                                <!-- {{x.start_time}} -->
                                <!-- <span class="ongoing-drapt"
                              >Ongoing <i class="feather-chevron-down ms-2"></i
                            ></span> -->
                              </a>
                              <ul class="doctor-sub-list dropdown-menu">
                                <li class="patient-new-list dropdown-item">
                                  Patient<span>{{ x.patient_name }}</span><a href="javascript:;"
                                    class="new-dot status-green"><i class="fas fa-circle me-1 fa-2xs"></i>New</a>
                                </li>
                                <li class="dropdown-item">
                                  Time<span>8:30 - 9:00 (30min)</span>
                                </li>
                                <li class="schedule-blk mb-0 pt-2 dropdown-item">
                                  <ul class="nav schedule-time">
                                    <li>
                                      <a href="javascript:;"><img src="../../assets/assets/img/icons/trash.svg"
                                          alt="" /></a>
                                    </li>
                                    <li @click="
                                      gotoDashboard(
                                        x.patient_id,
                                        x.patient_name,
                                        x.appointment_id,
                                        x.patient_type
                                      )
                                      ">
                                      <a href="javascript:;"><img src="../../assets/assets/img/icons/profile.svg"
                                          alt="" /></a>
                                    </li>
                                    <li>
                                      <router-link to="Doctor-Note"><img src="../../assets/assets/img/icons/edit.svg"
                                          alt="" /></router-link>
                                    </li>
                                  </ul>
                                  <span class="btn btn-primary text-light appoint-start"
                                    :id="`start_apt${x.appointment_id}`" @click="start_appt(x.appointment_id)">Start
                                    Appointment</span>
                                  <span class="btn btn-danger text-light appoint-start" style="display:none"
                                    :id="`end_apt${x.appointment_id}`" @click="end_appt(x.appointment_id)">Stop
                                    Appointment</span>
                                </li>
                              </ul>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster reject_modal"
      style="margin-top:160px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Reject patient</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <form>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Reason for Reject
                      <span class="login-danger">*</span></label>
                    <textarea class="form-control" rows="3" cols="30" v-model="appointment_rejection.reason"></textarea>
                  </div>
                </div>

                <div class="col-12">
                  <div class="doctor-submit text-end">
                    <span type="submit" class="btn btn-primary submit-form me-2" @click="rejectPatient()">
                      Submit
                    </span>
                    <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                      @click="hidemodal()">
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { formatDate } from "@/utils/formatter"
// import FloatSideBar from "./plugins/float_sidebar";
// import Chat from "./plugins/chat";
// import Loader from "./plugins/Loader";
import ApexCharts from "apexcharts";

// import { store } from '@/store';
import { mapActions } from "vuex";
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    Loader,
    Inloader,
  },

  data() {
    return {
      // dash_name: localStorage.getItem("Fname") + ' ' + localStorage.getItem("Lname"),
      main_data: "",
      id: null,
      doctor_name: "",
      main_data2: [],
      doctor_apt_data: "",
      pending_appiontments: null,
      accepted_appiontments: null,
      operations: null,
      pending_requests: null,
      Accepted_requests: null,
      admitted: null,
      discharged: null,
      aa: null,
      // started: 0,
      whoisLoggedin: localStorage.getItem("role"),
      hospital_name:
        localStorage.getItem("hospital") == "null"
          ? ""
          : localStorage.getItem("hospital"),

      chart: {
        chartOptions: {
          chart: {
            height: 200,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            categories: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
          },
        },
        series: [
          {
            name: "Income",
            color: "#14a79e",
            data: [],
          },
        ],
      },
      Doc_chartdata: "",
      appointment_rejection: {
        apt_id: "",
        Ttype: "",
        reason: ""
      }
    };
  },

  computed: {
    displayedAppointments() {
      if (this.main_data2 && this.main_data2.length > 0) {
        return this.main_data2.slice(0, 3);
      } else {
        return []; // Return an empty array if no data or data is empty
      }
    },
  },

  methods: {
    ...mapActions({
      _getDoctorDashboardData: "doctor/getDoctorDashboardData",
      // _getDoctorDashboardData: "dashboards/getAdminDashboard",
      _getDoctorPendingAppointmentList: "doctor/getDoctorPendigAppontmentList",
      _acceptPatient: "doctor/acceptPatient",
      _rejectPatient: "doctor/rejectPatient",
      _get_doctors_apt: "doctor/get_doctors_apt",
      _start_appt: "doctor/start_appt",
      _end_appt: "doctor/end_appt",
    }),

    getDoctorDashboardData() {
      if (this.whoisLoggedin == "Doctor") {
        this.id = localStorage.getItem("doc_id");

        this.doctor_name =
          localStorage.getItem("name") == "null"
            ? ""
            : localStorage.getItem("name");
      }

      if (this.whoisLoggedin == "Admin") {
        this.id = localStorage.getItem("doc_id_from_list");
        this.doctor_name = localStorage.getItem("name_from_list");
      }

      this._getDoctorDashboardData(this.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            // console.log(this.main_data, "sk");

            this.Doc_chartdata = response.data.data.chart;
            this.monthly_stat = Object.values(this.Doc_chartdata);
            console.log(this.monthly_stat);
            this.chart.series[0].data = this.monthly_stat;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    get_doctors_apt() {
      this._get_doctors_apt()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.doctor_apt_data = this.response.data;
            // this.aa = this.doctor_apt_data[0].appointment_id;
            // alert(this.aa)
            // document.querySelector(`[id='show${this.aa}']`).style.display =
            //   "block";
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getGreeting() {
      const currentTime = new Date();
      const hours = currentTime.getHours();

      let greeting;

      if (hours < 12) {
        greeting = "Good morning";
      } else if (hours < 16) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      return greeting;
    },

    getDoctorPendingAppointmentList() {
      this._getDoctorPendingAppointmentList()
        .then((response) => {
          this.response = response.data.data;
          this.main_data2 = this.response;
          // this.main_data2 = this.response.data.slice(0, 23);
          console.log(this.main_data2);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    acceptPatient(id, type) {
      var type = type.toLowerCase();
      $(".inloader").show();

      this._acceptPatient({
        id: id,
        payload: { type: type },
      })
        .then((response) => {
          this.response = response.data;
          window.location = "Doctor-Accepted-Appointments";

          if (this.response["status"] == true) {
            this.getDoctorPendigAppontmentList();
            $(".inloader").hide();
            this.$toast.success("Appointment Accepted");
          }
        })
        .catch((error) => {
          console.log(error);
          $(".inloader").hide();
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },

    showmodal: function (id, type) {
      $("#con-close-modal").modal("show");
      this.appointment_rejection.apt_id = id;
      this.appointment_rejection.Ttype = type;
    },
    rejectPatient() {
      $("#con-close-modal").modal("hide");
      $(".inloader").show();
      this._rejectPatient({
        id: this.appointment_rejection.apt_id,
        payload: {
          reason: this.appointment_rejection.reason,
          type: this.appointment_rejection.Ttype.toLowerCase(),
        },
      })
        .then((response) => {
          this.response = response.data;
          this.reject = "";

          if (this.response["status"] == true) {
            this.getDoctorPendingAppointmentList();
            $(".inloader").hide();
            this.$toast.success("Appointment Rejected");
          }
        })
        .catch((error) => {
          console.log(error);
          $(".inloader").hide();
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    // animateElements(percentage) {

    // },
    startCountdown(duration) {
      let timer = duration,
        hours,
        minutes,
        seconds;
      setInterval(function () {
        hours = parseInt(timer / 3600, 10);
        minutes = parseInt((timer % 3600) / 60, 10);
        seconds = parseInt(timer % 60, 10);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        var percentageRemaining = (timer / duration) * 100;
        percentageRemaining = percentageRemaining.toFixed(2);

        let countdownDisplay = document.getElementById("countdown");
        if (countdownDisplay) {
          countdownDisplay.textContent = hours + ":" + minutes + ":" + seconds;
        }
        $(".circle-bar2").each(function () {
          var elementPos = $(this).offset().top;
          var topOfWindow = $(window).scrollTop();
          var animate = $(this).data("animate");
          if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
            $(this).data("animate", true);
            $(this)
              .find(".circle-graph2")
              .circleProgress({
                value: percentageRemaining,
                // value:(100 - percentageRemaining.toFixed(2)) / 100,
                size: 400,
                thickness: 30,
                fill: {
                  color: "#14a79e",
                },
              });
          }
        });

        if (--timer < 0) {
          timer = duration;
        }
      }, 1000);
    },
    gotoDashboard(id, full_name, aptid, type) {
      localStorage.setItem("patient_name", full_name);
      localStorage.setItem("apt_id", aptid);
      localStorage.setItem("this_patient_id", id);
      localStorage.setItem("patient_type", type);

      window.location = "My-Patient-Dashboard";
    },
    start_appt(aptid) {
      this._start_appt(aptid)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            // this.doctor_apt_data = this.response.data;
            // this.started = 1;
            // this.gotoDashboard(id, full_name, aptid, type)

            const old = document.querySelector(`[id='start_apt${this.aa}']`);
            old.style.display = "none";
            const curr = document.querySelector(`[id='end_apt${this.aa}']`);
            curr.style.display = "block";
            this.get_doctors_apt()

          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });

      // var countdownDuration = 4;
      // var countdownElement = document.getElementById("fixedcountdown");
      // var count = countdownDuration;
      // var countdownInterval = setInterval(function () {
      //   count--;
      //   countdownElement.textContent = count;
      //   if (count <= 0) {
      //     clearInterval(countdownInterval);
      //   }
      // }, 1000);
    },
    end_appt(aptid) {
      this._end_appt(aptid)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            // this.doctor_apt_data = this.response.data;
            // this.started = 1;
            // this.gotoDashboard(id, full_name, aptid, type)

            const old = document.querySelector(`[id='start_apt${this.aa}']`);
            old.style.display = "block";
            const curr = document.querySelector(`[id='end_apt${this.aa}']`);
            curr.style.display = "none";

            this.get_doctors_apt()
            this.aa = null
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });

      // var countdownDuration = 4;
      // var countdownElement = document.getElementById("fixedcountdown");
      // var count = countdownDuration;
      // var countdownInterval = setInterval(function () {
      //   count--;
      //   countdownElement.textContent = count;
      //   if (count <= 0) {
      //     clearInterval(countdownInterval);
      //   }
      // }, 1000);
    },
    show_apt_dtl(apt_id) {
      // alert(this.aa)
      if (this.aa != null) {
        const old = document.querySelector(`[id='hide${this.aa}']`);
        old.style.display = "block";
        const curr = document.querySelector(`[id='show${this.aa}']`);
        curr.style.display = "none";
        this.aa = apt_id;
      }
      const old = document.querySelector(`[id='hide${apt_id}']`);
      old.style.display = "none";
      const curr = document.querySelector(`[id='show${apt_id}']`);
      curr.style.display = "block";
      this.aa = apt_id;

      // alert(this.aa);
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },

  mounted() {
    this.startCountdown(3600);

    this.getDoctorPendingAppointmentList();
    // this.displayedAppointments();
    setTimeout(() => {
      this.getDoctorDashboardData();
      this.get_doctors_apt();
    }, 3000);

    var role = localStorage.getItem("role");
    if (role == "Super Admin" || role == "Admin") {
      $(".not_for_admin").hide();
    }
    // let _vm = this;
    // $(".card-body,window").hover(function () {
    //   if (_vm.aa != null) {
    //     const old = document.querySelectorAll('[it="hide"]');
    //     old.forEach((old) => {
    //       old.style.display = "block";
    //     });

    //     const curr = document.querySelectorAll('[it="show"]');
    //     curr.forEach((curr) => {
    //       curr.style.display = "none";
    //     });

    //     window.addEventListener("mouseover", () => {
    //       if (_vm.aa != null) {
    //         const old = document.querySelectorAll('[it="hide"]');
    //         old.forEach((old) => {
    //           old.style.display = "block";
    //         });

    //         const curr = document.querySelectorAll('[it="show"]');
    //         curr.forEach((curr) => {
    //           curr.style.display = "none";
    //         });
    //       }
    //     });

    // NOT IMPORTANT
    // const old = document.querySelector(`[id='hide${_vm.aa}']`);
    // old.style.display = "block";
    // const curr = document.querySelector(`[id='show${_vm.aa}']`);
    // curr.style.display = "none";
    // }
    // console.log(_vm.aa);
    // });

    // $("#start_apt").click(function () {
    //   start_appt();
    // });

    // Circle Progress Bar
    // function animateElements() {
    //   $('.circle-bar2').each(function () {
    //     var elementPos = $(this).offset().top;
    //     var topOfWindow = $(window).scrollTop();
    //     var percent = $(this).find('.circle-graph2').attr('data-percent');
    //     var animate = $(this).data('animate');
    //     if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
    //       $(this).data('animate', true);
    //       $(this).find('.circle-graph2').circleProgress({
    //         value: percent / 100,
    //         size: 400,
    //         thickness: 30,
    //         fill: {
    //           color: '#14a79e'
    //         }
    //       });
    //     }
    //   });
    // }

    // if ($('.circle-bar').length > 0) {
    //   animateElements();
    // }
    // $(window).scroll(animateElements);

    // $(`[activate_doc='doc_btn${this.apt_id}']`).hover(function(){})
  },
};

// setTimeout(() => {
// 	location.reload()
// }, 3000);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa {
  color: white;
}

#fixedcountdown {
  font-size: 116px;
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
  /* width:100%; */
  height: auto;
  text-align: center;
  color: #00d3c7;
  animation: scaleCountdown 1s ease-in-out forwards;
}

.none {
  display: none;
}

@keyframes scaleCountdown {
  0% {
    transform: scale(2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
