<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Pharmacy</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Category</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <SettingsMenu />

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Category List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></span>
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Category Name</th>
                      <th>Admin</th>
                      <th>Date Added</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="drug_category in drug_categories"
                      :key="drug_category.id"
                    >
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td>{{ drug_category.category }}</td>
                      <td>{{ drug_category.admin }}</td>
                      <td>{{ formattedDate(drug_category.created_at) }}</td>
                      <td></td>

                      <!-- <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a
                                href="#"
                                class="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><i class="fa fa-ellipsis-v"></i
                              ></a>
                              <div class="dropdown-menu dropdown-menu-end">
                                <a class="dropdown-item" href="edit-appointment.html"
                                  ><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_patient"
                                  ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                                >
                              </div>
                            </div>
                          </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 15%"
            v-if="drug_categories == '' || drug_categories == null"
          >
            <i
              class="fas fa-box-open"
              style="font-size: 150px; color: #14a79e; opacity: 0.4"
            ></i>
            <div class="font-size:24px">No data yet</div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Drug Category</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label
                    >Add Category <span class="login-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g Eye"
                    v-model="formData.drug_category"
                  />
                </div>
                <div class="input-block local-forms">
                  <label
                    >Add Category Image<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <input
                    class="form-control"
                    type="file"
                    placeholder=""
                    v-on="drug_category_avatar"
                    accept="*"
                    @change="handleImageChange"
                    required
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="addDrugCategory()"
                  >
                    Submit
                  </span>
                  <span
                    type="submit"
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import SettingsMenu from "../plugins/Pharmacy_setting";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      drug_categories: [],
      formData: {
        drug_category: "",
        drug_category_avatar: null,
      },
      search: "",
      drug_categories_reserve: [],
    };
  },

  methods: {
    ...mapActions({
      _getDrugCategories: "pharmacy/getDrugCategories",
      _addDrugCategory: "pharmacy/addDrugCategory",
    }),

    getDrugCategories() {
      this._getDrugCategories()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_categories = this.response.data;
            this.drug_categories_reserve = this.drug_categories;
            // this.$toast.success(this.response["message"]);
            console.log(this.drug_categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    handleImageChange(event) {
      this.formData.drug_category_avatar = event.target.files[0];
    },

    addDrugCategory() {
      let formData = new FormData();
      formData.append("drug_category", this.formData.drug_category);
      formData.append(
        "drug_category_avatar",
        this.formData.drug_category_avatar
      );

      this._addDrugCategory(formData)
        .then((response) => {
          $("#con-close-modal").modal("hide");

          this.getDrugCategories();
          this.response = response.data;
          // console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    search_the_list() {
      if (this.search == "") {
        this.drug_categories = this.drug_categories_reserve;
      } else {
        this.drug_categories = this.drug_categories_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.drug_categories.length; i++) {
          var category = this.drug_categories[i].category.toLowerCase();
          if (category.includes(searchLetter)) {
            searchResult.push(this.drug_categories[i]);
          }
          // if (searchResult == []) {
          //   this.drug_categories = this.drug_categories;
          // }
        }
      }
      if (this.search == "") {
        this.drug_categories = this.drug_categories_reserve;
      } else {
        this.drug_categories = searchResult;
      }
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });

    this.getDrugCategories();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
