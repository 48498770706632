<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Dashboard </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Pharmacy Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="good-morning-blk not_for_admin">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>
                {{ getGreeting() }}, <span>{{ dash_name }}</span>
              </h2>
              <p>Have a nice day at work</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img
                src="../../assets/assets/img/undraw_medical_care_movn.svg"
                alt=""
                class="dashimg"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d1">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-pills dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Drugs</h4>
              <h2 class="text-light">
                <span class="conter-up">
                  {{
                    pharmacy_data.drugs == 0 ||
                    pharmacy_data.drugs == null ||
                    pharmacy_data.drugs == ""
                      ? 0
                      : pharmacy_data.drugs
                  }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>40%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d2">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-ambulance dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Dispensed</h4>
              <h2 class="text-light">
                <span class="counter-up"> {{
                    pharmacy_data.dispensed == 0 ||
                    pharmacy_data.dispensed == null ||
                    pharmacy_data.dispensed == ""
                      ? 0
                      : pharmacy_data.dispensed
                  }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>20%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d3">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-money dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Earnings</h4>
              <h2 class="text-light">
                <span class="counter-up">₦ {{
                  pharmacy_data.earnings == 0 ||
                  pharmacy_data.earnings == null ||
                  ""
                    ? 0
                    : formatNumberWithCommas(pharmacy_data.earnings)
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="negative-view"
                  ><i class="feather-arrow-down-right me-1"></i>15%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d4">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-trash dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Expired Drugs</h4>
              <h2 class="text-light">
                <span class="counter-up">
                  {{
                    pharmacy_data.expired_drugs == 0 ||
                    pharmacy_data.expired_drugs == null ||
                    pharmacy_data.expired_drugs == ""
                      ? 0
                      : formatNumberWithCommas(pharmacy_data.expired_drugs)
                  }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <!-- Number.parseInt(dashboard_data?.earnings).toLocaleString("en-US") -->
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>30%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
            </div>
          </div>
          <!-- /Table Header -->
          <div class="table-responsive" style="overflow: visible">
            <table
              class="table border-0 custom-table comman-table table-striped table-hover mb-0"
            >
              <thead>
                <tr>
                <th>Patient Name</th>
                  <th>Medicine Name</th>
                  <th>Quantity</th>
                  <th>Frequency</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              
                <tr v-for="drug_list in dispensory" :key="drug_list.id">
                 
                  <td>{{ drug_list.patient_name }}</td>
                  <td>{{ drug_list.drug_name }}</td>
                  <td>{{ drug_list.quantity }}</td>
                  <td>{{ drug_list.frequency }}</td>
                  <td>₦ {{ formatNumberWithCommas(drug_list.price) }}</td>

                  <td>{{ drug_list.created_at }}</td>
                <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

// import FloatSideBar from "./plugins/float_sidebar";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      dash_name:
        localStorage.getItem("name") == "null"
          ? ""
          : localStorage.getItem("name"),
      pharmacy_data: "",
      pharmacychartdata: "",
      monthly_stat: [],
      dispensory: [],
      // chart: {
      //   chartOptions: {
      //     chart: {
      //       height: 200,
      //       toolbar: {
      //         show: false,
      //       },
      //     },
      //     dataLabels: {
      //       enabled: false,
      //     },
      //     stroke: {
      //       curve: "smooth",
      //     },
      //     xaxis: {
      //       categories: [
      //         "January",
      //         "February",
      //         "March",
      //         "April",
      //         "May",
      //         "June",
      //         "July",
      //         "August",
      //         "September",
      //         "October",
      //         "November",
      //         "December",
      //       ],
      //     },
      //   },
      //   series: [
      //     {
      //       name: "Income",
      //       color: "#14a79e",
      //       data: [],
      //     },
      //   ],
      // },
    };
  },

  methods: {
    ...mapActions({
      _getpharmacyDashboard: "dashboards/getpharmacyDashboard",
      // _getpharmacychart: "dashboards/getpharmacychart",
      _getDrugList: "pharmacy/getDrugList",
      _getDrugList: "pharmacy/getDispensory",
      
    }),
    getpharmacyDashboard() {
      //   this.getchartdata();
      this._getpharmacyDashboard()
        .then((response) => {
          this.pharmacy_data = response.data.data;
          console.log(this.pharmacy_data);
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    // getpharmacychart() {
    //   //   this.getchartdata();
    //   this.getpharmacyDashboard();
    //   this._getpharmacychart()
    //     .then((response) => {
    //       this.pharmacychartdata = response.data.data.chart;
    //       this.monthly_stat = Object.values(this.pharmacychartdata);
    //       console.log(this.monthly_stat);
    //       this.chart.series[0].data = this.monthly_stat;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log("Error fetching data", error);
    //     });
    // },
    getGreeting() {
      const currentTime = new Date();
      const hours = currentTime.getHours();

      let greeting;

      if (hours < 12) {
        greeting = "Good morning";
      } else if (hours < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      return greeting;
    },
    getDrugList() {
      this._getDrugList({ pending: 1 })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.dispensory = this.response.data;
            this.dispensory_reserve = this.dispensory;
            // this.$toast.success(this.response["message"]);
            // console.log(this.drug_categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if(number){
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else{ return 0}
    },
  },

  mounted() {
    setTimeout(() => {
      this.getpharmacyDashboard();
      this.getDrugList();
      this.getGreeting();
    }, 3000);
    var role = localStorage.getItem("role");

    if (role == "Super Admin" || role == "Admin") {
      $(".not_for_admin").hide();
    }
    // this.getpharmacychart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.h2,
.h4 {
  color: white;
}

.d1 {
  /* background-color: #fc8955; */
  background-color: #14a79e;

  color: white;
}

.d2 {
  /* background-color: #0583d2; */
  background: rgb(255, 69, 96);
  /* background: #fbbc06; */
  color: white;
}

.d3 {
  /* background-color: #4fd6f7; */
  background: #2c50ed;
  color: white;
}

.d4 {
  /* background-color: #b8e3ff; */
  background: #222437;
  color: white;
}

.dash-boxs {
  /* background: #e8eff4; */
  background: #eff0f144;
}
.counter-up {
  color: white;
}
.dash_card_icons {
  color: white;
  font-size: 30px;
}

.sub_design {
  width: 53%;
  padding: 2px;
  border: 2px solid white;
  border-radius: 12px;
  background-color: white;
  color: black;
}
.dashimg {
  width: 100%;
  height: 130%;
  margin-left: -20%;
  /* margin-top: 650px; */
}
</style>
